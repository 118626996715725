/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  CommercialStatus,
  CommercialStatusFromJSON,
  CommercialStatusFromJSONTyped,
  CommercialStatusToJSON,
  Material,
  MaterialFromJSON,
  MaterialFromJSONTyped,
  MaterialToJSON,
  MediumEnum,
  MediumEnumFromJSON,
  MediumEnumFromJSONTyped,
  MediumEnumToJSON,
} from "./";

/**
 *
 * @export
 * @interface Commercial
 */
export interface Commercial {
  /**
   *
   * @type {CommercialStatus}
   * @memberof Commercial
   */
  status: CommercialStatus;
  /**
   *
   * @type {Material}
   * @memberof Commercial
   */
  material: Material;
  /**
   *
   * @type {Date}
   * @memberof Commercial
   */
  creationDate: Date;
  /**
   *
   * @type {number}
   * @memberof Commercial
   */
  currentYearBroadcasts?: number | null;
  /**
   *
   * @type {number}
   * @memberof Commercial
   */
  previousYearBroadcasts?: number | null;
  /**
   *
   * @type {number}
   * @memberof Commercial
   */
  currentYear?: number | null;
  /**
   *
   * @type {string}
   * @memberof Commercial
   */
  readonly id: string;
  /**
   *
   * @type {string}
   * @memberof Commercial
   */
  code: string;
  /**
   *
   * @type {number}
   * @memberof Commercial
   */
  length: number;
  /**
   *
   * @type {MediumEnum}
   * @memberof Commercial
   */
  medium: MediumEnum;
  /**
   *
   * @type {number}
   * @memberof Commercial
   */
  productId: number;
  /**
   *
   * @type {string}
   * @memberof Commercial
   */
  description?: string | null;
  /**
   *
   * @type {number}
   * @memberof Commercial
   */
  adScienceId?: number | null;
}

export function CommercialFromJSON(json: any): Commercial {
  return CommercialFromJSONTyped(json, false);
}

export function CommercialFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Commercial {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    status: CommercialStatusFromJSON(json["status"]),
    material: MaterialFromJSON(json["material"]),
    creationDate: new Date(json["creationDate"]),
    currentYearBroadcasts: !exists(json, "currentYearBroadcasts")
      ? undefined
      : json["currentYearBroadcasts"],
    previousYearBroadcasts: !exists(json, "previousYearBroadcasts")
      ? undefined
      : json["previousYearBroadcasts"],
    currentYear: !exists(json, "currentYear") ? undefined : json["currentYear"],
    id: json["id"],
    code: json["code"],
    length: json["length"],
    medium: MediumEnumFromJSON(json["medium"]),
    productId: json["productId"],
    description: !exists(json, "description") ? undefined : json["description"],
    adScienceId: !exists(json, "adScienceId") ? undefined : json["adScienceId"],
  };
}

export function CommercialToJSON(value?: Commercial | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    status: CommercialStatusToJSON(value.status),
    material: MaterialToJSON(value.material),
    creationDate: value.creationDate.toISOString(),
    currentYearBroadcasts: value.currentYearBroadcasts,
    previousYearBroadcasts: value.previousYearBroadcasts,
    currentYear: value.currentYear,
    code: value.code,
    length: value.length,
    medium: MediumEnumToJSON(value.medium),
    productId: value.productId,
    description: value.description,
    adScienceId: value.adScienceId,
  };
}
