import { createAction, createAsyncAction } from "typesafe-actions";

import {
  ExternalMaterial,
  ExternalMaterialMetadata,
  LinkExternalMaterialWithProductIdRequest,
  MaterialIdentifier,
} from "../../api/models";
import { ResponseError } from "../base";
import { actionNameAsync } from "../utils";

export const receiveExternalMaterialsAction = createAsyncAction(
  actionNameAsync("receiveExternalMaterials", "REQUEST"),
  actionNameAsync("receiveExternalMaterials", "SUCCESS"),
  actionNameAsync("receiveExternalMaterials", "FAILURE")
)<void, ExternalMaterial[], ResponseError>();

export const receiveExternalMaterialMetadataAction = createAsyncAction(
  actionNameAsync("receiveExternalMaterialMetadata", "REQUEST"),
  actionNameAsync("receiveExternalMaterialMetadata", "SUCCESS"),
  actionNameAsync("receiveExternalMaterialMetadata", "FAILURE")
)<MaterialIdentifier, ExternalMaterialMetadata, ResponseError>();

export const linkExternalMaterialWithProductIdAction = createAsyncAction(
  actionNameAsync("linkExternalMaterialWithProductId", "REQUEST"),
  actionNameAsync("linkExternalMaterialWithProductId", "SUCCESS"),
  actionNameAsync("linkExternalMaterialWithProductId", "FAILURE")
)<LinkExternalMaterialWithProductIdRequest, boolean, ResponseError>();

export const clearLinkExternalMaterialWithProductIdAction = createAction(
  "clearLinkExternalMaterialWithProductId"
)();
