import { Modal, Typography } from "@ster/ster-toolkit";

import { Material } from "../../../api";
import { getMaterialPlayer } from "../Commercials";

const MaterialPreviewModal = ({
  material,
  onCancel,
}: {
  material: Material;
  onCancel: () => void;
}) => (
  <Modal
    destroyOnClose
    footer={null}
    title={
      <Typography.Title level={3} className="material-modal__title">
        Preview
      </Typography.Title>
    }
    open
    onCancel={onCancel}
    wrapClassName="material-modal"
    width={600}
  >
    {getMaterialPlayer(material)}
  </Modal>
);

export default MaterialPreviewModal;
