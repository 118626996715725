import { Action } from "redux";
import { Reducer, createReducer } from "typesafe-actions";

import { genericReducer } from "../../utils";
import { Loading, ReduxStoreState } from "../base";
import { LoadingCampaigns, LoadingOrderRequest } from "../models";
import {
  clearCopyCampaignAction,
  clearDeleteCampaignAction,
  copyCampaignAction,
  deleteCampaignAction,
  receiveCampaignsAction,
} from "./actions";

export const campaignsReducer: Reducer<LoadingCampaigns, Action> =
  createReducer({
    state: ReduxStoreState.Initial,
  })
    .handleAction(
      receiveCampaignsAction.request,
      (state: LoadingCampaigns) => ({
        ...state,
        loading: true,
        state: ReduxStoreState.Loading,
      })
    )
    .handleAction(
      receiveCampaignsAction.failure,
      (state: LoadingCampaigns) => ({
        ...state,
        loading: false,
        state: ReduxStoreState.Failure,
      })
    )
    .handleAction(
      receiveCampaignsAction.success,
      (
        state: LoadingCampaigns,
        action: ReturnType<typeof receiveCampaignsAction.success>
      ) => ({
        campaigns: action.payload,
        loading: false,
        state: ReduxStoreState.Success,
      })
    );

export const deleteCampaignReducer: Reducer<Loading, Action> = createReducer({
  state: ReduxStoreState.Initial,
})
  .handleAction(deleteCampaignAction.request, () => ({
    loading: true,
    state: ReduxStoreState.Loading,
  }))
  .handleAction(deleteCampaignAction.failure, () => ({
    loading: false,
    state: ReduxStoreState.Failure,
  }))
  .handleAction(deleteCampaignAction.success, () => ({
    loading: false,
    state: ReduxStoreState.Success,
  }))
  .handleAction(clearDeleteCampaignAction, () => ({
    loading: false,
    state: ReduxStoreState.Initial,
  }));

export const copyCampaignReducer: Reducer<LoadingOrderRequest, Action> =
  genericReducer(
    copyCampaignAction.request,
    copyCampaignAction.success,
    copyCampaignAction.failure,
    undefined,
    clearCopyCampaignAction
  );
