import classNames from "classnames";

import { Material } from "../../../../api";
import styles from "./MaterialPlayButton.module.less";
import PlayButton from "./PlayButton";
import ThumbnailSlider from "./ThumbnailSlider";

const MaterialPlayButton = ({
  material,
  disabled = false,
  title,
  onClick,
}: {
  material: Material;
  disabled?: boolean;
  title: string;
  onClick: () => void;
}) => (
  <PlayButton
    disabled={disabled}
    className={classNames(styles.card__media, {
      [styles.btn_disabled]: disabled,
    })}
    onClick={onClick}
  >
    <ThumbnailSlider
      images={material.thumbnails}
      title={title}
      medium={material.identifier.medium}
    />
  </PlayButton>
);

export default MaterialPlayButton;
