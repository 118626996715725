/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  ExternalMaterial,
  ExternalMaterialFromJSON,
  ExternalMaterialMetadata,
  ExternalMaterialMetadataFromJSON,
  ExternalMaterialMetadataToJSON,
  ExternalMaterialToJSON,
  LinkExternalMaterialWithProductIdRequest,
  LinkExternalMaterialWithProductIdRequestFromJSON,
  LinkExternalMaterialWithProductIdRequestToJSON,
  MaterialIdentifier,
  MaterialIdentifierFromJSON,
  MaterialIdentifierToJSON,
  MediaFileType,
  MediaFileTypeFromJSON,
  MediaFileTypeToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface ApiV1MaterialGetExternalMaterialMetadataPostRequest {
  materialIdentifier?: MaterialIdentifier;
}

export interface ApiV1MaterialLinkExternalMaterialWithProductIdPostRequest {
  linkExternalMaterialWithProductIdRequest?: LinkExternalMaterialWithProductIdRequest;
}

export interface ApiV1MaterialThumbnailsMaterialCodeThumbnailIndexGetRequest {
  materialCode: string;
  thumbnailIndex: string;
}

export interface ApiV1MaterialTypeMaterialCodeGetRequest {
  type: MediaFileType;
  materialCode: string;
}

/**
 *
 */
export class MaterialApi extends runtime.BaseAPI {
  /**
   */
  async apiV1MaterialGetExternalMaterialMetadataPostRaw(
    requestParameters: ApiV1MaterialGetExternalMaterialMetadataPostRequest
  ): Promise<runtime.ApiResponse<ExternalMaterialMetadata>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Material/GetExternalMaterialMetadata`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: MaterialIdentifierToJSON(requestParameters.materialIdentifier),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ExternalMaterialMetadataFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1MaterialGetExternalMaterialMetadataPost(
    requestParameters: ApiV1MaterialGetExternalMaterialMetadataPostRequest
  ): Promise<ExternalMaterialMetadata> {
    const response =
      await this.apiV1MaterialGetExternalMaterialMetadataPostRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   */
  async apiV1MaterialGetExternalMaterialsGetRaw(): Promise<
    runtime.ApiResponse<Array<ExternalMaterial>>
  > {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Material/GetExternalMaterials`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(ExternalMaterialFromJSON)
    );
  }

  /**
   */
  async apiV1MaterialGetExternalMaterialsGet(): Promise<
    Array<ExternalMaterial>
  > {
    const response = await this.apiV1MaterialGetExternalMaterialsGetRaw();
    return await response.value();
  }

  /**
   */
  async apiV1MaterialLinkExternalMaterialWithProductIdPostRaw(
    requestParameters: ApiV1MaterialLinkExternalMaterialWithProductIdPostRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Material/LinkExternalMaterialWithProductId`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: LinkExternalMaterialWithProductIdRequestToJSON(
        requestParameters.linkExternalMaterialWithProductIdRequest
      ),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async apiV1MaterialLinkExternalMaterialWithProductIdPost(
    requestParameters: ApiV1MaterialLinkExternalMaterialWithProductIdPostRequest
  ): Promise<void> {
    await this.apiV1MaterialLinkExternalMaterialWithProductIdPostRaw(
      requestParameters
    );
  }

  /**
   */
  async apiV1MaterialThumbnailsMaterialCodeThumbnailIndexGetRaw(
    requestParameters: ApiV1MaterialThumbnailsMaterialCodeThumbnailIndexGetRequest
  ): Promise<runtime.ApiResponse<Blob>> {
    if (
      requestParameters.materialCode === null ||
      requestParameters.materialCode === undefined
    ) {
      throw new runtime.RequiredError(
        "materialCode",
        "Required parameter requestParameters.materialCode was null or undefined when calling apiV1MaterialThumbnailsMaterialCodeThumbnailIndexGet."
      );
    }

    if (
      requestParameters.thumbnailIndex === null ||
      requestParameters.thumbnailIndex === undefined
    ) {
      throw new runtime.RequiredError(
        "thumbnailIndex",
        "Required parameter requestParameters.thumbnailIndex was null or undefined when calling apiV1MaterialThumbnailsMaterialCodeThumbnailIndexGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Material/thumbnails/{materialCode}/{thumbnailIndex}`
        .replace(
          `{${"materialCode"}}`,
          encodeURIComponent(String(requestParameters.materialCode))
        )
        .replace(
          `{${"thumbnailIndex"}}`,
          encodeURIComponent(String(requestParameters.thumbnailIndex))
        ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async apiV1MaterialThumbnailsMaterialCodeThumbnailIndexGet(
    requestParameters: ApiV1MaterialThumbnailsMaterialCodeThumbnailIndexGetRequest
  ): Promise<Blob> {
    const response =
      await this.apiV1MaterialThumbnailsMaterialCodeThumbnailIndexGetRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   */
  async apiV1MaterialTypeMaterialCodeGetRaw(
    requestParameters: ApiV1MaterialTypeMaterialCodeGetRequest
  ): Promise<runtime.ApiResponse<Blob>> {
    if (
      requestParameters.type === null ||
      requestParameters.type === undefined
    ) {
      throw new runtime.RequiredError(
        "type",
        "Required parameter requestParameters.type was null or undefined when calling apiV1MaterialTypeMaterialCodeGet."
      );
    }

    if (
      requestParameters.materialCode === null ||
      requestParameters.materialCode === undefined
    ) {
      throw new runtime.RequiredError(
        "materialCode",
        "Required parameter requestParameters.materialCode was null or undefined when calling apiV1MaterialTypeMaterialCodeGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Material/{type}/{materialCode}`
        .replace(
          `{${"type"}}`,
          encodeURIComponent(String(requestParameters.type))
        )
        .replace(
          `{${"materialCode"}}`,
          encodeURIComponent(String(requestParameters.materialCode))
        ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async apiV1MaterialTypeMaterialCodeGet(
    requestParameters: ApiV1MaterialTypeMaterialCodeGetRequest
  ): Promise<Blob> {
    const response =
      await this.apiV1MaterialTypeMaterialCodeGetRaw(requestParameters);
    return await response.value();
  }
}
