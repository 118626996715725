import { createSelector } from "reselect";

import { CommercialsPerAdvertiser, MediumEnum } from "../../api/models";
import {
  accountSelector,
  advertisersLoadingSelector,
  advertisersSelector,
  createStructuredAppSelector,
  defaultRelativePeriodSelector,
  initialDatesCombiner,
  organisationsLoadingSelector,
  organisationsSelector,
  settingsSelector,
} from "../../shared/selectors";
import { ReduxStoreState, ResponseError } from "../../store/base";
import { StoreModel } from "../../store/models";
import { productsFromStoreSelector } from "../campaignCreate/selectors";

const commercialsSelector = ({
  commercials: commercialsFromStore,
}: StoreModel): CommercialsPerAdvertiser[] =>
  commercialsFromStore.commercials || [];

const commericalsLoadingSelector = ({
  commercials: commercialsFromStore,
}: StoreModel): boolean => commercialsFromStore.loading;

const commercialsDatesSelector = createSelector(
  [defaultRelativePeriodSelector],
  initialDatesCombiner
);

export const commercialsRootSelector = createStructuredAppSelector({
  account: accountSelector,
  period: commercialsDatesSelector,
  byAdvertiser: commercialsSelector,
  loading: commericalsLoadingSelector,
  settings: settingsSelector,
});

const commercialRegistrationsSelector = ({ commercialsForAudit }: StoreModel) =>
  commercialsForAudit.commercialRegistrations ?? [];

const commercialAuditLoadingSelector = createSelector(
  [
    organisationsLoadingSelector,
    advertisersLoadingSelector,
    ({ commercialsForAudit, commercialReview }: StoreModel): boolean =>
      Boolean(commercialsForAudit.loading) || Boolean(commercialReview.loading),
  ],
  (loadingOrganisations, loadingAdvertisers, loadingRegistrations) =>
    loadingOrganisations || loadingAdvertisers || loadingRegistrations
);

export const commercialAuditSelector = createStructuredAppSelector({
  commercialsForAudit: commercialRegistrationsSelector,
  organisations: organisationsSelector,
  advertisers: advertisersSelector,
  loading: commercialAuditLoadingSelector,
});

interface commercialFilterProps {
  advertiser: number;
  medium: MediumEnum | "";
}

const selectedAdvertiserSelector = (
  _state: StoreModel,
  { advertiser }: commercialFilterProps
): number => advertiser;

const selectedMediumSelector = (
  _state: StoreModel,
  { medium }: commercialFilterProps
): MediumEnum | "" => medium;

export const commercialsFilterSelector = createSelector(
  [commercialsSelector, selectedAdvertiserSelector, selectedMediumSelector],
  (byAdvertiser, advertiser, medium) => {
    const filteredAdvertisers = byAdvertiser
      .filter(
        (a) =>
          advertiser === 0 || (advertiser > 0 && a.advertiserId === advertiser)
      )
      .map((a) => ({
        ...a,
        commercials: a.commercials.filter(({ medium: m }) =>
          medium === "" ? true : medium === m
        ),
        banners: a.banners.filter(({ medium: m }) =>
          medium === "" ? true : medium === m
        ),
      }))
      .filter((s) => s.commercials.length > 0 || s.banners.length > 0);

    const products = filteredAdvertisers
      .flatMap((a) => a.products)
      .sort((a, b) => a.id - b.id);

    return {
      filteredAdvertisers,
      products,
    };
  }
);

const uploadCommercialLoadingSelector = ({
  uploadCommercial: uploadCommercialFromStore,
  products: productsFromStore,
}: StoreModel): boolean =>
  uploadCommercialFromStore.loading || productsFromStore.loading;

const uploadCommercialStateSelector = ({
  uploadCommercial: uploadCommercialFromStore,
}: StoreModel): ReduxStoreState => uploadCommercialFromStore.state;

const uploadCommercialErrorSelector = ({
  uploadCommercial: uploadCommercialFromStore,
}: StoreModel): ResponseError => uploadCommercialFromStore.error;

export const uploadCommercialSelector = createStructuredAppSelector({
  loading: uploadCommercialLoadingSelector,
  uploadState: uploadCommercialStateSelector,
  uploadError: uploadCommercialErrorSelector,
  products: productsFromStoreSelector,
  account: accountSelector,
  organisations: organisationsSelector,
});
