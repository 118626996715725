import "../styles.less";
import "./materialcard.less";

import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Tag, Tooltip, Typography, formatDate } from "@ster/ster-toolkit";
import { useState } from "react";

import { Commercial, MediumEnum } from "../../../../api";
import { getCommercialStatusText, tagStatusMapper } from "../../../../utils";
import MaterialModal from "./MaterialModal";
import MaterialPlayButton from "./MaterialPlayButton";

export interface MaterialCardProps {
  commercial: Commercial;
  footer?: React.ReactNode;
  disabled?: boolean;
  advertiserId?: number;
}

const MaterialCard = ({
  advertiserId,
  commercial,
  footer,
  disabled,
}: MaterialCardProps) => {
  const { i18n } = useLingui();
  const [visible, setVisible] = useState(false);
  const openModal = (): void => {
    setVisible(true);
  };

  const closeModal = (): void => {
    setVisible(false);
  };

  const {
    description,
    creationDate,
    medium,
    material,
    status,
    code,
    productId,
    length,
  } = commercial;
  const { Caption, Footnote, Title } = Typography;

  const formattedCreationDate = creationDate
    ? formatDate(creationDate)
    : undefined;

  return (
    <>
      <article className="card card--material">
        <MaterialPlayButton
          material={material}
          disabled={!!disabled}
          title={`${productId}${code}`}
          onClick={openModal}
        />
        <div className="card__data">
          <Tooltip overlayClassName="card__tooltip" title={description}>
            {medium === MediumEnum.Inter ? (
              <Title level={3}>
                <span className="card__product-id">{code}: </span>
                {description}
              </Title>
            ) : (
              <Title level={3}>
                <span className="card__product-id">{productId}</span>
                {code}: {description}
              </Title>
            )}
          </Tooltip>
          <div className="card__meta-data">
            <Tag
              status={tagStatusMapper(status)}
              text={i18n._(getCommercialStatusText(status))}
            />
            <Caption>{length}&ldquo;</Caption>
            <Footnote>
              {formattedCreationDate && (
                <Trans>toegevoegd op {formattedCreationDate}</Trans>
              )}
            </Footnote>
          </div>
          {footer}
        </div>
      </article>
      <MaterialModal
        title={
          medium === MediumEnum.Inter ? (
            <Title level={3} className="material-modal__title">
              <span className="material-modal__id">{code}: </span>
              {description}
            </Title>
          ) : (
            <Title level={3} className="material-modal__title">
              <span className="material-modal__id">{productId}</span>
              {code}: {description}
            </Title>
          )
        }
        open={visible}
        onCancel={closeModal}
        commercial={commercial}
        creationDate={formattedCreationDate}
        advertiserId={advertiserId}
      />
    </>
  );
};

export default MaterialCard;
