import { MessageDescriptor } from "@lingui/core";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { App } from "antd";
import { PropsWithChildren, useEffect } from "react";

import { removeError } from "../store/errorSlice";
import { useAppDispatch, useAppSelector } from "../utils/hooks";

const endpointTranslations: Record<string, MessageDescriptor> = {
  Getspots: msg`Het ophalen van het overzicht Nu & Straks ging fout.`,
};

const ErrorHandling = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const { _ } = useLingui();
  const { notification } = App.useApp();
  const errors = useAppSelector((state) => state.errors);

  useEffect(() => {
    errors.forEach(({ id, endpointName }) => {
      let name;
      // If the endpointTranslations contains one of the words in the endpointName, use the translation
      const translationKey = Object.keys(endpointTranslations).find(
        (override) => endpointName.includes(override)
      );
      if (translationKey) {
        name = _(endpointTranslations[translationKey]);
      } else {
        // Generic error message with part of the endpoint name
        name = _(
          msg`Er ging iets mis bij ${
            endpointName
              .match(/[A-Z][a-z]+/g)
              ?.slice(1)
              .join("") ?? endpointName
          }.`
        );
      }

      notification.error({
        key: id,
        message: _(msg`Fout`),
        description: (
          <>
            {name}{" "}
            {_(
              msg`Probeer het (later) opnieuw. Mocht het probleem aanhouden neem dan contact op met:`
            )}{" "}
            <a href="mailto:klantportal@ster.nl">klantportal@ster.nl</a>
          </>
        ),
        duration: 0,
        onClose: () => dispatch(removeError(id)),
      });
    });
  }, [_, dispatch, errors, notification]);

  return children;
};

export default ErrorHandling;
