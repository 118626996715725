import "../styles.less";
import "./campaigndetailcard.less";

import { i18n } from "@lingui/core";
import { Trans, t } from "@lingui/macro";
import {
  Button,
  CollapseTillBreakpoint,
  Typography,
  formatNumber,
  formatToEuro,
} from "@ster/ster-toolkit";
import { Space } from "antd";
import classNames from "classnames";
import { useCallback, useMemo } from "react";
import { useMatch, useNavigate } from "react-router-dom";

import {
  MediumEnum,
  OrderDetail,
  OrderStatus,
  PortalSettings,
  RunningState,
} from "../../../../api";
import { useAvailableMediumTypes } from "../../../../utils/hooks";
import ButtonOnlineEndReport from "../../Buttons/ButtonOnlineEndReport";
import HistoryTooltip from "../../Tooltips/HistoryTooltip/HistoryTooltip";
import OrderStatusTooltip from "../../Tooltips/OrderstatusTooltip/OrderStatusTooltip";
import CardStatistic from "../CardStatistic";
import MediumIcon from "../MediumIcon";
import ProgressIcon from "../ProgressIcon";
import {
  getProgress,
  getProgressDate,
  getRunningStateNumber,
  getRunningStateTitle,
} from "../utils";
import CampaignAnalysis from "./CampaignAnalysis";
import { useCampaignAnalysisVisible } from "./CampaignAnalysisHooks";

interface CampaignDetailCardProps {
  cardData: OrderDetail;
  settings: PortalSettings;
}

const RunningValue = ({
  cardData: {
    medium,
    status,
    bookedBudget,
    achievedNettBudget,
    bookedImpressions,
    runningState,
    bonusBudgetBooked,
    subOrders,
  },
}: {
  cardData: OrderDetail;
}) => {
  const formattedBookedImpr = formatNumber(bookedImpressions ?? 0, 0);
  const formattedBonusBudgetBooked = formatToEuro(
    bonusBudgetBooked ?? 0,
    false
  );

  return (
    <CardStatistic
      label={i18n._(
        getRunningStateTitle(
          runningState,
          medium,
          subOrders?.every(
            (s) => s.nacalcGrp !== null && s.nacalcGrp !== undefined
          )
        )
      )}
      value={getRunningStateNumber(
        "€",
        bookedBudget,
        status === OrderStatus.Ready ? bookedBudget : achievedNettBudget,
        runningState
      )}
      subValue={
        bonusBudgetBooked &&
        (runningState === RunningState.Forecast ||
          runningState === RunningState.Combination)
          ? i18n._(t`incl. ${formattedBonusBudgetBooked} bonus`)
          : undefined
      }
      detailValue={
        medium === MediumEnum.Inter ? `# ${formattedBookedImpr}` : undefined
      }
      className="card-stat--detail card-stat--wide card-stat--dark"
    />
  );
};

const CampaignDetailCard = ({
  cardData,
  settings,
}: CampaignDetailCardProps) => {
  const {
    id: orderId,
    productDescr,
    productId,
    medium,
    status,
    advertiserName,
    campaignName,
    requestedBudget,
    bookedGrp,
    achievedGrp,
    requestedGrp,
    orderStartDate,
    orderEndDate,
    requestedImpressions,
    bookedImpressions,
    runningState,
    isSpreaded,
    analysisTargetGroupIds,
    subOrders,
    bonusBudgetRequested,
    analysis,
    bookedBudget,
  } = cardData;

  const navigate = useNavigate();
  const [availableMediumTypes] = useAvailableMediumTypes("aanvragen_formulier");

  const { Title } = Typography;

  const formattedBudget = formatToEuro(requestedBudget, false);
  const formattedRequestedImpr = formatNumber(requestedImpressions ?? 0, 0);
  const formattedBonusBudgetRequested = formatToEuro(
    bonusBudgetRequested ?? 0,
    false
  );

  const progressGrp = getProgress(
    status === OrderStatus.Ready ? bookedGrp : achievedGrp,
    requestedGrp
  );
  const progressImpr =
    bookedImpressions && requestedImpressions
      ? getProgress(bookedImpressions, requestedImpressions)
      : 0;
  const progressDate = getProgressDate(orderStartDate, orderEndDate);

  const editCampaign = useCallback(() => {
    navigate(`/campaigns/edit/${medium}/${orderId}/`);
  }, [medium, navigate, orderId]);

  const isRootDetailPage = !!useMatch("/campaigns/:mediumId/:orderId");

  const isEditable = useMemo(
    () =>
      isRootDetailPage && // Alleen op de hoogdpagina van de campagne tonen
      !isSpreaded &&
      subOrders?.some((s) => (s.editableFields?.length ?? 0) > 0) &&
      availableMediumTypes.includes(medium) &&
      settings?.enableEditCampaign?.[medium],
    [
      availableMediumTypes,
      isRootDetailPage,
      isSpreaded,
      medium,
      settings?.enableEditCampaign,
      subOrders,
    ]
  );

  const campaignAnalysisIsVisible = useCampaignAnalysisVisible(
    cardData,
    settings
  );

  return (
    <article
      className={classNames(
        "card card--detail",
        `card--detail--${runningState.toLowerCase()}`
      )}
    >
      <header className="detail-header">
        <div className="card-titles">
          <Title>{campaignName !== "" ? campaignName : productDescr}</Title>
          <span className="advertiser-label">{advertiserName}</span>
        </div>
        <div className="card-meta">
          <CollapseTillBreakpoint value={767}>
            <span className="advertiser-label advertiser-label--mobile">
              {advertiserName}
            </span>
            <span>
              {productDescr} ({productId})
            </span>
            <MediumIcon medium={medium} />
            {cardData.auditLogs.length > 0 && (
              <HistoryTooltip logs={cardData.auditLogs} />
            )}
            <OrderStatusTooltip status={status} />
          </CollapseTillBreakpoint>
        </div>
      </header>
      <div className="card-body">
        <section className="card__stats-wrapper">
          <CardStatistic
            label={i18n._(t`Aanvraag`)}
            value={formattedBudget}
            subValue={
              bonusBudgetRequested
                ? i18n._(t`incl. ${formattedBonusBudgetRequested} bonus`)
                : undefined
            }
            detailValue={
              medium === MediumEnum.Inter
                ? `# ${formattedRequestedImpr}`
                : undefined
            }
            className="card-stat--detail"
          />
          {!(runningState === RunningState.Forecast && !bookedGrp) && (
            <RunningValue cardData={cardData} />
          )}
        </section>
        <section className="card__stats-wrapper card__stats-wrapper--column">
          <div className="card-stat__label">
            <Trans>Voortgang campagne</Trans>
          </div>
          <div className="icons-wrapper">
            <ProgressIcon
              label={
                medium === MediumEnum.Inter ? i18n._(t`IMPR.`) : i18n._(t`GRP`)
              }
              value={medium === MediumEnum.Inter ? progressImpr : progressGrp}
              type="grp"
              size="large"
            />
            <ProgressIcon
              label={i18n._(t`Looptijd`)}
              value={progressDate}
              type="date"
              size="large"
            />
          </div>
        </section>
      </div>

      <Space className="card-footer card-row" wrap>
        {isEditable && (
          <Button mode="secondary" onClick={editCampaign}>
            <Trans>Wijzigen</Trans>
          </Button>
        )}
        {status === "Ready" && subOrders && (
          <ButtonOnlineEndReport
            mode="primary"
            orderId={orderId}
            subOrders={subOrders}
            isReady
          />
        )}
        {campaignAnalysisIsVisible && (
          <CampaignAnalysis
            medium={medium}
            order={{
              id: orderId,
              startDate: orderStartDate,
              endDate: orderEndDate,
              analysis,
              name: campaignName,
              productDescr,
              productId,
              budget: requestedBudget,
              bookedBudget,
            }}
            targetGroupIds={analysisTargetGroupIds ?? []}
            nmoSwitchDate={settings.nmoSwitchDate}
          />
        )}
      </Space>
    </article>
  );
};

export default CampaignDetailCard;
