import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Form,
  Icons,
  Option,
  Radio,
  RangePicker,
  Select,
} from "@ster/ster-toolkit";
import { Form as AntForm, FormInstance } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Advertiser, MediumEnum } from "../../../api";
import { receiveProductsAction } from "../../../store/campaignCreate/actions";
import { getLanguage } from "../../../utils";
import { productsFromStoreSelector } from "../../campaignCreate/selectors";

export interface ProductsPartialFormData {
  period: [Date, Date];
  medium?: MediumEnum;
  advertiserId?: number;
  productId?: number;
}

interface ProductsPartialFormProps {
  mediumTypes: MediumEnum[];
  disabledDate?: (date: Date) => boolean;
  form: FormInstance<ProductsPartialFormData>;
  hideRangePicker?: boolean;
}

const ProductsPartialForm = ({
  form,
  mediumTypes,
  disabledDate,
  hideRangePicker = false,
}: ProductsPartialFormProps) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();

  const medium = AntForm.useWatch("medium", form);
  const period = AntForm.useWatch("period", form);

  const productsFromStore = useSelector(productsFromStoreSelector);
  const productsByAdvertiser = useMemo(
    () => productsFromStore.products ?? [],
    [productsFromStore.products]
  );

  const displayedMediumTypes = mediumTypes;

  useEffect(() => {
    if (!medium || !period) {
      return;
    }

    dispatch(
      receiveProductsAction.request({
        medium,
        from: period[0],
        to: period[1],
      })
    );
  }, [dispatch, medium, period]);

  const [advertiser, setAdvertiser] = useState<Advertiser | undefined>(
    undefined
  );

  const changeAdvertiser = useCallback(
    (advertiserId: number | undefined) => {
      const newAdvertiser = productsByAdvertiser.find(
        (p) => p.id === advertiserId
      );
      setAdvertiser(newAdvertiser);
    },
    [productsByAdvertiser]
  );

  useEffect(() => {
    const productId =
      advertiser?.products?.length === 1
        ? advertiser?.products?.[0].id
        : undefined;
    form.setFieldsValue({ productId });
  }, [advertiser, form]);

  return (
    <>
      {hideRangePicker ? (
        <Form.Item name="period" noStyle>
          <input type="hidden" />
        </Form.Item>
      ) : (
        <Form.Item<ProductsPartialFormData>
          label={i18n._(t`Periode`)}
          name="period"
          rules={[
            {
              required: true,
              message: i18n._(t`Kies een periode`),
            },
          ]}
        >
          <RangePicker
            allowClear={false}
            componentLocale={getLanguage()}
            disabledDate={disabledDate}
          />
        </Form.Item>
      )}
      {displayedMediumTypes.length === 1 ? (
        <Form.Item name="medium" noStyle>
          <input type="hidden" />
        </Form.Item>
      ) : (
        <Form.Item<ProductsPartialFormData>
          label={i18n._(t`Medium`)}
          name="medium"
          rules={[
            {
              required: true,
              message: i18n._(t`Kies een medium`),
            },
          ]}
        >
          <Radio.Group
            mode="horizontal"
            name="radioMediaGroup"
            style={{
              height: 36,
              display: "flex",
            }}
          >
            {displayedMediumTypes.includes(MediumEnum.Tv) && (
              <Radio value="tv">
                <Trans>Televisie</Trans>
                <Icons.TVIcon width={20} height={20} />
              </Radio>
            )}
            {displayedMediumTypes.includes(MediumEnum.Radio) && (
              <Radio value="radio">
                <Trans>Radio</Trans>
                <Icons.RadioIcon width={20} height={20} />
              </Radio>
            )}
          </Radio.Group>
        </Form.Item>
      )}
      <Form.Item<ProductsPartialFormData>
        label={i18n._(t`Adverteerder`)}
        name="advertiserId"
        rules={[
          {
            required: true,
            message: i18n._(t`Kies een adverteerder`),
          },
        ]}
        dependencies={["period"]}
      >
        <Select.Search
          disabled={productsByAdvertiser.length === 0}
          placeholder={i18n._(t`Kies een adverteerder`)}
          optionFilterProp="children"
          loading={productsFromStore.loading}
          onChange={changeAdvertiser}
        >
          {productsByAdvertiser
            ?.filter((s) => !s.isProspect)
            .map((advertiserItem) => (
              <Option value={advertiserItem.id} key={advertiserItem.id}>
                {advertiserItem.name}
              </Option>
            ))}
        </Select.Search>
      </Form.Item>
      <Form.Item<ProductsPartialFormData>
        label={i18n._(t`Sternummer`)}
        name="productId"
        rules={[
          {
            required: true,
            message: i18n._(t`Kies een sternummer`),
          },
        ]}
        dependencies={["advertiserId"]}
      >
        <Select.Search
          disabled={productsByAdvertiser.length === 0}
          placeholder={i18n._(t`Kies een sternummer`)}
          optionFilterProp="children"
          loading={productsFromStore.loading}
        >
          {advertiser?.products?.map((product) => (
            <Option value={product.id} key={product.id}>
              {`${product.description} - ${product.id}`}
            </Option>
          ))}
        </Select.Search>
      </Form.Item>
    </>
  );
};

export default ProductsPartialForm;
