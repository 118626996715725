import type { Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { isRejectedWithValue } from "@reduxjs/toolkit";

import { addError } from "./errorSlice";

type ActionArg = {
  type: string;
  endpointName: string;
};

const errorMiddleware: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      const args = action.meta.arg as ActionArg;

      api.dispatch(addError({ endpointName: args.endpointName }));
    }

    return next(action);
  };

export default errorMiddleware;
