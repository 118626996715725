import { Modal, Typography } from "@ster/ster-toolkit";

import { ExternalMaterial } from "../../../api";
import LinkExternalMaterialForm from "./LinkExternalMaterialForm";

const LinkExternalMaterialModal = ({
  externalMaterial,
  onCancel,
  onLinked,
}: {
  externalMaterial: ExternalMaterial;
  onCancel: () => void;
  onLinked: () => void;
}) => (
  <Modal
    destroyOnClose
    footer={null}
    title={
      <Typography.Title level={3} className="material-modal__title">
        {externalMaterial.name}
      </Typography.Title>
    }
    open
    onCancel={onCancel}
    wrapClassName="material-modal"
    width={1000}
  >
    <LinkExternalMaterialForm
      externalMaterial={externalMaterial}
      onLinked={onLinked}
    />
  </Modal>
);

export default LinkExternalMaterialModal;
