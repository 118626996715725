import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { App as AntApp } from "antd";
import { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import store from "../store";
import { dynamicActivate } from "../translations";
import { getLanguage } from "../utils";
import App from "./App";
import ErrorHandling from "./ErrorHandling";

export const router = createBrowserRouter([
  // match everything with "*"
  { path: "*", element: <App /> },
]);

const AppContainer = () => {
  useEffect(() => {
    dynamicActivate(getLanguage());
  }, []);

  return (
    <I18nProvider i18n={i18n}>
      <AntApp>
        <Provider store={store}>
          <ErrorHandling>
            <HelmetProvider>
              <RouterProvider router={router} />
            </HelmetProvider>
          </ErrorHandling>
        </Provider>
      </AntApp>
    </I18nProvider>
  );
};

export default AppContainer;
