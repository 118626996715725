import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  ContentContainer,
  Icons,
  Pageheader,
  Spinner,
} from "@ster/ster-toolkit";
import { Dropdown, Space } from "antd";
import { startOfDay } from "date-fns";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { MediumEnum, TenantEnum } from "../../api/models";
import { Period } from "../../shared/models";
import { receiveCommercialsAction } from "../../store/commercials/actions";
import { useAvailableMediumTypes } from "../../utils/hooks";
import CommercialList from "./CommercialList";
import { commercialsRootSelector } from "./selectors";

const CommercialContainer = memo(() => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // map state to props
  const {
    account,
    byAdvertiser,
    period: initialPeriod,
    loading,
    settings,
  } = useSelector(commercialsRootSelector);

  // Only "Admin's" or Ster employees can approve commercials for digital
  const canApprove = useMemo(
    () =>
      account &&
      account.claims &&
      account.claims.includes("material_inter") &&
      (account.sterInlog?.internalUser || account.roles.includes("Admin")),
    [account]
  );

  // Todo: Only "Admin's" or Ster employees can link material
  const canLinkMaterial = useMemo(
    () =>
      settings.settings?.tenant === TenantEnum.Regional &&
      (account.sterInlog?.internalUser || account.roles.includes("Admin")),
    [account, settings]
  );

  // filteren op periode
  const [period, setPeriod] = useState<Period>(initialPeriod);
  const handlePeriodChange = useCallback(
    (dateFrom?: Date | null, dateTo?: Date | null) => {
      if (dateFrom && dateTo) {
        setPeriod({
          from: startOfDay(dateFrom),
          to: startOfDay(dateTo),
        });
      } else {
        setPeriod(initialPeriod);
      }
    },
    [initialPeriod]
  );

  // filteren op medium
  const [availableMediumTypes, _] = useAvailableMediumTypes("materialinzien");

  const [medium, setMedium] = useState<MediumEnum | "">("");

  const handleMediumChange = useCallback((value: MediumEnum) => {
    setMedium(value);
  }, []);

  // haal commercials op/vernieuwen
  useEffect((): void => {
    const { from, to } = period;
    if (from && to) {
      dispatch(receiveCommercialsAction.request({ from, to }));
    }
  }, [dispatch, period]);

  return (
    <>
      <Helmet>
        <title>{i18n._(t`Materiaal`)}</title>
      </Helmet>

      <Pageheader
        title={i18n._(t`Materiaal`)}
        icon={
          <Icons.MaterialIcon
            width="100%"
            height="100%"
            fill="rgba(129, 176, 210, 0.2)"
          />
        }
      >
        <Space>
          {canApprove && (
            <Link to="/material/audit/inter" className="hide-for-mobile">
              <Button mode="tertiary">
                <Space>
                  <Icons.EyeIcon width={20} height={20} fill="#008ccc" />
                  <Trans>Materiaal goed-/afkeuren</Trans>
                </Space>
              </Button>
            </Link>
          )}
          {canLinkMaterial && (
            <Link to="/material/link" className="hide-for-mobile">
              <Button mode="tertiary">
                <Space>
                  <Icons.EyeIcon width={20} height={20} fill="#008ccc" />
                  <Trans>Extern materiaal koppelen</Trans>
                </Space>
              </Button>
            </Link>
          )}
          <Dropdown
            overlayClassName="more-menu"
            menu={{
              items: [
                {
                  label: <Trans>Televisie</Trans>,
                  key: "tv",
                  icon: <Icons.TVIcon width={20} height={20} />,
                  className: "more-menu-item",
                  onClick: () =>
                    window.open(
                      "https://eat.ster.nl/",
                      "_blank",
                      "noopener,noreferrer"
                    ),
                },
                {
                  label: <Trans>Radio</Trans>,
                  key: "radio",
                  icon: <Icons.RadioIcon width={20} height={20} />,
                  className: "more-menu-item",
                  onClick: () =>
                    window.open(
                      "https://www.spotwave.nl/",
                      "_blank",
                      "noopener,noreferrer"
                    ),
                },
                {
                  label: <Trans>Online</Trans>,
                  key: "inter",
                  icon: <Icons.OnlineIcon width={20} height={20} />,
                  className: "more-menu-item",
                  onClick: () => navigate("/material/upload/inter"),
                },
              ],
            }}
          >
            <Button mode="primary">
              <Trans>Materiaal uploaden</Trans>{" "}
              <Icons.ChevronDownIcon fill="white" />
            </Button>
          </Dropdown>
        </Space>
      </Pageheader>
      <ContentContainer>
        <Spinner spinning={loading}>
          <CommercialList
            byAdvertiser={byAdvertiser}
            period={period}
            onPeriodChange={handlePeriodChange}
            medium={medium}
            onMediumChange={handleMediumChange}
            availableMediumTypes={availableMediumTypes}
          />
        </Spinner>
      </ContentContainer>
    </>
  );
});

export default CommercialContainer;
