import "../styles.less";
import "./commercialpicker.module.less";

import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Icons, Input, Tooltip } from "@ster/ster-toolkit";
import { ChangeEvent, useCallback, useMemo } from "react";

import {
  Commercial,
  CommercialInstructionSpots,
  CommercialReference,
  CommercialStatus,
  CommercialsPerAdvertiser,
  MediumEnum,
} from "../../../../api";
import CommercialButton from "./CommercialButton";

interface CommercialRowProps {
  selection: CommercialInstructionSpots;
  onRemove?: (commercialId: number) => void;
  onReady: (item: CommercialInstructionSpots) => void;
  commercialsPerAdvertiser?: CommercialsPerAdvertiser;
  canRemove: boolean;
  spotLength: number[];
  productId: number;
  medium: MediumEnum;
  rotationPercentage: number | null;
}

const CommercialRow = ({
  onRemove,
  onReady,
  commercialsPerAdvertiser,
  canRemove,
  spotLength,
  selection,
  productId,
  medium,
  rotationPercentage,
}: CommercialRowProps) => {
  const { i18n } = useLingui();
  const { EditIcon, PlayIcon, DeleteIcon } = Icons;
  const { id, commercial, tagOns, rotationFrequency } = selection;

  const handleSelectMain = useCallback(
    (_index: number, selectedCommercial: CommercialReference) => {
      onReady({
        id,
        commercial: selectedCommercial,
        tagOns,
        rotationFrequency,
      });
    },
    [id, onReady, rotationFrequency, tagOns]
  );

  const handleOnRemove = useCallback(() => {
    if (onRemove) {
      onRemove(id);
    }
  }, [id, onRemove]);

  const handleSelectTagOn = useCallback(
    (index: number, selected: CommercialReference) => {
      let newTagOns = [selected];
      if (tagOns) {
        newTagOns = [
          ...tagOns.slice(0, index),
          selected as CommercialReference,
          ...tagOns.slice(index + 1, tagOns.length),
        ];
      }
      onReady({
        id,
        commercial,
        tagOns: newTagOns,
        rotationFrequency,
      });
    },
    [commercial, id, onReady, rotationFrequency, tagOns]
  );

  const handleChangeFrequency = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      onReady({
        id,
        commercial,
        tagOns,
        rotationFrequency: target.value ? Number(target.value) : undefined,
      });
    },
    [commercial, id, onReady, tagOns]
  );

  const nieuw = useMemo(
    () =>
      ({
        id: "Nieuw",
        code: "Nieuw",
        description: i18n._(t`Nog aanleveren`),
        status: CommercialStatus.Nieuw,
        creationDate: new Date(),
        length: -1,
        productId,
        material: {
          identifier: {
            medium: MediumEnum.Tv,
            code: "Nieuw",
          },
          thumbnails: [],
          uriPath: "",
        },
        medium,
      }) as Commercial,
    [i18n, medium, productId]
  );

  const filterCommercialsWithNew = useCallback(
    (length: number) =>
      ({
        ...commercialsPerAdvertiser,
        commercials: [
          {
            ...nieuw,
            length,
          },
          ...(commercialsPerAdvertiser?.commercials.filter(
            (s) => s.length === length
          ) ?? []),
        ],
      }) as CommercialsPerAdvertiser,
    [commercialsPerAdvertiser, nieuw]
  );

  return (
    <div className="picker-row">
      <div className="picker-row--commercial">
        <CommercialButton
          selected={selection}
          onSelectCommercial={handleSelectMain}
          commercialsPerAdvertiser={filterCommercialsWithNew(spotLength[0])}
          index={0}
        >
          {commercial ? (
            <>
              <span className="icon-wrapper play">
                <PlayIcon width={20} height={20} />
              </span>
              <Tooltip title={commercial.description}>{commercial.id}</Tooltip>
              <EditIcon width={16} height={16} />
            </>
          ) : (
            <Trans>Kies hoofdspot</Trans>
          )}
        </CommercialButton>
        {tagOns?.map((tagOn, index) => {
          const length = spotLength[index + 1];
          return (
            <CommercialButton
              selected={{ commercial: tagOn, id: 0 }}
              onSelectCommercial={handleSelectTagOn}
              commercialsPerAdvertiser={filterCommercialsWithNew(length)}
              index={index}
              key={`${length + index}`}
            >
              {tagOn.code ? (
                <>
                  <span className="icon-wrapper">
                    <PlayIcon width={20} height={20} />
                  </span>
                  <Tooltip title={tagOn.description}>{tagOn.id}</Tooltip>
                  <EditIcon width={16} height={16} />
                </>
              ) : (
                <Trans>Kies tag-on</Trans>
              )}
            </CommercialButton>
          );
        })}
      </div>
      <div className="picker-row__input">
        <Input
          type="number"
          onChange={handleChangeFrequency}
          value={
            rotationFrequency && rotationFrequency > 0 ? rotationFrequency : ""
          }
        />
      </div>
      <span className="picker-row__percentage">
        {!!rotationPercentage && `${rotationPercentage}%`}
      </span>
      <div className="picker-row__buttons">
        {onRemove && (
          <Tooltip
            className="picker-row__tooltip"
            title={<Trans>Regel verwijderen</Trans>}
          >
            <button
              className="picker-row__button"
              type="button"
              disabled={!canRemove}
              onClick={handleOnRemove}
            >
              <DeleteIcon />
            </button>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default CommercialRow;
