/* eslint-disable jsx-a11y/media-has-caption */
import { memo } from "react";

import { Material } from "../../api";

const AudioPlayer = memo(({ material }: { material: Material }) => (
  <audio controls style={{ width: "100%" }}>
    <source src={material.uriPath} type="audio/mp3" />
    <p>Het audio element wordt niet door uw browser ondersteund.</p>
  </audio>
));

export default AudioPlayer;
