/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Banner,
  BannerFromJSON,
  BannerFromJSONTyped,
  BannerToJSON,
  CommercialReference,
  CommercialReferenceFromJSON,
  CommercialReferenceFromJSONTyped,
  CommercialReferenceToJSON,
  DateSpan,
  DateSpanFromJSON,
  DateSpanFromJSONTyped,
  DateSpanToJSON,
} from "./";

/**
 *
 * @export
 * @interface CommercialInstructionSpots
 */
export interface CommercialInstructionSpots {
  /**
   *
   * @type {number}
   * @memberof CommercialInstructionSpots
   */
  id: number;
  /**
   *
   * @type {CommercialReference}
   * @memberof CommercialInstructionSpots
   */
  commercial?: CommercialReference;
  /**
   *
   * @type {Banner}
   * @memberof CommercialInstructionSpots
   */
  banner?: Banner;
  /**
   *
   * @type {DateSpan}
   * @memberof CommercialInstructionSpots
   */
  period?: DateSpan;
  /**
   *
   * @type {string}
   * @memberof CommercialInstructionSpots
   */
  startTime?: string | null;
  /**
   *
   * @type {string}
   * @memberof CommercialInstructionSpots
   */
  endTime?: string | null;
  /**
   *
   * @type {string}
   * @memberof CommercialInstructionSpots
   */
  channel?: string | null;
  /**
   *
   * @type {Array<CommercialReference>}
   * @memberof CommercialInstructionSpots
   */
  tagOns?: Array<CommercialReference> | null;
  /**
   *
   * @type {number}
   * @memberof CommercialInstructionSpots
   */
  rotationFrequency?: number | null;
  /**
   *
   * @type {string}
   * @memberof CommercialInstructionSpots
   */
  homepage?: string | null;
  /**
   *
   * @type {Array<number>}
   * @memberof CommercialInstructionSpots
   */
  selectedDays?: Array<number> | null;
}

export function CommercialInstructionSpotsFromJSON(
  json: any
): CommercialInstructionSpots {
  return CommercialInstructionSpotsFromJSONTyped(json, false);
}

export function CommercialInstructionSpotsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CommercialInstructionSpots {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    commercial: !exists(json, "commercial")
      ? undefined
      : CommercialReferenceFromJSON(json["commercial"]),
    banner: !exists(json, "banner")
      ? undefined
      : BannerFromJSON(json["banner"]),
    period: !exists(json, "period")
      ? undefined
      : DateSpanFromJSON(json["period"]),
    startTime: !exists(json, "startTime") ? undefined : json["startTime"],
    endTime: !exists(json, "endTime") ? undefined : json["endTime"],
    channel: !exists(json, "channel") ? undefined : json["channel"],
    tagOns: !exists(json, "tagOns")
      ? undefined
      : json["tagOns"] === null
        ? null
        : (json["tagOns"] as Array<any>).map(CommercialReferenceFromJSON),
    rotationFrequency: !exists(json, "rotationFrequency")
      ? undefined
      : json["rotationFrequency"],
    homepage: !exists(json, "homepage") ? undefined : json["homepage"],
    selectedDays: !exists(json, "selectedDays")
      ? undefined
      : json["selectedDays"],
  };
}

export function CommercialInstructionSpotsToJSON(
  value?: CommercialInstructionSpots | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    commercial: CommercialReferenceToJSON(value.commercial),
    banner: BannerToJSON(value.banner),
    period: DateSpanToJSON(value.period),
    startTime: value.startTime,
    endTime: value.endTime,
    channel: value.channel,
    tagOns:
      value.tagOns === undefined
        ? undefined
        : value.tagOns === null
          ? null
          : (value.tagOns as Array<any>).map(CommercialReferenceToJSON),
    rotationFrequency: value.rotationFrequency,
    homepage: value.homepage,
    selectedDays: value.selectedDays,
  };
}
