import { Action } from "redux";
import { Reducer, createReducer } from "typesafe-actions";

import { TermsAndConditionsResult } from "../../api/models";
import { ReduxStoreState } from "../base";
import { LoadingProductTourResult } from "../models";
import { postProductTourAction, receiveProductTourAction } from "./actions";

// eslint-disable-next-line import/prefer-default-export
export const productTourReducer: Reducer<LoadingProductTourResult, Action> =
  createReducer({
    state: ReduxStoreState.Initial,
  })
    .handleAction(
      receiveProductTourAction.request,
      (state: TermsAndConditionsResult) => ({
        ...state,
        loading: true,
        state: ReduxStoreState.Loading,
      })
    )
    .handleAction(
      postProductTourAction.request,
      (state: LoadingProductTourResult) => ({
        ...state,
        loading: true,
        state: ReduxStoreState.Loading,
      })
    )
    .handleAction(
      receiveProductTourAction.failure,
      (state: LoadingProductTourResult) => ({
        ...state,
        loading: false,
        state: ReduxStoreState.Failure,
      })
    )
    .handleAction(
      postProductTourAction.failure,
      (state: LoadingProductTourResult) => ({
        ...state,
        loading: false,
        state: ReduxStoreState.Failure,
      })
    )
    .handleAction(
      receiveProductTourAction.success,
      (
        state: LoadingProductTourResult,
        action: ReturnType<typeof receiveProductTourAction.success>
      ) => ({
        ...action.payload,
        loading: false,
        state: ReduxStoreState.Success,
      })
    )
    .handleAction(
      postProductTourAction.success,
      (
        state: LoadingProductTourResult,
        action: ReturnType<typeof postProductTourAction.success>
      ) => ({
        ...action.payload,
        loading: false,
        state: ReduxStoreState.Success,
      })
    );
