import { Action } from "redux";
import { Reducer, createReducer } from "typesafe-actions";

import { EditableSetting } from "../../api";
import { genericReducer } from "../../utils";
import { Loading, ReduxStoreState } from "../base";
import {
  getEditableSettingsAction,
  updateEditableSettingAction,
} from "./actions";
import { EditableSettingsOutput } from "./models";

const empty: EditableSetting[] = [];

export const getEditableSettingsReducer: Reducer<
  EditableSettingsOutput,
  Action
> = createReducer({
  state: ReduxStoreState.Initial,
})
  .handleAction(
    getEditableSettingsAction.request,
    (state: EditableSettingsOutput) => ({
      ...state,
      loading: true,
      state: ReduxStoreState.Loading,
    })
  )
  .handleAction(
    getEditableSettingsAction.failure,
    (state: EditableSettingsOutput) => ({
      ...state,
      loading: false,
      state: ReduxStoreState.Failure,
    })
  )
  .handleAction(
    getEditableSettingsAction.success,
    (
      state: EditableSettingsOutput,
      action: ReturnType<typeof getEditableSettingsAction.success>
    ) => ({
      editable: action.payload || empty,
      loading: false,
      state: ReduxStoreState.Success,
    })
  )
  .handleAction(
    updateEditableSettingAction.success,
    (
      state: EditableSettingsOutput,
      action: ReturnType<typeof updateEditableSettingAction.success>
    ) => {
      const editable = [
        ...state.editable.filter((s) => s.alias !== action.payload.alias),
        action.payload,
      ].sort((a, b) => a.alias!.localeCompare(b.alias!));

      return {
        editable,
        loading: false,
        state: ReduxStoreState.Success,
      };
    }
  );

export const updateEditableSettingReducer: Reducer<Loading, Action> =
  genericReducer(
    updateEditableSettingAction.request,
    updateEditableSettingAction.success,
    updateEditableSettingAction.failure
  );
