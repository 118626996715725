import { baseApi as api } from "./baseApi";
export const addTagTypes = [
  "Account",
  "Announcements",
  "AssetManagementWebHook",
  "BookSpot",
  "Cache",
  "Campaign",
  "CampaignAnalysis",
  "CampaignForward",
  "CommercialInstructions",
  "Commercials",
  "Customer",
  "Document",
  "Download",
  "Forecast",
  "Generic",
  "Invoice",
  "Planning",
  "ProductTour",
  "Proposal",
  "Ratecard",
  "Request",
  "Settings",
  "SpotTracker",
  "TermsAndConditions",
  "Tips",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getApiV1AccountMe: build.query<
        GetApiV1AccountMeApiResponse,
        GetApiV1AccountMeApiArg
      >({
        query: () => ({ url: `/api/v1/Account/me` }),
        providesTags: ["Account"],
      }),
      getApiV1AccountOrganisations: build.query<
        GetApiV1AccountOrganisationsApiResponse,
        GetApiV1AccountOrganisationsApiArg
      >({
        query: () => ({ url: `/api/v1/Account/organisations` }),
        providesTags: ["Account"],
      }),
      getApiV1AccountAdvertisers: build.query<
        GetApiV1AccountAdvertisersApiResponse,
        GetApiV1AccountAdvertisersApiArg
      >({
        query: () => ({ url: `/api/v1/Account/advertisers` }),
        providesTags: ["Account"],
      }),
      postApiV1AccountDeleteByUserId: build.mutation<
        PostApiV1AccountDeleteByUserIdApiResponse,
        PostApiV1AccountDeleteByUserIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Account/delete/${queryArg.userId}`,
          method: "POST",
        }),
        invalidatesTags: ["Account"],
      }),
      deleteApiV1AccountDeleteByUserId: build.mutation<
        DeleteApiV1AccountDeleteByUserIdApiResponse,
        DeleteApiV1AccountDeleteByUserIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Account/delete/${queryArg.userId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Account"],
      }),
      deleteApiV1AccountDeactivate: build.mutation<
        DeleteApiV1AccountDeactivateApiResponse,
        DeleteApiV1AccountDeactivateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Account/deactivate`,
          method: "DELETE",
          body: queryArg.deactivateUserInput,
        }),
        invalidatesTags: ["Account"],
      }),
      postApiV1AccountActivateByUserId: build.mutation<
        PostApiV1AccountActivateByUserIdApiResponse,
        PostApiV1AccountActivateByUserIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Account/activate/${queryArg.userId}`,
          method: "POST",
        }),
        invalidatesTags: ["Account"],
      }),
      postApiV1AccountUpdateByUserId: build.mutation<
        PostApiV1AccountUpdateByUserIdApiResponse,
        PostApiV1AccountUpdateByUserIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Account/update/${queryArg.userId}`,
          method: "POST",
          body: queryArg.userModel,
        }),
        invalidatesTags: ["Account"],
      }),
      postApiV1AccountRegister: build.mutation<
        PostApiV1AccountRegisterApiResponse,
        PostApiV1AccountRegisterApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Account/register`,
          method: "POST",
          body: queryArg.userModel,
        }),
        invalidatesTags: ["Account"],
      }),
      getApiV1AccountAll: build.query<
        GetApiV1AccountAllApiResponse,
        GetApiV1AccountAllApiArg
      >({
        query: () => ({ url: `/api/v1/Account/all` }),
        providesTags: ["Account"],
      }),
      getApiV1AccountByUserId: build.query<
        GetApiV1AccountByUserIdApiResponse,
        GetApiV1AccountByUserIdApiArg
      >({
        query: (queryArg) => ({ url: `/api/v1/Account/${queryArg.userId}` }),
        providesTags: ["Account"],
      }),
      getApiV1AccountExport: build.query<
        GetApiV1AccountExportApiResponse,
        GetApiV1AccountExportApiArg
      >({
        query: () => ({ url: `/api/v1/Account/export` }),
        providesTags: ["Account"],
      }),
      getApiV1Announcements: build.query<
        GetApiV1AnnouncementsApiResponse,
        GetApiV1AnnouncementsApiArg
      >({
        query: () => ({ url: `/api/v1/Announcements` }),
        providesTags: ["Announcements"],
      }),
      postApiV1Announcements: build.mutation<
        PostApiV1AnnouncementsApiResponse,
        PostApiV1AnnouncementsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Announcements`,
          method: "POST",
          body: queryArg.announcementDto,
        }),
        invalidatesTags: ["Announcements"],
      }),
      getApiV1AnnouncementsAll: build.query<
        GetApiV1AnnouncementsAllApiResponse,
        GetApiV1AnnouncementsAllApiArg
      >({
        query: () => ({ url: `/api/v1/Announcements/all` }),
        providesTags: ["Announcements"],
      }),
      deleteApiV1AnnouncementsById: build.mutation<
        DeleteApiV1AnnouncementsByIdApiResponse,
        DeleteApiV1AnnouncementsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Announcements/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Announcements"],
      }),
      postApiV1AssetManagementWebHookUpdate: build.mutation<
        PostApiV1AssetManagementWebHookUpdateApiResponse,
        PostApiV1AssetManagementWebHookUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/AssetManagementWebHook/update`,
          method: "POST",
          body: queryArg.assetManagementHook,
        }),
        invalidatesTags: ["AssetManagementWebHook"],
      }),
      postApiV1BookSpot: build.mutation<
        PostApiV1BookSpotApiResponse,
        PostApiV1BookSpotApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/BookSpot`,
          method: "POST",
          body: queryArg.bookSpotCollection,
        }),
        invalidatesTags: ["BookSpot"],
      }),
      getApiV1CacheList: build.query<
        GetApiV1CacheListApiResponse,
        GetApiV1CacheListApiArg
      >({
        query: () => ({ url: `/api/v1/Cache/list` }),
        providesTags: ["Cache"],
      }),
      getApiV1CacheClear: build.query<
        GetApiV1CacheClearApiResponse,
        GetApiV1CacheClearApiArg
      >({
        query: () => ({ url: `/api/v1/Cache/clear` }),
        providesTags: ["Cache"],
      }),
      getApiV1CampaignCodeByCampaignCode: build.query<
        GetApiV1CampaignCodeByCampaignCodeApiResponse,
        GetApiV1CampaignCodeByCampaignCodeApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Campaign/code/${queryArg.campaignCode}`,
        }),
        providesTags: ["Campaign"],
      }),
      postApiV1CampaignCode: build.mutation<
        PostApiV1CampaignCodeApiResponse,
        PostApiV1CampaignCodeApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Campaign/code`,
          method: "POST",
          body: queryArg.campaignConfigurationInput,
        }),
        invalidatesTags: ["Campaign"],
      }),
      getApiV1CampaignEndreportByMediumAndPackageChoiceOrderIdFileType:
        build.query<
          GetApiV1CampaignEndreportByMediumAndPackageChoiceOrderIdFileTypeApiResponse,
          GetApiV1CampaignEndreportByMediumAndPackageChoiceOrderIdFileTypeApiArg
        >({
          query: (queryArg) => ({
            url: `/api/v1/Campaign/endreport/${queryArg.medium}/${queryArg.packageChoice}/${queryArg.orderId}/${queryArg.fileType}`,
            params: {
              subOrderId: queryArg.subOrderId,
            },
          }),
          providesTags: ["Campaign"],
        }),
      getApiV1CampaignGetorders: build.query<
        GetApiV1CampaignGetordersApiResponse,
        GetApiV1CampaignGetordersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Campaign/getorders`,
          params: {
            from: queryArg["from"],
            to: queryArg.to,
          },
        }),
        providesTags: ["Campaign"],
      }),
      getApiV1CampaignGetorder: build.query<
        GetApiV1CampaignGetorderApiResponse,
        GetApiV1CampaignGetorderApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Campaign/getorder`,
          params: {
            medium: queryArg.medium,
            orderId: queryArg.orderId,
          },
        }),
        providesTags: ["Campaign"],
      }),
      getApiV1CampaignGetorderwithspots: build.query<
        GetApiV1CampaignGetorderwithspotsApiResponse,
        GetApiV1CampaignGetorderwithspotsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Campaign/getorderwithspots`,
          params: {
            medium: queryArg.medium,
            orderId: queryArg.orderId,
          },
        }),
        providesTags: ["Campaign"],
      }),
      getApiV1CampaignGeteditablecampaign: build.query<
        GetApiV1CampaignGeteditablecampaignApiResponse,
        GetApiV1CampaignGeteditablecampaignApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Campaign/geteditablecampaign`,
          params: {
            medium: queryArg.medium,
            orderId: queryArg.orderId,
          },
        }),
        providesTags: ["Campaign"],
      }),
      getApiV1CampaignGetspotsbyorder: build.query<
        GetApiV1CampaignGetspotsbyorderApiResponse,
        GetApiV1CampaignGetspotsbyorderApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Campaign/getspotsbyorder`,
          params: {
            medium: queryArg.medium,
            orderId: queryArg.orderId,
            secondaryTargetGroupId: queryArg.secondaryTargetGroupId,
          },
        }),
        providesTags: ["Campaign"],
      }),
      getApiV1CampaignGetspots: build.query<
        GetApiV1CampaignGetspotsApiResponse,
        GetApiV1CampaignGetspotsApiArg
      >({
        query: () => ({ url: `/api/v1/Campaign/getspots` }),
        providesTags: ["Campaign"],
      }),
      postApiV1CampaignExport: build.mutation<
        PostApiV1CampaignExportApiResponse,
        PostApiV1CampaignExportApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Campaign/export`,
          method: "POST",
          body: queryArg.exportOutputSettings,
          params: {
            medium: queryArg.medium,
          },
        }),
        invalidatesTags: ["Campaign"],
      }),
      postApiV1CampaignUpdate: build.mutation<
        PostApiV1CampaignUpdateApiResponse,
        PostApiV1CampaignUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Campaign/update`,
          method: "POST",
          body: queryArg.orderRequest,
        }),
        invalidatesTags: ["Campaign"],
      }),
      getApiV1CampaignAnalysisRelatedByMediumAndOrderId: build.query<
        GetApiV1CampaignAnalysisRelatedByMediumAndOrderIdApiResponse,
        GetApiV1CampaignAnalysisRelatedByMediumAndOrderIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/CampaignAnalysis/related/${queryArg.medium}/${queryArg.orderId}`,
        }),
        providesTags: ["CampaignAnalysis"],
      }),
      postApiV1CampaignAnalysisAnalysisGeneric: build.mutation<
        PostApiV1CampaignAnalysisAnalysisGenericApiResponse,
        PostApiV1CampaignAnalysisAnalysisGenericApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/CampaignAnalysis/analysis/generic`,
          method: "POST",
          body: queryArg.genericAnalysisInput,
          params: {
            medium: queryArg.medium,
          },
        }),
        invalidatesTags: ["CampaignAnalysis"],
      }),
      postApiV1CampaignAnalysisAnalysis: build.mutation<
        PostApiV1CampaignAnalysisAnalysisApiResponse,
        PostApiV1CampaignAnalysisAnalysisApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/CampaignAnalysis/analysis`,
          method: "POST",
          body: queryArg.analysisInput,
          params: {
            medium: queryArg.medium,
            orderId: queryArg.orderId,
          },
        }),
        invalidatesTags: ["CampaignAnalysis"],
      }),
      getApiV1CampaignAnalysisAnalysistargetgroups: build.query<
        GetApiV1CampaignAnalysisAnalysistargetgroupsApiResponse,
        GetApiV1CampaignAnalysisAnalysistargetgroupsApiArg
      >({
        query: () => ({ url: `/api/v1/CampaignAnalysis/analysistargetgroups` }),
        providesTags: ["CampaignAnalysis"],
      }),
      postApiV1CampaignAnalysisUploadFile: build.mutation<
        PostApiV1CampaignAnalysisUploadFileApiResponse,
        PostApiV1CampaignAnalysisUploadFileApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/CampaignAnalysis/uploadFile`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["CampaignAnalysis"],
      }),
      getApiV1CampaignAnalysisHasTvCampaignOnNmoSwitch: build.query<
        GetApiV1CampaignAnalysisHasTvCampaignOnNmoSwitchApiResponse,
        GetApiV1CampaignAnalysisHasTvCampaignOnNmoSwitchApiArg
      >({
        query: () => ({
          url: `/api/v1/CampaignAnalysis/hasTVCampaignOnNMOSwitch`,
        }),
        providesTags: ["CampaignAnalysis"],
      }),
      postApiV1CampaignForwardByInitialRequestId: build.mutation<
        PostApiV1CampaignForwardByInitialRequestIdApiResponse,
        PostApiV1CampaignForwardByInitialRequestIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/CampaignForward/${queryArg.initialRequestId}`,
          method: "POST",
          body: queryArg.campaignForwardInput,
          params: {
            medium: queryArg.medium,
            from: queryArg["from"],
            to: queryArg.to,
          },
        }),
        invalidatesTags: ["CampaignForward"],
      }),
      getApiV1CampaignForwardContacts: build.query<
        GetApiV1CampaignForwardContactsApiResponse,
        GetApiV1CampaignForwardContactsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/CampaignForward/contacts`,
          params: {
            organisationCode: queryArg.organisationCode,
          },
        }),
        providesTags: ["CampaignForward"],
      }),
      getApiV1CampaignForwardProductsByMedium: build.query<
        GetApiV1CampaignForwardProductsByMediumApiResponse,
        GetApiV1CampaignForwardProductsByMediumApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/CampaignForward/products/${queryArg.medium}`,
          params: {
            organisationCode: queryArg.organisationCode,
            dateFrom: queryArg.dateFrom,
            dateTo: queryArg.dateTo,
          },
        }),
        providesTags: ["CampaignForward"],
      }),
      getApiV1CampaignForwardAvailable: build.query<
        GetApiV1CampaignForwardAvailableApiResponse,
        GetApiV1CampaignForwardAvailableApiArg
      >({
        query: () => ({ url: `/api/v1/CampaignForward/available` }),
        providesTags: ["CampaignForward"],
      }),
      postApiV1CampaignForwardAccept: build.mutation<
        PostApiV1CampaignForwardAcceptApiResponse,
        PostApiV1CampaignForwardAcceptApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/CampaignForward/accept`,
          method: "POST",
          params: {
            id: queryArg.id,
          },
        }),
        invalidatesTags: ["CampaignForward"],
      }),
      postApiV1CampaignForwardReject: build.mutation<
        PostApiV1CampaignForwardRejectApiResponse,
        PostApiV1CampaignForwardRejectApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/CampaignForward/reject`,
          method: "POST",
          params: {
            id: queryArg.id,
          },
        }),
        invalidatesTags: ["CampaignForward"],
      }),
      getApiV1CampaignForwardDetailsById: build.query<
        GetApiV1CampaignForwardDetailsByIdApiResponse,
        GetApiV1CampaignForwardDetailsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/CampaignForward/details/${queryArg.id}`,
        }),
        providesTags: ["CampaignForward"],
      }),
      getApiV1CommercialInstructions: build.query<
        GetApiV1CommercialInstructionsApiResponse,
        GetApiV1CommercialInstructionsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/CommercialInstructions`,
          params: {
            medium: queryArg.medium,
            productId: queryArg.productId,
            from: queryArg["from"],
            to: queryArg.to,
            orderId: queryArg.orderId,
          },
        }),
        providesTags: ["CommercialInstructions"],
      }),
      postApiV1CommercialInstructions: build.mutation<
        PostApiV1CommercialInstructionsApiResponse,
        PostApiV1CommercialInstructionsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/CommercialInstructions`,
          method: "POST",
          body: queryArg.commercialInstructionRequest,
        }),
        invalidatesTags: ["CommercialInstructions"],
      }),
      getApiV1CommercialInstructionsOnlineByOrderId: build.query<
        GetApiV1CommercialInstructionsOnlineByOrderIdApiResponse,
        GetApiV1CommercialInstructionsOnlineByOrderIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/CommercialInstructions/online/${queryArg.orderId}`,
        }),
        providesTags: ["CommercialInstructions"],
      }),
      postApiV1CommercialInstructionsSaveOnlineByOrderId: build.mutation<
        PostApiV1CommercialInstructionsSaveOnlineByOrderIdApiResponse,
        PostApiV1CommercialInstructionsSaveOnlineByOrderIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/CommercialInstructions/saveOnline/${queryArg.orderId}`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["CommercialInstructions"],
      }),
      postApiV1CommercialInstructionsMulti: build.mutation<
        PostApiV1CommercialInstructionsMultiApiResponse,
        PostApiV1CommercialInstructionsMultiApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/CommercialInstructions/multi`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["CommercialInstructions"],
      }),
      deleteApiV1CommercialInstructionsDeleteByInstructionId: build.mutation<
        DeleteApiV1CommercialInstructionsDeleteByInstructionIdApiResponse,
        DeleteApiV1CommercialInstructionsDeleteByInstructionIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/CommercialInstructions/delete/${queryArg.instructionId}`,
          method: "DELETE",
          params: {
            medium: queryArg.medium,
          },
        }),
        invalidatesTags: ["CommercialInstructions"],
      }),
      getApiV1CommercialsByProductId: build.query<
        GetApiV1CommercialsByProductIdApiResponse,
        GetApiV1CommercialsByProductIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Commercials/${queryArg.productId}`,
          params: {
            medium: queryArg.medium,
            from: queryArg["from"],
            to: queryArg.to,
            includeThumbs: queryArg.includeThumbs,
          },
        }),
        providesTags: ["Commercials"],
      }),
      getApiV1Commercials: build.query<
        GetApiV1CommercialsApiResponse,
        GetApiV1CommercialsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Commercials`,
          params: {
            medium: queryArg.medium,
            from: queryArg["from"],
            to: queryArg.to,
            productId: queryArg.productId,
            includeThumbs: queryArg.includeThumbs,
          },
        }),
        providesTags: ["Commercials"],
      }),
      getApiV1CommercialsByMediaIdInit: build.query<
        GetApiV1CommercialsByMediaIdInitApiResponse,
        GetApiV1CommercialsByMediaIdInitApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Commercials/${queryArg.mediaId}/init`,
        }),
        providesTags: ["Commercials"],
      }),
      getApiV1CommercialsThumbnailsByCommercialCodeAndThumbnailIndex:
        build.query<
          GetApiV1CommercialsThumbnailsByCommercialCodeAndThumbnailIndexApiResponse,
          GetApiV1CommercialsThumbnailsByCommercialCodeAndThumbnailIndexApiArg
        >({
          query: (queryArg) => ({
            url: `/api/v1/Commercials/thumbnails/${queryArg.commercialCode}/${queryArg.thumbnailIndex}`,
          }),
          providesTags: ["Commercials"],
        }),
      getApiV1CommercialsByTypeAndCommercialCode: build.query<
        GetApiV1CommercialsByTypeAndCommercialCodeApiResponse,
        GetApiV1CommercialsByTypeAndCommercialCodeApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Commercials/${queryArg["type"]}/${queryArg.commercialCode}`,
        }),
        providesTags: ["Commercials"],
      }),
      postApiV1CommercialsUpload: build.mutation<
        PostApiV1CommercialsUploadApiResponse,
        PostApiV1CommercialsUploadApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Commercials/upload`,
          method: "POST",
          body: queryArg.body,
          params: {
            medium: queryArg.medium,
          },
        }),
        invalidatesTags: ["Commercials"],
      }),
      getApiV1CommercialsAudit: build.query<
        GetApiV1CommercialsAuditApiResponse,
        GetApiV1CommercialsAuditApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Commercials/audit`,
          params: {
            medium: queryArg.medium,
            showAll: queryArg.showAll,
          },
        }),
        providesTags: ["Commercials"],
      }),
      postApiV1CommercialsAudit: build.mutation<
        PostApiV1CommercialsAuditApiResponse,
        PostApiV1CommercialsAuditApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Commercials/audit`,
          method: "POST",
          body: queryArg.commercialUploadRegistrationReview,
          params: {
            medium: queryArg.medium,
          },
        }),
        invalidatesTags: ["Commercials"],
      }),
      postApiV1CommercialsReactivate: build.mutation<
        PostApiV1CommercialsReactivateApiResponse,
        PostApiV1CommercialsReactivateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Commercials/reactivate`,
          method: "POST",
          body: queryArg.commercialUploadRegistrationReactivate,
          params: {
            medium: queryArg.medium,
          },
        }),
        invalidatesTags: ["Commercials"],
      }),
      getApiV1CustomerAccounts: build.query<
        GetApiV1CustomerAccountsApiResponse,
        GetApiV1CustomerAccountsApiArg
      >({
        query: () => ({ url: `/api/v1/Customer/accounts` }),
        providesTags: ["Customer"],
      }),
      getApiV1CustomerContactsByAccountId: build.query<
        GetApiV1CustomerContactsByAccountIdApiResponse,
        GetApiV1CustomerContactsByAccountIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Customer/contacts/${queryArg.accountId}`,
        }),
        providesTags: ["Customer"],
      }),
      getApiV1CustomerContactsByAdvertiserId: build.query<
        GetApiV1CustomerContactsByAdvertiserIdApiResponse,
        GetApiV1CustomerContactsByAdvertiserIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Customer/contacts/${queryArg.advertiserId}`,
        }),
        providesTags: ["Customer"],
      }),
      getApiV1CustomerOpportunitiesByAccountId: build.query<
        GetApiV1CustomerOpportunitiesByAccountIdApiResponse,
        GetApiV1CustomerOpportunitiesByAccountIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Customer/opportunities/${queryArg.accountId}`,
        }),
        providesTags: ["Customer"],
      }),
      getApiV1CustomerOpportunitiesByAdvertiserId: build.query<
        GetApiV1CustomerOpportunitiesByAdvertiserIdApiResponse,
        GetApiV1CustomerOpportunitiesByAdvertiserIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Customer/opportunities/${queryArg.advertiserId}`,
        }),
        providesTags: ["Customer"],
      }),
      getApiV1Document: build.query<
        GetApiV1DocumentApiResponse,
        GetApiV1DocumentApiArg
      >({
        query: () => ({ url: `/api/v1/Document` }),
        providesTags: ["Document"],
      }),
      postApiV1Document: build.mutation<
        PostApiV1DocumentApiResponse,
        PostApiV1DocumentApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Document`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Document"],
      }),
      getApiV1DocumentByGuid: build.query<
        GetApiV1DocumentByGuidApiResponse,
        GetApiV1DocumentByGuidApiArg
      >({
        query: (queryArg) => ({ url: `/api/v1/Document/${queryArg.guid}` }),
        providesTags: ["Document"],
      }),
      postApiV1DocumentDeleteByGuid: build.mutation<
        PostApiV1DocumentDeleteByGuidApiResponse,
        PostApiV1DocumentDeleteByGuidApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Document/delete/${queryArg.guid}`,
          method: "POST",
        }),
        invalidatesTags: ["Document"],
      }),
      getByTypeAndIdExtension: build.query<
        GetByTypeAndIdExtensionApiResponse,
        GetByTypeAndIdExtensionApiArg
      >({
        query: (queryArg) => ({
          url: `/${queryArg["type"]}/${queryArg.id}.${queryArg.extension}`,
        }),
        providesTags: ["Download"],
      }),
      getByTypeAndIdSubIdExtension: build.query<
        GetByTypeAndIdSubIdExtensionApiResponse,
        GetByTypeAndIdSubIdExtensionApiArg
      >({
        query: (queryArg) => ({
          url: `/${queryArg["type"]}/${queryArg.id}/${queryArg.subId}.${queryArg.extension}`,
        }),
        providesTags: ["Download"],
      }),
      postApiV1ForecastRadioV2: build.mutation<
        PostApiV1ForecastRadioV2ApiResponse,
        PostApiV1ForecastRadioV2ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Forecast/radio/v2`,
          method: "POST",
          body: queryArg.forecastInput,
          params: {
            initialRequestId: queryArg.initialRequestId,
          },
        }),
        invalidatesTags: ["Forecast"],
      }),
      postApiV1ForecastRadio: build.mutation<
        PostApiV1ForecastRadioApiResponse,
        PostApiV1ForecastRadioApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Forecast/radio`,
          method: "POST",
          body: queryArg.forecastInput,
          params: {
            initialRequestId: queryArg.initialRequestId,
          },
        }),
        invalidatesTags: ["Forecast"],
      }),
      postApiV1ForecastTvV2: build.mutation<
        PostApiV1ForecastTvV2ApiResponse,
        PostApiV1ForecastTvV2ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Forecast/tv/v2`,
          method: "POST",
          body: queryArg.forecastInput,
          params: {
            initialRequestId: queryArg.initialRequestId,
          },
        }),
        invalidatesTags: ["Forecast"],
      }),
      postApiV1ForecastTv: build.mutation<
        PostApiV1ForecastTvApiResponse,
        PostApiV1ForecastTvApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Forecast/tv`,
          method: "POST",
          body: queryArg.forecastInput,
          params: {
            initialRequestId: queryArg.initialRequestId,
          },
        }),
        invalidatesTags: ["Forecast"],
      }),
      getApiV1ForecastConversiongroups: build.query<
        GetApiV1ForecastConversiongroupsApiResponse,
        GetApiV1ForecastConversiongroupsApiArg
      >({
        query: () => ({ url: `/api/v1/Forecast/conversiongroups` }),
        providesTags: ["Forecast"],
      }),
      getApiV1GenericSecondaryTargetGroups: build.query<
        GetApiV1GenericSecondaryTargetGroupsApiResponse,
        GetApiV1GenericSecondaryTargetGroupsApiArg
      >({
        query: () => ({ url: `/api/v1/Generic/secondaryTargetGroups` }),
        providesTags: ["Generic"],
      }),
      getApiV1GenericGetsalesperiod: build.query<
        GetApiV1GenericGetsalesperiodApiResponse,
        GetApiV1GenericGetsalesperiodApiArg
      >({
        query: () => ({ url: `/api/v1/Generic/getsalesperiod` }),
        providesTags: ["Generic"],
      }),
      getApiV1GenericGetproducts: build.query<
        GetApiV1GenericGetproductsApiResponse,
        GetApiV1GenericGetproductsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Generic/getproducts`,
          params: {
            medium: queryArg.medium,
            from: queryArg["from"],
            to: queryArg.to,
            filter: queryArg.filter,
          },
        }),
        providesTags: ["Generic"],
      }),
      getApiV1GenericDigitalContext: build.query<
        GetApiV1GenericDigitalContextApiResponse,
        GetApiV1GenericDigitalContextApiArg
      >({
        query: () => ({ url: `/api/v1/Generic/digital/context` }),
        providesTags: ["Generic"],
      }),
      getApiV1GenericDigitalTargetgroups: build.query<
        GetApiV1GenericDigitalTargetgroupsApiResponse,
        GetApiV1GenericDigitalTargetgroupsApiArg
      >({
        query: () => ({ url: `/api/v1/Generic/digital/targetgroups` }),
        providesTags: ["Generic"],
      }),
      getApiV1Invoice: build.query<
        GetApiV1InvoiceApiResponse,
        GetApiV1InvoiceApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Invoice`,
          params: {
            from: queryArg["from"],
            to: queryArg.to,
          },
        }),
        providesTags: ["Invoice"],
      }),
      getApiV1PlanningByInitialRequestId: build.query<
        GetApiV1PlanningByInitialRequestIdApiResponse,
        GetApiV1PlanningByInitialRequestIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Planning/${queryArg.initialRequestId}`,
          params: {
            medium: queryArg.medium,
            from: queryArg["from"],
            to: queryArg.to,
          },
        }),
        providesTags: ["Planning"],
      }),
      postApiV1PlanningSave: build.mutation<
        PostApiV1PlanningSaveApiResponse,
        PostApiV1PlanningSaveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Planning/save`,
          method: "POST",
          body: queryArg.orderRequest,
        }),
        invalidatesTags: ["Planning"],
      }),
      getApiV1PlanningBookingdate: build.query<
        GetApiV1PlanningBookingdateApiResponse,
        GetApiV1PlanningBookingdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Planning/bookingdate`,
          params: {
            medium: queryArg.medium,
          },
        }),
        providesTags: ["Planning"],
      }),
      postApiV1PlanningFinalize: build.mutation<
        PostApiV1PlanningFinalizeApiResponse,
        PostApiV1PlanningFinalizeApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Planning/finalize`,
          method: "POST",
          body: queryArg.orderRequest,
        }),
        invalidatesTags: ["Planning"],
      }),
      postApiV1PlanningDeleteByInitialRequestId: build.mutation<
        PostApiV1PlanningDeleteByInitialRequestIdApiResponse,
        PostApiV1PlanningDeleteByInitialRequestIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Planning/delete/${queryArg.initialRequestId}`,
          method: "POST",
          params: {
            medium: queryArg.medium,
            dateFrom: queryArg.dateFrom,
            dateUntil: queryArg.dateUntil,
          },
        }),
        invalidatesTags: ["Planning"],
      }),
      postApiV1PlanningValidate: build.mutation<
        PostApiV1PlanningValidateApiResponse,
        PostApiV1PlanningValidateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Planning/validate`,
          method: "POST",
          body: queryArg.orderRequest,
        }),
        invalidatesTags: ["Planning"],
      }),
      postApiV1PlanningCopy: build.mutation<
        PostApiV1PlanningCopyApiResponse,
        PostApiV1PlanningCopyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Planning/copy`,
          method: "POST",
          body: queryArg.copyOrderInput,
          params: {
            medium: queryArg.medium,
          },
        }),
        invalidatesTags: ["Planning"],
      }),
      getApiV1ProductTour: build.query<
        GetApiV1ProductTourApiResponse,
        GetApiV1ProductTourApiArg
      >({
        query: () => ({ url: `/api/v1/productTour` }),
        providesTags: ["ProductTour"],
      }),
      postApiV1ProductTour: build.mutation<
        PostApiV1ProductTourApiResponse,
        PostApiV1ProductTourApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/productTour`,
          method: "POST",
          body: queryArg.productTourModel,
        }),
        invalidatesTags: ["ProductTour"],
      }),
      postApiV1Proposal: build.mutation<
        PostApiV1ProposalApiResponse,
        PostApiV1ProposalApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Proposal`,
          method: "POST",
          body: queryArg.proposalRequest,
        }),
        invalidatesTags: ["Proposal"],
      }),
      postApiV1ProposalGenerateProposal: build.mutation<
        PostApiV1ProposalGenerateProposalApiResponse,
        PostApiV1ProposalGenerateProposalApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Proposal/generateProposal`,
          method: "POST",
          body: queryArg.proposalOrderRequest,
        }),
        invalidatesTags: ["Proposal"],
      }),
      postApiV1ProposalGetProposalData: build.mutation<
        PostApiV1ProposalGetProposalDataApiResponse,
        PostApiV1ProposalGetProposalDataApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Proposal/getProposalData`,
          method: "POST",
          body: queryArg.proposalRequest,
        }),
        invalidatesTags: ["Proposal"],
      }),
      postApiV1ProposalUploadForecast: build.mutation<
        PostApiV1ProposalUploadForecastApiResponse,
        PostApiV1ProposalUploadForecastApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Proposal/uploadForecast`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Proposal"],
      }),
      getApiV1ProposalTestByYear: build.query<
        GetApiV1ProposalTestByYearApiResponse,
        GetApiV1ProposalTestByYearApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Proposal/test/${queryArg.year}`,
        }),
        providesTags: ["Proposal"],
      }),
      getApiV1RatecardSpotindexes: build.query<
        GetApiV1RatecardSpotindexesApiResponse,
        GetApiV1RatecardSpotindexesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Ratecard/spotindexes`,
          params: {
            medium: queryArg.medium,
            year: queryArg.year,
          },
        }),
        providesTags: ["Ratecard"],
      }),
      getApiV1RequestGetpackages: build.query<
        GetApiV1RequestGetpackagesApiResponse,
        GetApiV1RequestGetpackagesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Request/getpackages`,
          params: {
            medium: queryArg.medium,
            product: queryArg.product,
            from: queryArg["from"],
            to: queryArg.to,
            orderRequestDate: queryArg.orderRequestDate,
          },
        }),
        providesTags: ["Request"],
      }),
      getApiV1RequestGetpackagesGroupedRadioByYear: build.query<
        GetApiV1RequestGetpackagesGroupedRadioByYearApiResponse,
        GetApiV1RequestGetpackagesGroupedRadioByYearApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Request/getpackages/grouped/radio/${queryArg.year}`,
        }),
        providesTags: ["Request"],
      }),
      getApiV1RequestGetpackagesGroupedTvByYear: build.query<
        GetApiV1RequestGetpackagesGroupedTvByYearApiResponse,
        GetApiV1RequestGetpackagesGroupedTvByYearApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Request/getpackages/grouped/tv/${queryArg.year}`,
        }),
        providesTags: ["Request"],
      }),
      postApiV1RequestGetavailablespots: build.mutation<
        PostApiV1RequestGetavailablespotsApiResponse,
        PostApiV1RequestGetavailablespotsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Request/getavailablespots`,
          method: "POST",
          body: queryArg.availableBreaksRequest,
        }),
        invalidatesTags: ["Request"],
      }),
      postApiV1RequestCalculate: build.mutation<
        PostApiV1RequestCalculateApiResponse,
        PostApiV1RequestCalculateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Request/calculate`,
          method: "POST",
          body: queryArg.calculationRequest,
          params: {
            medium: queryArg.medium,
          },
        }),
        invalidatesTags: ["Request"],
      }),
      getApiV1RequestDigitalPrograms: build.query<
        GetApiV1RequestDigitalProgramsApiResponse,
        GetApiV1RequestDigitalProgramsApiArg
      >({
        query: () => ({ url: `/api/v1/Request/digital/programs` }),
        providesTags: ["Request"],
      }),
      getApiV1RequestDigitalSites: build.query<
        GetApiV1RequestDigitalSitesApiResponse,
        GetApiV1RequestDigitalSitesApiArg
      >({
        query: () => ({ url: `/api/v1/Request/digital/sites` }),
        providesTags: ["Request"],
      }),
      getApiV1Settings: build.query<
        GetApiV1SettingsApiResponse,
        GetApiV1SettingsApiArg
      >({
        query: () => ({ url: `/api/v1/Settings` }),
        providesTags: ["Settings"],
      }),
      putApiV1Settings: build.mutation<
        PutApiV1SettingsApiResponse,
        PutApiV1SettingsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/Settings`,
          method: "PUT",
          body: queryArg.editableSetting,
        }),
        invalidatesTags: ["Settings"],
      }),
      getApiV1SettingsEditable: build.query<
        GetApiV1SettingsEditableApiResponse,
        GetApiV1SettingsEditableApiArg
      >({
        query: () => ({ url: `/api/v1/Settings/editable` }),
        providesTags: ["Settings"],
      }),
      postApiV1SpotTracker: build.mutation<
        PostApiV1SpotTrackerApiResponse,
        PostApiV1SpotTrackerApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/SpotTracker`,
          method: "POST",
          body: queryArg.spotTrackerRequestInput,
          params: {
            medium: queryArg.medium,
          },
        }),
        invalidatesTags: ["SpotTracker"],
      }),
      postApiV1SpotTrackerExport: build.mutation<
        PostApiV1SpotTrackerExportApiResponse,
        PostApiV1SpotTrackerExportApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/SpotTracker/export`,
          method: "POST",
          body: queryArg.spotTrackerRequestInput,
          params: {
            medium: queryArg.medium,
            selectedColumns: queryArg.selectedColumns,
          },
        }),
        invalidatesTags: ["SpotTracker"],
      }),
      getApiV1Terms: build.query<GetApiV1TermsApiResponse, GetApiV1TermsApiArg>(
        {
          query: () => ({ url: `/api/v1/terms` }),
          providesTags: ["TermsAndConditions"],
        },
      ),
      postApiV1Terms: build.mutation<
        PostApiV1TermsApiResponse,
        PostApiV1TermsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/terms`,
          method: "POST",
          body: queryArg.termsAndConditionsModel,
        }),
        invalidatesTags: ["TermsAndConditions"],
      }),
      getApiV1Tips: build.query<GetApiV1TipsApiResponse, GetApiV1TipsApiArg>({
        query: () => ({ url: `/api/v1/Tips` }),
        providesTags: ["Tips"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as coreApi };
export type GetApiV1AccountMeApiResponse =
  /** status 200 Success */ PortalUserRead;
export type GetApiV1AccountMeApiArg = void;
export type GetApiV1AccountOrganisationsApiResponse =
  /** status 200 Success */ GetOrganisationCodesResponse;
export type GetApiV1AccountOrganisationsApiArg = void;
export type GetApiV1AccountAdvertisersApiResponse =
  /** status 200 Success */ GetAdvertisersResponse;
export type GetApiV1AccountAdvertisersApiArg = void;
export type PostApiV1AccountDeleteByUserIdApiResponse =
  /** status 200 Success */ ResultDeleted;
export type PostApiV1AccountDeleteByUserIdApiArg = {
  userId: string;
};
export type DeleteApiV1AccountDeleteByUserIdApiResponse =
  /** status 200 Success */ IdentityResultRead;
export type DeleteApiV1AccountDeleteByUserIdApiArg = {
  userId: string;
};
export type DeleteApiV1AccountDeactivateApiResponse =
  /** status 200 Success */ ResultDeleted;
export type DeleteApiV1AccountDeactivateApiArg = {
  deactivateUserInput: DeactivateUserInput;
};
export type PostApiV1AccountActivateByUserIdApiResponse =
  /** status 200 Success */ ResultActivated;
export type PostApiV1AccountActivateByUserIdApiArg = {
  userId: string;
};
export type PostApiV1AccountUpdateByUserIdApiResponse =
  /** status 200 Success */ PortalUserRead;
export type PostApiV1AccountUpdateByUserIdApiArg = {
  userId: string;
  userModel: UserModel;
};
export type PostApiV1AccountRegisterApiResponse =
  /** status 200 Success */ PortalUserRead;
export type PostApiV1AccountRegisterApiArg = {
  userModel: UserModel;
};
export type GetApiV1AccountAllApiResponse =
  /** status 200 Success */ PortalUserRead[];
export type GetApiV1AccountAllApiArg = void;
export type GetApiV1AccountByUserIdApiResponse =
  /** status 200 Success */ PortalUserRead;
export type GetApiV1AccountByUserIdApiArg = {
  userId: string;
};
export type GetApiV1AccountExportApiResponse = /** status 200 Success */ Blob;
export type GetApiV1AccountExportApiArg = void;
export type GetApiV1AnnouncementsApiResponse =
  /** status 200 Success */ AnnouncementDto[];
export type GetApiV1AnnouncementsApiArg = void;
export type PostApiV1AnnouncementsApiResponse = /** status 200 Success */
  | AnnouncementDto
  | /** status 201 Created */ AnnouncementDto;
export type PostApiV1AnnouncementsApiArg = {
  announcementDto: AnnouncementDto;
};
export type GetApiV1AnnouncementsAllApiResponse =
  /** status 200 Success */ AnnouncementDto[];
export type GetApiV1AnnouncementsAllApiArg = void;
export type DeleteApiV1AnnouncementsByIdApiResponse = unknown;
export type DeleteApiV1AnnouncementsByIdApiArg = {
  id: number;
};
export type PostApiV1AssetManagementWebHookUpdateApiResponse = unknown;
export type PostApiV1AssetManagementWebHookUpdateApiArg = {
  assetManagementHook: AssetManagementHook;
};
export type PostApiV1BookSpotApiResponse = unknown;
export type PostApiV1BookSpotApiArg = {
  bookSpotCollection: BookSpotCollection;
};
export type GetApiV1CacheListApiResponse = /** status 200 Success */ any[];
export type GetApiV1CacheListApiArg = void;
export type GetApiV1CacheClearApiResponse = unknown;
export type GetApiV1CacheClearApiArg = void;
export type GetApiV1CampaignCodeByCampaignCodeApiResponse =
  /** status 200 Success */ CampaignConfigurationRead;
export type GetApiV1CampaignCodeByCampaignCodeApiArg = {
  campaignCode: string;
};
export type PostApiV1CampaignCodeApiResponse =
  /** status 200 Success */ CampaignImportOutput;
export type PostApiV1CampaignCodeApiArg = {
  campaignConfigurationInput: CampaignConfigurationInput;
};
export type GetApiV1CampaignEndreportByMediumAndPackageChoiceOrderIdFileTypeApiResponse =
  /** status 200 Success */ string;
export type GetApiV1CampaignEndreportByMediumAndPackageChoiceOrderIdFileTypeApiArg =
  {
    medium: MediumEnum;
    packageChoice: OnlinePackageChoice;
    orderId: number;
    fileType: string;
    subOrderId?: number;
  };
export type GetApiV1CampaignGetordersApiResponse =
  /** status 200 Success */ OrderListItemRead[];
export type GetApiV1CampaignGetordersApiArg = {
  from?: string;
  to?: string;
};
export type GetApiV1CampaignGetorderApiResponse =
  /** status 200 Success */ OrderDetailRead;
export type GetApiV1CampaignGetorderApiArg = {
  medium?: MediumEnum;
  orderId?: number;
};
export type GetApiV1CampaignGetorderwithspotsApiResponse =
  /** status 200 Success */ OrderDetailRead;
export type GetApiV1CampaignGetorderwithspotsApiArg = {
  medium?: MediumEnum;
  orderId?: number;
};
export type GetApiV1CampaignGeteditablecampaignApiResponse =
  /** status 200 Success */ OrderRequestRead;
export type GetApiV1CampaignGeteditablecampaignApiArg = {
  medium?: MediumEnum;
  orderId: number;
};
export type GetApiV1CampaignGetspotsbyorderApiResponse =
  /** status 200 Success */ SpotsResultRead;
export type GetApiV1CampaignGetspotsbyorderApiArg = {
  medium?: MediumEnum;
  orderId?: number;
  secondaryTargetGroupId?: string;
};
export type GetApiV1CampaignGetspotsApiResponse =
  /** status 200 Success */ SpotsByMedium[];
export type GetApiV1CampaignGetspotsApiArg = void;
export type PostApiV1CampaignExportApiResponse = /** status 200 Success */ Blob;
export type PostApiV1CampaignExportApiArg = {
  medium: MediumEnum;
  exportOutputSettings: ExportOutputSettings;
};
export type PostApiV1CampaignUpdateApiResponse = unknown;
export type PostApiV1CampaignUpdateApiArg = {
  orderRequest: OrderRequest;
};
export type GetApiV1CampaignAnalysisRelatedByMediumAndOrderIdApiResponse =
  /** status 200 Success */ OrderListItemRead[];
export type GetApiV1CampaignAnalysisRelatedByMediumAndOrderIdApiArg = {
  medium: MediumEnum;
  orderId: number;
};
export type PostApiV1CampaignAnalysisAnalysisGenericApiResponse = unknown;
export type PostApiV1CampaignAnalysisAnalysisGenericApiArg = {
  medium: MediumEnum;
  genericAnalysisInput: GenericAnalysisInput;
};
export type PostApiV1CampaignAnalysisAnalysisApiResponse = unknown;
export type PostApiV1CampaignAnalysisAnalysisApiArg = {
  medium: MediumEnum;
  orderId: number;
  analysisInput: AnalysisInput;
};
export type GetApiV1CampaignAnalysisAnalysistargetgroupsApiResponse =
  /** status 200 Success */ AnalysisTargetGroups;
export type GetApiV1CampaignAnalysisAnalysistargetgroupsApiArg = void;
export type PostApiV1CampaignAnalysisUploadFileApiResponse =
  /** status 200 Success */ Blob;
export type PostApiV1CampaignAnalysisUploadFileApiArg = {
  body: {
    Files: Blob[];
    Medium: string;
    Advertiser?: string;
    Year: number;
  };
};
export type GetApiV1CampaignAnalysisHasTvCampaignOnNmoSwitchApiResponse =
  /** status 200 Success */ boolean;
export type GetApiV1CampaignAnalysisHasTvCampaignOnNmoSwitchApiArg = void;
export type PostApiV1CampaignForwardByInitialRequestIdApiResponse =
  /** status 200 Success */ CampaignForwardedRead;
export type PostApiV1CampaignForwardByInitialRequestIdApiArg = {
  medium: MediumEnum;
  initialRequestId: number;
  from: string;
  to: string;
  campaignForwardInput: CampaignForwardInput;
};
export type GetApiV1CampaignForwardContactsApiResponse =
  /** status 200 Success */ SimplePortalUserRead[];
export type GetApiV1CampaignForwardContactsApiArg = {
  organisationCode: string;
};
export type GetApiV1CampaignForwardProductsByMediumApiResponse =
  /** status 200 Success */ Advertiser[];
export type GetApiV1CampaignForwardProductsByMediumApiArg = {
  medium: MediumEnum;
  organisationCode: string;
  dateFrom: string;
  dateTo: string;
};
export type GetApiV1CampaignForwardAvailableApiResponse =
  /** status 200 Success */ CampaignForwardedRead[];
export type GetApiV1CampaignForwardAvailableApiArg = void;
export type PostApiV1CampaignForwardAcceptApiResponse =
  /** status 200 Success */ CampaignImportOutput;
export type PostApiV1CampaignForwardAcceptApiArg = {
  id?: number;
};
export type PostApiV1CampaignForwardRejectApiResponse = unknown;
export type PostApiV1CampaignForwardRejectApiArg = {
  id?: number;
};
export type GetApiV1CampaignForwardDetailsByIdApiResponse =
  /** status 200 Success */ CampaignForwardedRead;
export type GetApiV1CampaignForwardDetailsByIdApiArg = {
  id: number;
};
export type GetApiV1CommercialInstructionsApiResponse =
  /** status 200 Success */ CommercialInstructionRequestRead[];
export type GetApiV1CommercialInstructionsApiArg = {
  medium?: MediumEnum;
  productId?: number;
  from?: string;
  to?: string;
  orderId?: number;
};
export type PostApiV1CommercialInstructionsApiResponse =
  /** status 200 Success */
    | CommercialInstructionRequestRead
    | /** status 201 Created */ CommercialInstructionRequestRead;
export type PostApiV1CommercialInstructionsApiArg = {
  commercialInstructionRequest: CommercialInstructionRequest;
};
export type GetApiV1CommercialInstructionsOnlineByOrderIdApiResponse =
  /** status 200 Success */ OnlineInstructionPerSubOrderRead[];
export type GetApiV1CommercialInstructionsOnlineByOrderIdApiArg = {
  orderId: number;
};
export type PostApiV1CommercialInstructionsSaveOnlineByOrderIdApiResponse =
  /** status 201 Created */ boolean;
export type PostApiV1CommercialInstructionsSaveOnlineByOrderIdApiArg = {
  orderId: number;
  body: OnlineInstructionPerSubOrder[];
};
export type PostApiV1CommercialInstructionsMultiApiResponse = unknown;
export type PostApiV1CommercialInstructionsMultiApiArg = {
  body: CommercialInstructionRequest[];
};
export type DeleteApiV1CommercialInstructionsDeleteByInstructionIdApiResponse =
  unknown;
export type DeleteApiV1CommercialInstructionsDeleteByInstructionIdApiArg = {
  medium: MediumEnum;
  instructionId: number;
};
export type GetApiV1CommercialsByProductIdApiResponse =
  /** status 200 Success */ CommercialsPerAdvertiserRead;
export type GetApiV1CommercialsByProductIdApiArg = {
  medium?: MediumEnum;
  productId: number;
  from?: string;
  to?: string;
  includeThumbs?: boolean;
};
export type GetApiV1CommercialsApiResponse =
  /** status 200 Success */ CommercialsPerAdvertiserRead[];
export type GetApiV1CommercialsApiArg = {
  medium?: MediumEnum;
  from?: string;
  to?: string;
  productId?: number;
  includeThumbs?: boolean;
};
export type GetApiV1CommercialsByMediaIdInitApiResponse =
  /** status 200 Success */ NpoPlayerResult;
export type GetApiV1CommercialsByMediaIdInitApiArg = {
  mediaId: string;
};
export type GetApiV1CommercialsThumbnailsByCommercialCodeAndThumbnailIndexApiResponse =
  /** status 200 Success */ Blob;
export type GetApiV1CommercialsThumbnailsByCommercialCodeAndThumbnailIndexApiArg =
  {
    commercialCode: string;
    thumbnailIndex: string;
  };
export type GetApiV1CommercialsByTypeAndCommercialCodeApiResponse =
  /** status 200 Success */ Blob;
export type GetApiV1CommercialsByTypeAndCommercialCodeApiArg = {
  commercialCode: string;
  type: MediaFileType;
};
export type PostApiV1CommercialsUploadApiResponse =
  /** status 200 Success */ CommercialUploadResult;
export type PostApiV1CommercialsUploadApiArg = {
  medium?: MediumEnum;
  body: {
    AdvertiserId: number;
    AdvertiserName: string;
    CommercialTitle: string;
    UploadType: string;
    Files: Blob[];
    ClickUrl: string;
  };
};
export type GetApiV1CommercialsAuditApiResponse =
  /** status 200 Success */ CommercialUploadRegistration[];
export type GetApiV1CommercialsAuditApiArg = {
  medium?: MediumEnum;
  showAll?: boolean;
};
export type PostApiV1CommercialsAuditApiResponse = unknown;
export type PostApiV1CommercialsAuditApiArg = {
  medium?: MediumEnum;
  commercialUploadRegistrationReview: CommercialUploadRegistrationReview;
};
export type PostApiV1CommercialsReactivateApiResponse = unknown;
export type PostApiV1CommercialsReactivateApiArg = {
  medium?: MediumEnum;
  commercialUploadRegistrationReactivate: CommercialUploadRegistrationReactivate;
};
export type GetApiV1CustomerAccountsApiResponse =
  /** status 200 Success */ CustomerAccount[];
export type GetApiV1CustomerAccountsApiArg = void;
export type GetApiV1CustomerContactsByAccountIdApiResponse =
  /** status 200 Success */ CustomerContactRead[];
export type GetApiV1CustomerContactsByAccountIdApiArg = {
  accountId: string;
};
export type GetApiV1CustomerContactsByAdvertiserIdApiResponse =
  /** status 200 Success */ CustomerContactRead[];
export type GetApiV1CustomerContactsByAdvertiserIdApiArg = {
  advertiserId: number;
};
export type GetApiV1CustomerOpportunitiesByAccountIdApiResponse =
  /** status 200 Success */ CustomerOpportunity[];
export type GetApiV1CustomerOpportunitiesByAccountIdApiArg = {
  accountId: string;
};
export type GetApiV1CustomerOpportunitiesByAdvertiserIdApiResponse =
  /** status 200 Success */ CustomerOpportunity[];
export type GetApiV1CustomerOpportunitiesByAdvertiserIdApiArg = {
  advertiserId: number;
};
export type GetApiV1DocumentApiResponse =
  /** status 200 Success */ DocumentRead[];
export type GetApiV1DocumentApiArg = void;
export type PostApiV1DocumentApiResponse =
  /** status 200 Success */ DocumentResultSuccess;
export type PostApiV1DocumentApiArg = {
  body: {
    File: Blob;
    PublicationStart?: string;
    PublicationEnd?: string;
    OrganisationCode: string;
    UserName: string;
    AdvertiserCode?: number;
  };
};
export type GetApiV1DocumentByGuidApiResponse = unknown;
export type GetApiV1DocumentByGuidApiArg = {
  guid: string;
};
export type PostApiV1DocumentDeleteByGuidApiResponse =
  /** status 200 Success */ DocumentResultDeleted;
export type PostApiV1DocumentDeleteByGuidApiArg = {
  guid: string;
};
export type GetByTypeAndIdExtensionApiResponse = /** status 200 Success */ Blob;
export type GetByTypeAndIdExtensionApiArg = {
  type: string;
  id: number;
  extension: string;
};
export type GetByTypeAndIdSubIdExtensionApiResponse =
  /** status 200 Success */ Blob;
export type GetByTypeAndIdSubIdExtensionApiArg = {
  type: string;
  id: number;
  subId: number;
  extension: string;
};
export type PostApiV1ForecastRadioV2ApiResponse =
  /** status 200 Success */ ForecastResultRead;
export type PostApiV1ForecastRadioV2ApiArg = {
  initialRequestId?: number;
  forecastInput: ForecastInput;
};
export type PostApiV1ForecastRadioApiResponse =
  /** status 200 Success */ CampaignCalculatorForecastResult;
export type PostApiV1ForecastRadioApiArg = {
  initialRequestId?: number;
  forecastInput: ForecastInput;
};
export type PostApiV1ForecastTvV2ApiResponse =
  /** status 200 Success */ ForecastResultRead;
export type PostApiV1ForecastTvV2ApiArg = {
  initialRequestId?: number;
  forecastInput: ForecastInput;
};
export type PostApiV1ForecastTvApiResponse =
  /** status 200 Success */ CampaignCalculatorForecastResult;
export type PostApiV1ForecastTvApiArg = {
  initialRequestId?: number;
  forecastInput: ForecastInput;
};
export type GetApiV1ForecastConversiongroupsApiResponse =
  /** status 200 Success */ ForecastConversionGroupsRead;
export type GetApiV1ForecastConversiongroupsApiArg = void;
export type GetApiV1GenericSecondaryTargetGroupsApiResponse =
  /** status 200 Success */ {
    tv?: SecondaryTargetGroup[];
    radio?: SecondaryTargetGroup[];
    inter?: SecondaryTargetGroup[];
  };
export type GetApiV1GenericSecondaryTargetGroupsApiArg = void;
export type GetApiV1GenericGetsalesperiodApiResponse =
  /** status 200 Success */ SalesPeriodResult[];
export type GetApiV1GenericGetsalesperiodApiArg = void;
export type GetApiV1GenericGetproductsApiResponse =
  /** status 200 Success */ Advertiser[];
export type GetApiV1GenericGetproductsApiArg = {
  medium?: MediumEnum;
  from?: string;
  to?: string;
  filter?: string;
};
export type GetApiV1GenericDigitalContextApiResponse =
  /** status 200 Success */ ValueListRead[];
export type GetApiV1GenericDigitalContextApiArg = void;
export type GetApiV1GenericDigitalTargetgroupsApiResponse =
  /** status 200 Success */ ValueListRead[];
export type GetApiV1GenericDigitalTargetgroupsApiArg = void;
export type GetApiV1InvoiceApiResponse = /** status 200 Success */ Invoice[];
export type GetApiV1InvoiceApiArg = {
  from?: string;
  to?: string;
};
export type GetApiV1PlanningByInitialRequestIdApiResponse =
  /** status 200 Success */ OrderRequestRead;
export type GetApiV1PlanningByInitialRequestIdApiArg = {
  medium?: MediumEnum;
  initialRequestId: number;
  from?: string;
  to?: string;
};
export type PostApiV1PlanningSaveApiResponse =
  /** status 200 Success */ OrderRequestRead;
export type PostApiV1PlanningSaveApiArg = {
  orderRequest: OrderRequest;
};
export type GetApiV1PlanningBookingdateApiResponse =
  /** status 200 Success */ BookingDate;
export type GetApiV1PlanningBookingdateApiArg = {
  medium?: MediumEnum;
};
export type PostApiV1PlanningFinalizeApiResponse =
  /** status 200 Success */ OrderRequestRead;
export type PostApiV1PlanningFinalizeApiArg = {
  orderRequest: OrderRequest;
};
export type PostApiV1PlanningDeleteByInitialRequestIdApiResponse = unknown;
export type PostApiV1PlanningDeleteByInitialRequestIdApiArg = {
  medium?: MediumEnum;
  initialRequestId: number;
  dateFrom?: string;
  dateUntil?: string;
};
export type PostApiV1PlanningValidateApiResponse =
  /** status 200 Success */ boolean;
export type PostApiV1PlanningValidateApiArg = {
  orderRequest: OrderRequest;
};
export type PostApiV1PlanningCopyApiResponse =
  /** status 200 Success */ OrderRequestRead;
export type PostApiV1PlanningCopyApiArg = {
  medium?: MediumEnum;
  copyOrderInput: CopyOrderInput;
};
export type GetApiV1ProductTourApiResponse =
  /** status 200 Success */ ProductTourResult;
export type GetApiV1ProductTourApiArg = void;
export type PostApiV1ProductTourApiResponse =
  /** status 200 Success */ ProductTourResult;
export type PostApiV1ProductTourApiArg = {
  productTourModel: ProductTourModel;
};
export type PostApiV1ProposalApiResponse = unknown;
export type PostApiV1ProposalApiArg = {
  proposalRequest: ProposalRequest;
};
export type PostApiV1ProposalGenerateProposalApiResponse = unknown;
export type PostApiV1ProposalGenerateProposalApiArg = {
  proposalOrderRequest: ProposalOrderRequest;
};
export type PostApiV1ProposalGetProposalDataApiResponse =
  /** status 200 Success */ ProposalDataRead;
export type PostApiV1ProposalGetProposalDataApiArg = {
  proposalRequest: ProposalRequest;
};
export type PostApiV1ProposalUploadForecastApiResponse =
  /** status 200 Success */ Blob;
export type PostApiV1ProposalUploadForecastApiArg = {
  body: {
    Files: Blob[];
    Medium: string;
    Advertiser?: string;
    Year: number;
  };
};
export type GetApiV1ProposalTestByYearApiResponse =
  /** status 200 Success */ Blob;
export type GetApiV1ProposalTestByYearApiArg = {
  year: number;
};
export type GetApiV1RatecardSpotindexesApiResponse =
  /** status 200 Success */ SpotIndexesResult;
export type GetApiV1RatecardSpotindexesApiArg = {
  medium?: MediumEnum;
  year?: number;
};
export type GetApiV1RequestGetpackagesApiResponse =
  /** status 200 Success */ PackageResultRead[];
export type GetApiV1RequestGetpackagesApiArg = {
  medium?: MediumEnum;
  product?: string;
  from?: string;
  to?: string;
  orderRequestDate?: string;
};
export type GetApiV1RequestGetpackagesGroupedRadioByYearApiResponse =
  /** status 200 Success */ GroupedPackageRadio[];
export type GetApiV1RequestGetpackagesGroupedRadioByYearApiArg = {
  year: number;
};
export type GetApiV1RequestGetpackagesGroupedTvByYearApiResponse =
  /** status 200 Success */ GroupedPackageTv[];
export type GetApiV1RequestGetpackagesGroupedTvByYearApiArg = {
  year: number;
};
export type PostApiV1RequestGetavailablespotsApiResponse =
  /** status 200 Success */ AvailableBreakRead[];
export type PostApiV1RequestGetavailablespotsApiArg = {
  availableBreaksRequest: AvailableBreaksRequest;
};
export type PostApiV1RequestCalculateApiResponse =
  /** status 200 Success */ CalculationResult;
export type PostApiV1RequestCalculateApiArg = {
  medium?: MediumEnum;
  calculationRequest: CalculationRequest;
};
export type GetApiV1RequestDigitalProgramsApiResponse =
  /** status 200 Success */ DigitalProgramIListValueResult;
export type GetApiV1RequestDigitalProgramsApiArg = void;
export type GetApiV1RequestDigitalSitesApiResponse =
  /** status 200 Success */ ValueListIEnumerableValueResultRead;
export type GetApiV1RequestDigitalSitesApiArg = void;
export type GetApiV1SettingsApiResponse =
  /** status 200 Success */ SettingsGetResponse;
export type GetApiV1SettingsApiArg = void;
export type PutApiV1SettingsApiResponse =
  /** status 200 Success */ EditableSetting;
export type PutApiV1SettingsApiArg = {
  editableSetting: EditableSetting;
};
export type GetApiV1SettingsEditableApiResponse =
  /** status 200 Success */ EditableSetting[];
export type GetApiV1SettingsEditableApiArg = void;
export type PostApiV1SpotTrackerApiResponse =
  /** status 204 No Content */ SpotResultOutput[];
export type PostApiV1SpotTrackerApiArg = {
  medium: MediumEnum;
  spotTrackerRequestInput: SpotTrackerRequestInput;
};
export type PostApiV1SpotTrackerExportApiResponse =
  /** status 200 Success */ Blob;
export type PostApiV1SpotTrackerExportApiArg = {
  medium: MediumEnum;
  selectedColumns: string[];
  spotTrackerRequestInput: SpotTrackerRequestInput;
};
export type GetApiV1TermsApiResponse =
  /** status 200 Success */ TermsAndConditionsResult;
export type GetApiV1TermsApiArg = void;
export type PostApiV1TermsApiResponse =
  /** status 200 Success */ TermsAndConditionsResult;
export type PostApiV1TermsApiArg = {
  termsAndConditionsModel: TermsAndConditionsModel;
};
export type GetApiV1TipsApiResponse = /** status 200 Success */ TipDto[];
export type GetApiV1TipsApiArg = void;
export type SimplePortalUser = {
  userId?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  organisationCode: string;
  tenant: TenantEnum;
  advertiserCode?: number | null;
  isInternalUser?: boolean;
  clientIdList?: string | null;
  phoneNumber?: string | null;
};
export type SimplePortalUserRead = {
  userId?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  fullName?: string | null;
  organisationCode: string;
  tenant: TenantEnum;
  advertiserCode?: number | null;
  isInternalUser?: boolean;
  clientIdList?: string | null;
  phoneNumber?: string | null;
};
export type RoleFromSterInlog = {
  id: number;
  name: string;
  alias: string;
};
export type ApplicationFromSterInlog = {
  id: number;
  name: string;
  alias: string;
  url: string;
  newWindow: boolean;
  partOfDefaultSet: boolean;
};
export type SterInlogUser = {
  internalUser: boolean;
  azureId?: string | null;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  company?: string | null;
  phonenumber?: string | null;
  active: boolean;
  verified: boolean;
  roles: RoleFromSterInlog[];
  applications: ApplicationFromSterInlog[];
};
export type SterInlogUserRead = {
  id: string;
  internalUser: boolean;
  azureId?: string | null;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  company?: string | null;
  phonenumber?: string | null;
  active: boolean;
  verified: boolean;
  roles: RoleFromSterInlog[];
  applications: ApplicationFromSterInlog[];
};
export type PortalUser = {
  active: boolean;
  phoneNumber?: string | null;
  userName: string;
  userInfo: SimplePortalUser;
  roles: string[];
  claims: string[];
  sterInlog: SterInlogUser;
  userType: string;
  ipAddress?: string | null;
};
export type PortalUserRead = {
  id: string;
  email: string;
  active: boolean;
  phoneNumber?: string | null;
  userName: string;
  userInfo: SimplePortalUserRead;
  roles: string[];
  claims: string[];
  lastLogin?: string | null;
  sterInlog: SterInlogUserRead;
  userType: string;
  ipAddress?: string | null;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export type Organisation = {
  code: string;
  name: string;
};
export type GetOrganisationCodesResponse = {
  data: {
    National?: Organisation[];
    Regional?: Organisation[];
  };
};
export type Product = {
  id: number;
  description: string;
  brandId?: number;
  brandDescr?: string | null;
  medium?: MediumEnum;
};
export type Advertiser = {
  id: number;
  name: string;
  products?: Product[] | null;
  isProspect?: boolean;
  volumeDiscount?: VolumeDiscount;
};
export type GetAdvertisersResponse = {
  data: {
    National?: Advertiser[];
    Regional?: Advertiser[];
  };
};
export type ResultDeleted = {
  deleted?: boolean;
};
export type IdentityResult = {};
export type IdentityError = {
  code?: string | null;
  description?: string | null;
};
export type IdentityResultRead = {
  succeeded?: boolean;
  errors?: IdentityError[] | null;
};
export type DeactivateUserInput = {
  emailAddress: string;
};
export type ResultActivated = {
  activated?: boolean;
};
export type OperationalContextState = {
  tenant: TenantEnum;
  organisationCode: string;
  advertiserCode?: number | null;
};
export type UserModel = {
  email: string;
  phoneNumber?: string | null;
  firstName: string;
  lastName: string;
  operationalContextState: OperationalContextState;
  clientIdList?: string | null;
  active?: boolean;
  roles?: string[] | null;
  claims?: string[] | null;
};
export type AnnouncementDto = {
  id?: number;
  userId?: string | null;
  show?: boolean;
  tenant: TenantEnum;
  created?: string;
  modified?: string;
  startDate: string;
  endDate: string;
  message: string;
  messageInt?: string | null;
};
export type AssetManagementHook = {
  secure_token?: string | null;
  scheme?: string | null;
  album?: string | null;
  displayname?: string | null;
  id?: string | null;
};
export type BookSpotItem = {
  action: BookSpotAction;
  schedDate: string;
  breakId: string;
  spotId?: number;
  preferredPositionString?: string | null;
};
export type BookSpotCollection = {
  emailPlannerAgency?: string | null;
  emailPlannerSalesHouse?: string | null;
  orderId: number;
  subOrderId: number;
  items: BookSpotItem[];
};
export type DateSpan = {
  from: string;
  to: string;
};
export type SimpleBreakSelection = {
  breakId?: string | null;
  schedDate?: string;
  schedStartTime?: string;
  grossSpotPrice?: number;
  nettSpotPrice?: number;
  preferredPosition?: string | null;
};
export type DynamicMarketIndexRadio = {
  index: number;
  channelIds: string[];
  start: string;
  end: string;
};
export type OutputPricePerMonth = {
  month: number;
  monthIndex: number;
  marketIndex: number;
  marketIndexRadio?: DynamicMarketIndexRadio[] | null;
  outputPrice: number;
  discountPercWithMarketIndex: number;
};
export type TargetGroup = {
  id?: number;
  costPerGrp: number;
  outputPriceWithoutSpotLengthIndex: OutputPricePerMonth[];
  costPerSpot?: number;
  intomartTargetGroupId: string;
  targetGroupId: string;
  targetGroupName: string;
  targetGroupIndex: number;
  transitionIndex: number;
  targetGroupIndexWithoutTransitionIndex: number;
  channels: string[];
  cpmPrice: number;
  hasChannelChoice: boolean;
  disabledDeseletionChannels: string[];
  maximumChannelDeselection?: number | null;
};
export type DiscountSurtaxRule = {
  code?: string | null;
  description?: string | null;
  percentage?: number;
  type?: RuleType;
};
export type InternetFormat = {
  id?: string | null;
  description?: string | null;
  spotLength?: number;
  index?: number;
  cpmPrice?: number;
  outputPrice?: OutputPricePerMonth[] | null;
};
export type Package = {
  hasBreakSelection: boolean;
  marketIndex: number;
  monthIndex: number;
  periods: DateSpan[];
  salesLockPeriods: DateSpan[];
  productIndex: number;
  targetGroups: TargetGroup[];
  minDays: number;
  minGrp?: number;
  discountPerc: number;
  dynamicMarketIndex: number;
  canSelectPreferredPosition: boolean;
  hasSpread: boolean;
  minDailyHours: number;
  minDaysNoWeekly: number;
  spotLengths?: number[] | null;
  costPerSpot?: number;
  digitalMarketIndexPublished?: boolean | null;
  discountSurtaxes?: DiscountSurtaxRule[] | null;
  code: string;
  name: string;
  internetFormat?: InternetFormat[] | null;
  yourTimeType?: string | null;
  preferredPositionSurcharge?: number;
  type?: PackageType;
};
export type TimeBand = {
  from: string;
  to: string;
};
export type PackageRead = {
  hasBreakSelection: boolean;
  marketIndex: number;
  monthIndex: number;
  periods: DateSpan[];
  salesLockPeriods: DateSpan[];
  productIndex: number;
  targetGroups: TargetGroup[];
  minDays: number;
  minGrp?: number;
  yearBaseRate: number;
  weekDays?: number[] | null;
  timeBands?: TimeBand[] | null;
  discountPerc: number;
  dynamicMarketIndex: number;
  canSelectPreferredPosition: boolean;
  hasSpread: boolean;
  hasConsecutiveHours: boolean;
  minDailyHours: number;
  minDaysNoWeekly: number;
  spotLengths?: number[] | null;
  costPerSpot?: number;
  digitalMarketIndexPublished?: boolean | null;
  isSpreadEnabled: boolean;
  discountSurtaxes?: DiscountSurtaxRule[] | null;
  code: string;
  name: string;
  internetFormat?: InternetFormat[] | null;
  isYourTime: boolean;
  yourTimeType?: string | null;
  preferredPositionSurcharge?: number;
  type?: PackageType;
  isCombi: boolean;
};
export type SubOrderConfiguration = {
  medium?: MediumEnum;
  subOrderPeriod?: DateSpan;
  packageCode?: string | null;
  targetGroupId?: string | null;
  spotLength?: number[] | null;
  budget?: number;
  grps?: number;
  impressions?: number;
  portalCriteria?: string | null;
  channels?: string[] | null;
  spreadIndex?: number | null;
  spread?: boolean;
  spotNo?: number;
  campaignName?: string | null;
  daysOfWeek?: number[] | null;
  format?: string | null;
  impressionsDiscount?: number | null;
  timeSelection?: number[] | null;
  breakSelection?: SimpleBreakSelection[] | null;
  package?: Package;
  packageChoice?: PackageChoiceEnum;
  id?: number;
};
export type SubOrderConfigurationRead = {
  medium?: MediumEnum;
  subOrderPeriod?: DateSpan;
  packageCode?: string | null;
  targetGroupId?: string | null;
  spotLength?: number[] | null;
  budget?: number;
  grps?: number;
  impressions?: number;
  portalCriteria?: string | null;
  channels?: string[] | null;
  spreadIndex?: number | null;
  spread?: boolean;
  spotNo?: number;
  campaignName?: string | null;
  daysOfWeek?: number[] | null;
  format?: string | null;
  impressionsDiscount?: number | null;
  timeSelection?: number[] | null;
  breakSelection?: SimpleBreakSelection[] | null;
  package?: PackageRead;
  packageChoice?: PackageChoiceEnum;
  id?: number;
};
export type CampaignConfiguration = {
  code?: string | null;
  forecastConversionGroups?: string[] | null;
  createdOn?: string;
  id?: number;
  configuration?: SubOrderConfiguration[] | null;
  orderPeriod?: DateSpan;
};
export type CampaignConfigurationRead = {
  code?: string | null;
  forecastConversionGroups?: string[] | null;
  createdOn?: string;
  id?: number;
  configuration?: SubOrderConfigurationRead[] | null;
  orderPeriod?: DateSpan;
};
export type CampaignImportMessage = {
  message: string;
  subOrderIndex: number;
  packageCode: string;
};
export type CampaignImportOutput = {
  mediumEnum?: MediumEnum;
  initialRequestId?: number;
  orderPeriod?: DateSpan;
  messages?: CampaignImportMessage[] | null;
};
export type CampaignConfigurationInput = {
  campaignName?: string | null;
  comments?: string | null;
  invoice?: string | null;
  crmAccount?: string | null;
  crmOpportunity?: string | null;
  crmContact?: string | null;
  contact?: string | null;
  contactEmailExtra?: string | null;
  productId: number;
  campaignCode: string;
  advertiserId: number;
};
export type AuditlogModel = {
  id?: number | null;
  userId?: string | null;
  user?: SimplePortalUser;
  created?: string;
  organisationCode?: string | null;
  emailadress?: string | null;
  advertiserCode?: number | null;
  medium?: MediumEnum;
  productId?: number | null;
  orderId?: number | null;
  requestId?: number | null;
  actionType?: AuditActionType;
  actionMessage?: string | null;
};
export type AuditlogModelRead = {
  id?: number | null;
  userId?: string | null;
  user?: SimplePortalUserRead;
  created?: string;
  organisationCode?: string | null;
  emailadress?: string | null;
  advertiserCode?: number | null;
  medium?: MediumEnum;
  productId?: number | null;
  orderId?: number | null;
  requestId?: number | null;
  actionType?: AuditActionType;
  actionMessage?: string | null;
};
export type SubOrderListItem = {
  id: number;
  onlinePackageChoice: OnlinePackageChoice;
};
export type AnalysisAvailability = {
  from?: string | null;
  isAvailable?: boolean;
};
export type OrderListItem = {
  name?: string | null;
  id: number;
  orderStartDate: string;
  orderEndDate: string;
  startDate: string;
  endDate: string;
  budget: number;
  productDescr: string;
  productId: number;
  advertiserName: string;
  advertiserId: number;
  medium: MediumEnum;
  predictedBudget?: number;
  bookedBudget?: number;
  spreadedSecondPart: boolean;
  spreadedFirstPart: boolean;
  spreadedOrderId?: number | null;
  status: OrderStatus;
  runningState: RunningState;
  needsInstructions?: boolean;
  auditLogs: AuditlogModel[];
  packageCodes?: string[] | null;
  contactPersonAgencyEmail?: string | null;
  isProspect?: boolean;
  hasRequestedGrps?: boolean;
  hasBookedGrps?: boolean;
  hasRequestedImpressions?: boolean;
  hasBookedImpressions?: boolean;
  prospectId?: string | null;
  disableDetails?: boolean;
  subOrders: SubOrderListItem[];
  isEditable?: boolean;
  analysis: AnalysisAvailability;
};
export type OrderListItemRead = {
  name?: string | null;
  id: number;
  orderStartDate: string;
  orderEndDate: string;
  startDate: string;
  endDate: string;
  budget: number;
  productDescr: string;
  productId: number;
  advertiserName: string;
  advertiserId: number;
  medium: MediumEnum;
  predictedBudget?: number;
  bookedBudget?: number;
  spreadedSecondPart: boolean;
  spreadedFirstPart: boolean;
  spreadedOrderId?: number | null;
  status: OrderStatus;
  runningState: RunningState;
  needsInstructions?: boolean;
  auditLogs: AuditlogModelRead[];
  packageCodes?: string[] | null;
  contactPersonAgencyEmail?: string | null;
  isProspect?: boolean;
  hasRequestedGrps?: boolean;
  hasBookedGrps?: boolean;
  hasRequestedImpressions?: boolean;
  hasBookedImpressions?: boolean;
  prospectId?: string | null;
  disableDetails?: boolean;
  subOrders: SubOrderListItem[];
  isEditable?: boolean;
  analysis: AnalysisAvailability;
};
export type PackageRequest = {
  code: string;
  name: string;
  internetFormat?: InternetFormat[] | null;
  yourTimeType?: string | null;
  preferredPositionSurcharge?: number;
  type?: PackageType;
};
export type PackageRequestRead = {
  code: string;
  name: string;
  internetFormat?: InternetFormat[] | null;
  isYourTime: boolean;
  yourTimeType?: string | null;
  preferredPositionSurcharge?: number;
  type?: PackageType;
  isCombi: boolean;
};
export type TargetGroupRequest = {
  id?: number;
  intomartTargetGroupId?: string | null;
  targetGroupId?: string | null;
};
export type GrpPricePerMonth = {
  month: number;
  grpPriceWithMonthIndex: number;
  grpPriceWithMonthAndSpotLengthIndex: number;
};
export type Tagon = {
  spotId: number;
  commercialCode: string;
  commercialDescr: string;
  compositionSeqNr: number;
  spotLength: number;
};
export type RatingRegio = {
  intomartTargetGroupId: string;
  targetGroupId: string;
  channelNr: string;
  channelDescr: string;
  actualStartTime: string;
  achievedRating: number;
};
export type Spot = {
  achievedRating: number;
  achievedRatingSecondaryTargetGroup?: number | null;
  bonusAmount: number;
  breakId: string;
  channelDescr: string;
  commercialCode?: string | null;
  commercialDescr?: string | null;
  discountAmount: number;
  grossSpotPrice: number;
  nettSpotPrice: number;
  subOrderId: number;
  packageDescr: string;
  predictedRating: number;
  predictedRatingSecondaryTargetGroup?: number | null;
  preferredPosition?: string | null;
  programmeAfter: string;
  programmeBefore: string;
  schedDate: string;
  schedStartTime: string;
  spotId: number;
  spotLength: number[];
  spotStatus?: string | null;
  totalSpotDiscount: number;
  compositionSeqNr: number;
  isTagon?: boolean;
  tagons?: Tagon[] | null;
  disabled?: boolean;
  runningState: RunningState;
  nacalcRating?: number | null;
  selectivity?: number | null;
  selectivitySecondaryTargetGroup?: number | null;
  ratingsRegio: RatingRegio[];
};
export type SpotRead = {
  achievedRating: number;
  achievedRatingSecondaryTargetGroup?: number | null;
  bonusAmount: number;
  breakId: string;
  channelDescr: string;
  commercialCode?: string | null;
  commercialDescr?: string | null;
  discountAmount: number;
  grossSpotPrice: number;
  nettSpotPrice: number;
  subOrderId: number;
  packageDescr: string;
  predictedRating: number;
  predictedRatingSecondaryTargetGroup?: number | null;
  preferredPosition?: string | null;
  programmeAfter: string;
  programmeBefore: string;
  schedDate: string;
  schedStartTime: string;
  spotId: number;
  spotLength: number[];
  spotStatus?: string | null;
  totalSpotDiscount: number;
  compositionSeqNr: number;
  isTagon?: boolean;
  tagons?: Tagon[] | null;
  uniqueId?: string | null;
  disabled?: boolean;
  runningState: RunningState;
  nacalcRating?: number | null;
  selectivity?: number | null;
  selectivitySecondaryTargetGroup?: number | null;
  ratingsRegio: RatingRegio[];
};
export type SubOrderDetail = {
  campaignName?: string | null;
  bookedBudget: number;
  id: number;
  spotLength?: number[] | null;
  period: DateSpan;
  package: PackageRequest;
  requestedGrp: number;
  requestedBudget: number;
  targetGroup: TargetGroupRequest;
  grpPricePerMonth?: GrpPricePerMonth[] | null;
  bookedGrp: number;
  status: OrderStatus;
  runningState: RunningState;
  requestedImpressions?: number;
  bookedImpressions?: number;
  cpmPrice?: number;
  lineItemId?: string | null;
  internetFormatId?: string | null;
  clicks?: number | null;
  starts?: number | null;
  complete?: number | null;
  spotsEditable: boolean;
  format?: string | null;
  packageChoice: PackageChoiceEnum;
  channels?: string[] | null;
  achievedNettBudget: number;
  achievedGrp: number;
  timeBands: TimeBand[];
  nacalcGrp?: number | null;
  editableFields?: UpdateFields[] | null;
  adScienceId?: string | null;
  hasMaterial?: boolean | null;
  bonusBudgetRequested?: number;
  bonusBudgetBooked?: number;
  spotSelection?: Spot[] | null;
  hasBreakSelection?: boolean;
  requestDate?: string;
};
export type SubOrderDetailRead = {
  campaignName?: string | null;
  bookedBudget: number;
  id: number;
  spotLength?: number[] | null;
  period: DateSpan;
  package: PackageRequestRead;
  requestedGrp: number;
  requestedBudget: number;
  targetGroup: TargetGroupRequest;
  grpPricePerMonth?: GrpPricePerMonth[] | null;
  bookedGrp: number;
  status: OrderStatus;
  runningState: RunningState;
  requestedImpressions?: number;
  bookedImpressions?: number;
  cpmPrice?: number;
  lineItemId?: string | null;
  internetFormatId?: string | null;
  clicks?: number | null;
  starts?: number | null;
  complete?: number | null;
  ctr?: number | null;
  cvr?: number | null;
  spotsEditable: boolean;
  format?: string | null;
  packageChoice: PackageChoiceEnum;
  channels?: string[] | null;
  achievedNettBudget: number;
  achievedGrp: number;
  timeBands: TimeBand[];
  nacalcGrp?: number | null;
  editableFields?: UpdateFields[] | null;
  adScienceId?: string | null;
  hasMaterial?: boolean | null;
  bonusBudgetRequested?: number;
  bonusBudgetBooked?: number;
  spotSelection?: SpotRead[] | null;
  hasBreakSelection?: boolean;
  requestDate?: string;
};
export type OrderDetail = {
  productDescr: string;
  id: number;
  productId: number;
  orderStartDate: string;
  orderEndDate: string;
  orderBudget: number;
  medium: MediumEnum;
  requestedBudget: number;
  bookedBudget: number;
  campaignName: string;
  advertiserId: number;
  advertiserName: string;
  subOrders?: SubOrderDetail[] | null;
  status: OrderStatus;
  runningState: RunningState;
  requestedImpressions?: number;
  bookedImpressions?: number;
  auditLogs: AuditlogModel[];
  salesPeriod: DateSpan;
  isSpreaded?: boolean;
  spreadedPeriod?: DateSpan;
  analysis: AnalysisAvailability;
  analysisTargetGroupIds?: string[] | null;
  hasMaterial?: boolean | null;
  bonusBudgetRequested?: number;
  bonusBudgetBooked?: number;
  initialRequestId?: number;
  originalBudget?: number;
};
export type OrderDetailRead = {
  productDescr: string;
  id: number;
  productId: number;
  orderStartDate: string;
  orderEndDate: string;
  orderBudget: number;
  medium: MediumEnum;
  requestedBudget: number;
  requestedGrp: number;
  bookedBudget: number;
  bookedGrp: number;
  achievedNettBudget: number;
  achievedGrp: number;
  campaignName: string;
  advertiserId: number;
  advertiserName: string;
  subOrders?: SubOrderDetailRead[] | null;
  status: OrderStatus;
  runningState: RunningState;
  requestedImpressions?: number;
  bookedImpressions?: number;
  auditLogs: AuditlogModelRead[];
  salesPeriod: DateSpan;
  isSpreaded?: boolean;
  spreadedPeriod?: DateSpan;
  analysis: AnalysisAvailability;
  analysisTargetGroupIds?: string[] | null;
  hasMaterial?: boolean | null;
  bonusBudgetRequested?: number;
  bonusBudgetBooked?: number;
  initialRequestId?: number;
  originalBudget?: number;
};
export type Break = {
  breakId?: string | null;
  schedDate?: string;
  baseBreakPrice?: number;
  channelDescr?: string | null;
  grossSpotPrice?: number;
  nettSpotPrice?: number;
  schedStartTime?: string;
  preferredPosition?: string | null;
  programmeBefore?: string | null;
  programmeAfter?: string | null;
  selectivitySurcharge?: number;
};
export type BreakRead = {
  breakId?: string | null;
  schedDate?: string;
  baseBreakPrice?: number;
  channelDescr?: string | null;
  grossSpotPrice?: number;
  nettSpotPrice?: number;
  schedStartTime?: string;
  preferredPosition?: string | null;
  programmeBefore?: string | null;
  programmeAfter?: string | null;
  selectivitySurcharge?: number;
  key?: string | null;
};
export type BreakSelection = {
  key?: string | null;
  break?: Break;
  preferredPosition?: string | null;
};
export type BreakSelectionRead = {
  key?: string | null;
  break?: BreakRead;
  preferredPosition?: string | null;
};
export type DiscountProps = {
  timeTargeting?: boolean;
};
export type Commercial = {
  status: CommercialStatus;
  code: string;
  description?: string | null;
  creationDate?: string | null;
  length: number;
  currentYearBroadcasts?: number | null;
  previousYearBroadcasts?: number | null;
  productId: number;
  currentYear?: number | null;
  thumbnails: string[];
  medium: MediumEnum;
  url?: string | null;
  adScienceId?: number;
};
export type CommercialRead = {
  id: string;
  status: CommercialStatus;
  code: string;
  description?: string | null;
  creationDate?: string | null;
  length: number;
  currentYearBroadcasts?: number | null;
  previousYearBroadcasts?: number | null;
  productId: number;
  currentYear?: number | null;
  thumbnails: string[];
  medium: MediumEnum;
  url?: string | null;
  adScienceId?: number;
};
export type PreviewUrl = {
  name?: string | null;
  url?: string | null;
};
export type Banner = {
  id?: number;
  description?: string | null;
  clickUrl?: string | null;
  width?: number;
  height?: number;
  creationDate?: string;
  status?: CommercialStatus;
  tag?: string | null;
  previews?: PreviewUrl[] | null;
  medium?: MediumEnum;
};
export type OnlineInstructionSpots = {
  id: number;
  commercial?: Commercial;
  banner?: Banner;
  homepage?: string | null;
};
export type OnlineInstructionSpotsRead = {
  id: number;
  commercial?: CommercialRead;
  banner?: Banner;
  homepage?: string | null;
};
export type OnlineInstructionPerPeriod = {
  id: number;
  startDate?: string | null;
  endDate?: string | null;
  instructions?: OnlineInstructionSpots[] | null;
};
export type OnlineInstructionPerPeriodRead = {
  id: number;
  startDate?: string | null;
  endDate?: string | null;
  instructions?: OnlineInstructionSpotsRead[] | null;
};
export type SubOrderRequest = {
  id?: number | null;
  period: DateSpan;
  excluded?: string[] | null;
  excludedPeriodStrings?: string[] | null;
  spread?: boolean;
  package: PackageRequest;
  channels?: string[] | null;
  targetGroup?: TargetGroupRequest;
  spotLength?: number[] | null;
  budget: number;
  grp?: number;
  spotsPerDayChannel?: number;
  spotsPerDay?: number;
  spotsTotal?: number | null;
  spotNo?: number;
  impressions?: number;
  cpmPrice?: number | null;
  contextTargets?: string[] | null;
  packageChoice?: PackageChoiceEnum;
  breakSelection?: BreakSelection[] | null;
  timeSelection?: number[] | null;
  endTime?: string | null;
  site?: string[] | null;
  program?: string[] | null;
  deviceTargets?: string[] | null;
  discountProperties?: DiscountProps;
  campaignName?: string | null;
  subOrderRequestStatus?: OrderStatus;
  errorMessage?: string | null;
  daysOfWeek?: number[] | null;
  format?: string | null;
  revenueType?: number | null;
  useTime?: boolean;
  onlineInstructionsPerPeriod?: OnlineInstructionPerPeriod[] | null;
  editableFields?: UpdateFields[] | null;
  lineItemId?: string | null;
};
export type SubOrderRequestRead = {
  id?: number | null;
  period: DateSpan;
  excluded?: string[] | null;
  excludedPeriodStrings?: string[] | null;
  spread?: boolean;
  package: PackageRequestRead;
  channels?: string[] | null;
  targetGroup?: TargetGroupRequest;
  spotLength?: number[] | null;
  budget: number;
  grp?: number;
  spotsPerDayChannel?: number;
  spotsPerDay?: number;
  spotsTotal?: number | null;
  spotNo?: number;
  impressions?: number;
  cpmPrice?: number | null;
  contextTargets?: string[] | null;
  packageChoice?: PackageChoiceEnum;
  breakSelection?: BreakSelectionRead[] | null;
  timeSelection?: number[] | null;
  endTime?: string | null;
  site?: string[] | null;
  program?: string[] | null;
  deviceTargets?: string[] | null;
  discountProperties?: DiscountProps;
  campaignName?: string | null;
  subOrderRequestStatus?: OrderStatus;
  errorMessage?: string | null;
  daysOfWeek?: number[] | null;
  format?: string | null;
  revenueType?: number | null;
  useTime?: boolean;
  onlineInstructionsPerPeriod?: OnlineInstructionPerPeriodRead[] | null;
  editableFields?: UpdateFields[] | null;
  lineItemId?: string | null;
};
export type ForecastConfiguration = {
  conversionGroups?: string[] | null;
  displayContactDistribution?: boolean;
  displayAbsoluteNumbers?: boolean;
  additionalCoverage?: ForecastCoverageEnum;
};
export type CommercialInstructionSpots = {
  id: number;
  commercial?: Commercial;
  banner?: Banner;
  period?: DateSpan;
  startTime?: string | null;
  endTime?: string | null;
  channel?: string | null;
  tagOns?: Commercial[] | null;
  rotationFrequency?: number | null;
  homepage?: string | null;
  selectedDays?: number[] | null;
};
export type CommercialInstructionSpotsRead = {
  id: number;
  commercial?: CommercialRead;
  banner?: Banner;
  period?: DateSpan;
  startTime?: string | null;
  endTime?: string | null;
  channel?: string | null;
  tagOns?: CommercialRead[] | null;
  rotationFrequency?: number | null;
  homepage?: string | null;
  selectedDays?: number[] | null;
};
export type CommercialInstructionCommercialsPerPeriod = {
  id: number;
  spotLength: number[];
  dateRanges: DateSpan[];
  excluded: string[];
  commercialSelection: CommercialInstructionSpots[];
};
export type CommercialInstructionCommercialsPerPeriodRead = {
  id: number;
  spotLength: number[];
  dateRanges: DateSpan[];
  excluded: string[];
  commercialSelection: CommercialInstructionSpotsRead[];
};
export type CommercialInstructionRequest = {
  id?: number;
  instructionStatus: CommercialInstructionStatus;
  medium: MediumEnum;
  productId: number;
  period?: DateSpan;
  creationDate?: string | null;
  startTime?: string | null;
  endTime?: string | null;
  channels?: string[] | null;
  selectedDays?: number[] | null;
  instructionInfo?: string | null;
  contactPersonAgencyEmail?: string | null;
  alternationType: CommercialInstructionAlternationType;
  commercialsPerPeriod: CommercialInstructionCommercialsPerPeriod[];
};
export type CommercialInstructionRequestRead = {
  id?: number;
  instructionStatus: CommercialInstructionStatus;
  medium: MediumEnum;
  productId: number;
  period?: DateSpan;
  creationDate?: string | null;
  startTime?: string | null;
  endTime?: string | null;
  channels?: string[] | null;
  selectedDays?: number[] | null;
  instructionInfo?: string | null;
  contactPersonAgencyEmail?: string | null;
  alternationType: CommercialInstructionAlternationType;
  commercialsPerPeriod: CommercialInstructionCommercialsPerPeriodRead[];
};
export type OrderRequest = {
  id?: number | null;
  requestStatus?: OrderStatus;
  contactEmail?: string | null;
  productId: number;
  advertiserId: number;
  medium: MediumEnum;
  campaignName?: string | null;
  comments?: string | null;
  subOrders?: SubOrderRequest[] | null;
  spreadedSecondPart?: boolean;
  spreadedFirstPart?: boolean;
  invoice?: string | null;
  requestDate?: string;
  mutationTimeStamp?: string;
  errorMessage?: string | null;
  period: DateSpan;
  auditLogs?: AuditlogModel[] | null;
  forecastConfiguration?: ForecastConfiguration;
  instructions?: CommercialInstructionRequest[] | null;
  crmAccount?: string | null;
  crmOpportunity?: string | null;
  crmContact?: string | null;
  advertiser?: string | null;
  contact?: string | null;
  contactEmailExtra?: string | null;
  originalBudget?: number;
};
export type OrderRequestRead = {
  id?: number | null;
  requestStatus?: OrderStatus;
  contactEmail?: string | null;
  productId: number;
  advertiserId: number;
  medium: MediumEnum;
  campaignName?: string | null;
  comments?: string | null;
  subOrders?: SubOrderRequestRead[] | null;
  spreadedSecondPart?: boolean;
  spreadedFirstPart?: boolean;
  isSpreaded?: boolean;
  invoice?: string | null;
  requestDate?: string;
  mutationTimeStamp?: string;
  errorMessage?: string | null;
  period: DateSpan;
  auditLogs?: AuditlogModelRead[] | null;
  forecastConfiguration?: ForecastConfiguration;
  instructions?: CommercialInstructionRequestRead[] | null;
  crmAccount?: string | null;
  crmOpportunity?: string | null;
  crmContact?: string | null;
  advertiser?: string | null;
  contact?: string | null;
  contactEmailExtra?: string | null;
  originalBudget?: number;
};
export type SpotsResult = {
  medium: MediumEnum;
  orderId: number;
  spots: Spot[];
};
export type SpotsResultRead = {
  medium: MediumEnum;
  orderId: number;
  spots: SpotRead[];
};
export type BlockSpot = {
  advertiserName: string;
  commercialCode?: string | null;
  commercialDescr?: string | null;
  orderId: number;
  subOrderId: number;
  packageDescr: string;
  productDescr: string;
  productId: number;
  spotId: number;
  spotLength: number;
};
export type Block = {
  breakId: string;
  programmeAfter: string;
  programmeAfterTime?: string | null;
  programmeBefore: string;
  programmeBeforeTime?: string | null;
  schedDate: string;
  schedStartTime: string;
  spots: BlockSpot[];
};
export type Channel = {
  blocks: Block[];
  channelAka: string;
  channelDescr: string;
  medium: MediumEnum;
};
export type SpotsByMedium = {
  channels: Channel[];
  medium: MediumEnum;
};
export type ExportOutputSettings = {
  orderId: number;
  subOrderId?: number | null;
  columns: string[];
  dateFrom: string;
  dateTo: string;
  targetGroup?: string | null;
  fileType: ExportFileType;
};
export type Age = {
  min: number;
  max?: number;
};
export type AnalysisTargetGroupTv = {
  dag?: boolean;
  zzp?: boolean;
  "+KND12"?: boolean;
  "+KND17"?: boolean;
  hhv?: boolean;
  aB1?: boolean;
  ehb?: boolean;
  w1?: boolean;
  b2B?: boolean;
  age: Age;
  gender?: string | null;
};
export type AnalysisTargetGroupRadio = {
  zb?: boolean;
  "HHV+KND(0-12)"?: boolean;
  hhv?: boolean;
  aB1?: boolean;
  ehb?: boolean;
  w1?: boolean;
  b2B?: boolean;
  age: Age;
  gender?: string | null;
};
export type AnalysisTargetGroups = {
  tv: AnalysisTargetGroupTv[];
  radio: AnalysisTargetGroupRadio[];
};
export type AnalysisPeriod = {
  start?: string;
  end?: string;
};
export type GenericAnalysisInput = {
  secondaryTargetGroups?: AnalysisTargetGroups;
  productId?: number;
  period?: AnalysisPeriod;
};
export type AnalysisInput = {
  secondaryTargetGroups?: AnalysisTargetGroups;
  relatedOrderIds?: number[] | null;
};
export type CampaignForwarded = {
  id?: number | null;
  campaignConfigurationId: number;
  status: CampaignForwardedStatus;
  modified: string;
  forwardedOn: string;
  forwardedBy: string;
  forwardedTo: string;
  organisationCode: string;
  advertiserId: number;
  productId: number;
  package?: Package;
  campaignConfiguration?: CampaignConfiguration;
  budgetChanged?: boolean;
};
export type CampaignForwardedRead = {
  id?: number | null;
  campaignConfigurationId: number;
  status: CampaignForwardedStatus;
  modified: string;
  forwardedOn: string;
  forwardedBy: string;
  forwardedTo: string;
  organisationCode: string;
  advertiserId: number;
  productId: number;
  advertiserName?: string | null;
  productDescr?: string | null;
  package?: PackageRead;
  campaignConfiguration?: CampaignConfigurationRead;
  budgetChanged?: boolean;
};
export type CampaignForwardInput = {
  organisationCode?: string | null;
  advertiserId?: number;
  productId?: number;
  email?: string | null;
};
export type OnlineInstructionPerSubOrder = {
  subOrderId: number;
  instructionsPerPeriod?: OnlineInstructionPerPeriod[] | null;
};
export type OnlineInstructionPerSubOrderRead = {
  subOrderId: number;
  instructionsPerPeriod?: OnlineInstructionPerPeriodRead[] | null;
};
export type CommercialsPerAdvertiser = {
  advertiserId: number;
  advertiserName: string;
  banners: Banner[];
  commercials: Commercial[];
  products: Product[];
};
export type CommercialsPerAdvertiserRead = {
  advertiserId: number;
  advertiserName: string;
  banners: Banner[];
  commercials: CommercialRead[];
  products: Product[];
};
export type NpoPlayerResult = {
  token?: string | null;
  embedUrl?: string | null;
  embedCode?: string | null;
};
export type CommercialUploadResult = {
  status: Status;
};
export type CommercialUploader = {
  id: string;
  email: string;
  name: string;
  organisationCode: string;
};
export type AdScienceMediaFile = {
  width?: number;
  height?: number;
  type?: string | null;
  url?: string | null;
  adScienceId?: number;
  duration?: number;
  name?: string | null;
  tag?: string | null;
};
export type CommercialUploadRegistration = {
  id: number;
  adScienceId: number;
  advertiserId: number;
  uploadedOn: string;
  status: CommercialUploadStatus;
  message?: string | null;
  uploader: CommercialUploader;
  mediaFile: AdScienceMediaFile;
};
export type CommercialUploadRegistrationReview = {
  message?: string | null;
  newStatus: CommercialUploadStatus;
  internalId: number;
};
export type CommercialUploadRegistrationReactivate = {
  adScienceId: number;
  advertiserId: number;
};
export type CustomerAccount = {
  name: string;
  id: string;
  starlightId: string;
};
export type CustomerContact = {
  contactId: string;
};
export type CustomerContactRead = {
  contactId: string;
  name?: string | null;
};
export type CustomerOpportunity = {
  name: string;
  id: string;
};
export type DocumentUser = {
  userName: string;
};
export type Document = {
  organisation: string;
  advertiser?: number | null;
  advertiserName?: string | null;
  publicationEnd?: string | null;
  publicationStart: string;
  uploadedBy: DocumentUser;
  lastModified: string;
  filename: string;
  id: number;
  guid: string;
};
export type DocumentRead = {
  organisation: string;
  advertiser?: number | null;
  advertiserName?: string | null;
  publicationEnd?: string | null;
  publicationStart: string;
  uploadedBy: DocumentUser;
  lastModified: string;
  filename: string;
  id: number;
  guid: string;
  type: string;
};
export type DocumentResultSuccess = {
  success?: string | null;
};
export type DocumentResultDeleted = {
  deleted?: string | null;
};
export type Forecast = {
  bereikPercentage: number;
  gcf: number;
  bereikNumber?: number;
  grp: number;
};
export type ForecastResultTargetGroup = {
  targetGroup: string;
  targetGroupDisplayName: string;
  forecasts: {
    Coverage1?: Forecast;
    Coverage2?: Forecast;
    Coverage3?: Forecast;
    Coverage4?: Forecast;
    Coverage5?: Forecast;
    Coverage6?: Forecast;
    Coverage7?: Forecast;
    Coverage8?: Forecast;
    Coverage9?: Forecast;
    Coverage10?: Forecast;
  };
};
export type ForecastResult = {
  conversionForecasts?: ForecastResultTargetGroup[] | null;
  forecasts?: ForecastResultTargetGroup[] | null;
  unknownChannels?: string[] | null;
  yourTimeRequirementsNotMet?: boolean;
  top2000RequirementsNotMet?: boolean;
  regioChannelForecastNotAvailable?: boolean;
  regioPackageForecastNotAvailable?: boolean;
};
export type ForecastResultRead = {
  conversionForecasts?: ForecastResultTargetGroup[] | null;
  forecasts?: ForecastResultTargetGroup[] | null;
  unknownPackageCodes?: string[] | null;
  unknownChannels?: string[] | null;
  yourTimeRequirementsNotMet?: boolean;
  top2000RequirementsNotMet?: boolean;
  regioChannelForecastNotAvailable?: boolean;
  regioPackageForecastNotAvailable?: boolean;
};
export type ForecastInput = {
  subOrders: SubOrderRequest[];
  productId: number;
  conversionGroups?: string[] | null;
};
export type ForecastInputRead = {
  subOrders: SubOrderRequestRead[];
  productId: number;
  conversionGroups?: string[] | null;
};
export type CampaignCalculatorForecast = {
  targetGroup?: string | null;
  targetGroupDisplayName?: string | null;
  bereikPercentage: number;
  gcf: number;
  bereikNumber?: number;
  grp: number;
};
export type CampaignCalculatorForecastResult = {
  conversionForecasts?: CampaignCalculatorForecast[] | null;
  forecasts?: CampaignCalculatorForecast[] | null;
  unknownPackageCodes?: string[] | null;
  unknownChannels?: string[] | null;
  yourTimeRequirementsNotMet?: boolean;
  top2000RequirementsNotMet?: boolean;
  regioChannelForecastNotAvailable?: boolean;
  regioPackageForecastNotAvailable?: boolean;
};
export type ForecastTargetGroup = {
  code?: string | null;
  displayName?: string | null;
};
export type ForecastTargetGroupRead = {
  code?: string | null;
  displayName?: string | null;
  aka?: string | null;
};
export type ForecastConversionGroups = {
  conversionGroups?: {
    tv?: ForecastTargetGroup[];
    radio?: ForecastTargetGroup[];
    inter?: ForecastTargetGroup[];
  } | null;
};
export type ForecastConversionGroupsRead = {
  conversionGroups?: {
    tv?: ForecastTargetGroupRead[];
    radio?: ForecastTargetGroupRead[];
    inter?: ForecastTargetGroupRead[];
  } | null;
};
export type SecondaryTargetGroup = {
  id?: number;
  code?: string | null;
  mbsId?: string | null;
  medium?: string | null;
  displayName?: string | null;
};
export type SalesPeriod = {
  salesPeriodStartDate: string;
  salesPeriodEndDate: string;
  requestStartDate?: string | null;
  requestEndDate?: string | null;
  confirmationDate?: string | null;
  releaseDate?: string | null;
  ratecardStatus?: string | null;
  canRequest: boolean;
};
export type SalesPeriodResult = {
  medium: MediumEnum;
  result: SalesPeriod[];
};
export type ValueList = {
  name?: string | null;
};
export type ValueListRead = {
  name?: string | null;
  aka?: string | null;
};
export type Invoice = {
  id: string;
  internalId: string;
  medium: MediumEnum;
  productDescr: string;
  advertiserName: string;
  dueDate: string;
  invoiceDate: string;
  invoiceAmount: number;
  vatAmount: number;
  status: InvoiceStatus;
};
export type BookingDate = {
  medium: MediumEnum;
  date: string;
  spotsEditableDate: string;
};
export type OrderIdWithDate = {
  orderId?: number;
  from?: string;
  to?: string;
};
export type CopyOrderInput = {
  campaignName?: string | null;
  orders?: OrderIdWithDate[] | null;
  newPeriod?: DateSpan;
  startDate?: string;
};
export type ProductTourResult = {
  id: number;
  userId: string;
  dateFinished: string;
  lastStep?: number;
  lastStepViewed?: number;
};
export type ProductTourModel = {
  lastStep: number;
  lastStepViewed: number;
};
export type ProposalInitialRequest = {
  medium: MediumEnum;
  initialRequestId: number;
  from: string;
  to: string;
};
export type ProposalRequest = {
  initialRequests: ProposalInitialRequest[];
  type?: ProposalType;
};
export type ProposalOrderRequest = {
  userEmail: string;
  userFullName: string;
  userCompany: string;
  orderRequest: OrderRequest;
  clientId?: string | null;
};
export type ProposalOrderRequestRead = {
  userEmail: string;
  userFullName: string;
  userCompany: string;
  orderRequest: OrderRequestRead;
  clientId?: string | null;
};
export type ForecastForInitialRequestsResultItem = {
  configuration?: ForecastConfiguration;
  result: ForecastResult;
};
export type ForecastForInitialRequestsResultItemRead = {
  configuration?: ForecastConfiguration;
  result: ForecastResultRead;
};
export type ForecastForInitialRequestsResult = {
  byId: {
    [key: string]: ForecastForInitialRequestsResultItem;
  };
  combined?: ForecastForInitialRequestsResultItem;
};
export type ForecastForInitialRequestsResultRead = {
  byId: {
    [key: string]: ForecastForInitialRequestsResultItemRead;
  };
  combined?: ForecastForInitialRequestsResultItemRead;
};
export type CampaignTotals = {
  period: DateSpan;
  budget: number;
  impressions: number;
};
export type ProposalData = {
  forecasts?: ForecastForInitialRequestsResult;
  totals: CampaignTotals;
};
export type ProposalDataRead = {
  forecasts?: ForecastForInitialRequestsResultRead;
  totals: CampaignTotals;
};
export type SpotIndexItem = {
  index: number;
  length: number;
};
export type SpotIndexesResult = {
  data?: SpotIndexItem[] | null;
};
export type PackageResult = {
  type: PackageChoiceEnum;
  packages: Package[];
};
export type PackageResultRead = {
  type: PackageChoiceEnum;
  packages: PackageRead[];
};
export type PackagePropertiesRadio = {
  hasCombi: boolean;
  hasChannelChoice: boolean;
  hasHours: boolean;
  hasDays: boolean;
  code: string;
  hasSpread: boolean;
  hasPreferredPosition: boolean;
};
export type GroupedPackageRadio = {
  name: string;
  properties: PackagePropertiesRadio[];
};
export type PackagePropertiesTv = {
  hasPremium: boolean;
  hasHotspot: boolean;
  code: string;
  hasSpread: boolean;
  hasPreferredPosition: boolean;
};
export type GroupedPackageTv = {
  name: string;
  properties: PackagePropertiesTv[];
};
export type AvailableBreak = {
  baseBreakPrice?: number;
  breakId: string;
  predictedRating?: number;
  predictedRatingSecondaryTargetGroup?: number;
  selectivity?: number;
  selectivitySurcharge: number;
  channelDescr: string;
  grossSpotPrice?: number;
  nettSpotPrice: number;
  programmeAfter: string;
  programmeBefore: string;
  schedDate: string;
  schedStartTime: string;
  selectivityDemandPerc?: number;
  disabled?: boolean;
  isDisabledTop2000?: boolean;
  booked?: boolean;
  positionFreeString?: string | null;
  preferredPosition?: string | null;
  spotId?: number;
};
export type AvailableBreakRead = {
  baseBreakPrice?: number;
  breakId: string;
  predictedRating?: number;
  predictedRatingSecondaryTargetGroup?: number;
  selectivity?: number;
  selectivitySurcharge: number;
  channelDescr: string;
  grossSpotPrice?: number;
  nettSpotPrice: number;
  programmeAfter: string;
  programmeBefore: string;
  schedDate: string;
  schedStartTime: string;
  selectivityDemandPerc?: number;
  disabled?: boolean;
  isDisabledTop2000?: boolean;
  booked?: boolean;
  positionFreeString?: string | null;
  preferredPosition?: string | null;
  spotId?: number;
  uniqueId: string;
};
export type AvailableBreaksRequest = {
  medium?: MediumEnum;
  product: string;
  from: string;
  to: string;
  packageCode: string;
  length: number;
  targetGroup?: string | null;
  secondaryTargetGroup?: string | null;
  channels?: string | null;
  orderId?: string | null;
  subOrderId?: string | null;
  excludedDays?: string[] | null;
};
export type CalculationResult = {
  budget?: number;
  grp?: number;
  spotsPerDay?: number;
  spotsPerDayChannel?: number;
  spots?: number;
  impressions?: number;
};
export type CalculationRequest = {
  productId?: number;
  dateFrom?: string;
  dateTo?: string;
  excludedDays?: string[] | null;
  packageCode?: string | null;
  targetGroupId?: string | null;
  spotLength?: number;
  channels?: string[] | null;
  budget?: number;
  grp?: number;
  spotsPerDay?: number;
  spotsPerDayChannel?: number;
  impressions?: number;
  format?: string | null;
  discountProperties?: DiscountProps;
  startTime?: number | null;
  endTime?: number | null;
  requestDate?: string | null;
};
export type DigitalProgram = {
  name: string;
  displayName: string;
  count: number;
};
export type DigitalProgramIListValueResult = {
  name?: string | null;
  data?: DigitalProgram[] | null;
};
export type ValueListIEnumerableValueResult = {
  name?: string | null;
  data?: ValueList[] | null;
};
export type ValueListIEnumerableValueResultRead = {
  name?: string | null;
  data?: ValueListRead[] | null;
};
export type AppInsights = {
  instrumentationKey?: string;
  cloudRoleNameApi?: string | null;
  cloudRoleNameUi?: string | null;
  enableDebug?: boolean;
};
export type GoogleTagManager = {
  tagManagerId?: string | null;
};
export type PortalSettings = {
  tenant?: TenantEnum;
  disableEditCampaignFields?: {
    tv: {
      SpotLength: boolean;
      Impressions: boolean;
      Budget: boolean;
      EndDate: boolean;
      StartDate: boolean;
      Package: boolean;
    };
    radio: {
      SpotLength: boolean;
      Impressions: boolean;
      Budget: boolean;
      EndDate: boolean;
      StartDate: boolean;
      Package: boolean;
    };
    inter: {
      SpotLength: boolean;
      Impressions: boolean;
      Budget: boolean;
      EndDate: boolean;
      StartDate: boolean;
      Package: boolean;
    };
  } | null;
  enableInitialRequests: boolean;
  enableTerms: boolean;
  enableConcept: boolean;
  termsDate: string;
  enableTop2000Warning: boolean;
  enableDigitalPrograms: boolean;
  enableDashboardCalendar: boolean;
  enableDashboardSpots: boolean;
  monthsInThePast: number;
  monthsInTheFuture: number;
  materialMonthsInThePast: number;
  materialMonthsInTheFuture: number;
  enableNewCommercialsForRotations: boolean;
  maxSalesperiodsOnDashboard: number;
  enableGenerateProposal: boolean;
  enableInstructionsFromRequest: boolean;
  enableChat: boolean;
  enableForward: boolean;
  chatBaseUrl: string;
  chatToken: string;
  enableTenantSwitching: boolean;
  enableEditCampaign: {
    tv: boolean;
    radio: boolean;
    inter: boolean;
  };
  enableCampaignAnalysis: {
    tv: boolean;
    radio: boolean;
    inter: boolean;
  };
  enableCampaignAnalysisNmoNotification: boolean;
  enableAnalysis: boolean;
  enableForecastV2: {
    tv: boolean;
    radio: boolean;
    inter: boolean;
  };
  showDigitalReportWarning: boolean;
  showDigitalMarketindexWarning: boolean;
  digitalDisplayMaximumImpressionsPerDay: number;
  appInsights: AppInsights;
  google: GoogleTagManager;
  crmEnabled: boolean;
  budgetOrBreakSelectionChoiceEnabled: boolean;
  nmoSwitchDate: string;
};
export type ClaimValue = {
  type?: string | null;
  description?: string | null;
};
export type SettingsGetResponse = {
  settings: PortalSettings;
  claims?: ClaimValue[] | null;
};
export type EditableSetting = {
  name?: string | null;
  alias?: string | null;
  type?: SettingDataType;
  value?: any | null;
};
export type SpotResultGrpOutput = {
  targetGroup?: string | null;
  displayName?: string | null;
  grps?: number;
  blockAvarage?: number | null;
};
export type SpotResultOutput = {
  advertiserName: string;
  channel: string;
  dateOfBroadcast: string;
  duration: number;
  productName: string;
  breakId: string;
  commercialCode: string;
  commercialTitle: string;
  targetGroups: SpotResultGrpOutput[];
  programBefore: string;
  programAfter: string;
};
export type SpotTrackerRequestInput = {
  period?: AnalysisPeriod;
  targetGroupsTv?: AnalysisTargetGroupTv[] | null;
  targetGroupsRadio?: AnalysisTargetGroupRadio[] | null;
  productId?: number[] | null;
};
export type TermsAndConditionsResult = {
  id: number;
  userId: string;
  dateOfAgreement: string;
  dateOfTerms: string;
  canUseForMailing?: boolean;
  canUseForMarketing?: boolean;
};
export type TermsAndConditionsModel = {
  dateOfTerms: string;
  canUseForMailing?: boolean;
  canUseForMarketing?: boolean;
};
export type TipDto = {
  alias: string;
  title: string;
  description: string;
  cssClassName: string;
  sortOrder: number;
  imageUrl?: string | null;
  startDate?: string | null;
  endDate?: string | null;
};
export enum TenantEnum {
  National = "National",
  Regional = "Regional",
}
export enum MediumEnum {
  Tv = "tv",
  Radio = "radio",
  Inter = "inter",
}
export enum VolumeDiscount {
  VolumeDiscount0 = "VolumeDiscount0",
  VolumeDiscount2 = "VolumeDiscount2",
  VolumeDiscount3 = "VolumeDiscount3",
  VolumeDiscount4 = "VolumeDiscount4",
  VolumeDiscount5 = "VolumeDiscount5",
}
export enum BookSpotAction {
  Book = "Book",
  Cancel = "Cancel",
}
export enum RuleType {
  Discount = "Discount",
  Surtax = "Surtax",
}
export enum PackageType {
  RunOnSite = "RunOnSite",
  RunOnNetwork = "RunOnNetwork",
  HouseDisplay = "HouseDisplay",
  TourDeFrance = "TourDeFrance",
  Top2000 = "Top2000",
  AlgemeenNut = "AlgemeenNut",
  NpoPromo = "NpoPromo",
  Specifiek = "Specifiek",
  Seniors = "Seniors",
  Combi50Plus = "Combi50Plus",
  FunXDisplay = "FunXDisplay",
  SpecifiekCultuur = "SpecifiekCultuur",
  SeriousRequest = "SeriousRequest",
  Spits = "Spits",
}
export enum PackageChoiceEnum {
  FixedCosts = "FixedCosts",
  Grps = "Grps",
  Display = "Display",
  Video = "Video",
  NpoPromo = "NpoPromo",
  FixedCostsCult = "FixedCostsCult",
  None = "None",
}
export enum OnlinePackageChoice {
  Display = "Display",
  Video = "Video",
}
export enum OrderStatus {
  Concept = "Concept",
  Submitted = "Submitted",
  Planned = "Planned",
  Active = "Active",
  Ready = "Ready",
}
export enum RunningState {
  Unkown = "Unkown",
  Forecast = "Forecast",
  Combination = "Combination",
  Realization = "Realization",
}
export enum AuditActionType {
  RequestCreate = "RequestCreate",
  RequestUpdate = "RequestUpdate",
  RequestDelete = "RequestDelete",
  RequestFinalize = "RequestFinalize",
  OrderUpdate = "OrderUpdate",
}
export enum UpdateFields {
  SpotLength = "SpotLength",
  Impressions = "Impressions",
  Budget = "Budget",
  EndDate = "EndDate",
  StartDate = "StartDate",
  Package = "Package",
}
export enum CommercialStatus {
  Active = "Active",
  Inactive = "Inactive",
  Offline = "Offline",
  Nieuw = "Nieuw",
}
export enum ForecastCoverageEnum {
  Coverage1 = "Coverage1",
  Coverage2 = "Coverage2",
  Coverage3 = "Coverage3",
  Coverage4 = "Coverage4",
  Coverage5 = "Coverage5",
  Coverage6 = "Coverage6",
  Coverage7 = "Coverage7",
  Coverage8 = "Coverage8",
  Coverage9 = "Coverage9",
  Coverage10 = "Coverage10",
}
export enum CommercialInstructionStatus {
  Concept = "Concept",
  InBehandeling = "InBehandeling",
  Behandeld = "Behandeld",
  Incorrect = "Incorrect",
}
export enum CommercialInstructionAlternationType {
  Percentage = "Percentage",
  Channel = "Channel",
}
export enum ExportFileType {
  Csv = "Csv",
  Pdf = "Pdf",
  Xlsx = "Xlsx",
}
export enum CampaignForwardedStatus {
  Created = "Created",
  Approved = "Approved",
  Rejected = "Rejected",
}
export enum MediaFileType {
  Audio = "Audio",
  Video = "Video",
}
export enum Status {
  Success = "Success",
  Failed = "Failed",
}
export enum CommercialUploadStatus {
  WaitForReview = "WaitForReview",
  Approved = "Approved",
  Rejected = "Rejected",
}
export enum InvoiceStatus {
  Factureerbaar = "Factureerbaar",
  Gefactureerd = "Gefactureerd",
  DeelsBetaald = "DeelsBetaald",
  Betaald = "Betaald",
}
export enum ProposalType {
  Flights = "Flights",
  FlightsWithSum = "FlightsWithSum",
  Scenarios = "Scenarios",
}
export enum SettingDataType {
  String = "String",
  Int = "Int",
  Bool = "Bool",
  Datetime = "Datetime",
}
export const {
  useGetApiV1AccountMeQuery,
  useLazyGetApiV1AccountMeQuery,
  useGetApiV1AccountOrganisationsQuery,
  useLazyGetApiV1AccountOrganisationsQuery,
  useGetApiV1AccountAdvertisersQuery,
  useLazyGetApiV1AccountAdvertisersQuery,
  usePostApiV1AccountDeleteByUserIdMutation,
  useDeleteApiV1AccountDeleteByUserIdMutation,
  useDeleteApiV1AccountDeactivateMutation,
  usePostApiV1AccountActivateByUserIdMutation,
  usePostApiV1AccountUpdateByUserIdMutation,
  usePostApiV1AccountRegisterMutation,
  useGetApiV1AccountAllQuery,
  useLazyGetApiV1AccountAllQuery,
  useGetApiV1AccountByUserIdQuery,
  useLazyGetApiV1AccountByUserIdQuery,
  useGetApiV1AccountExportQuery,
  useLazyGetApiV1AccountExportQuery,
  useGetApiV1AnnouncementsQuery,
  useLazyGetApiV1AnnouncementsQuery,
  usePostApiV1AnnouncementsMutation,
  useGetApiV1AnnouncementsAllQuery,
  useLazyGetApiV1AnnouncementsAllQuery,
  useDeleteApiV1AnnouncementsByIdMutation,
  usePostApiV1AssetManagementWebHookUpdateMutation,
  usePostApiV1BookSpotMutation,
  useGetApiV1CacheListQuery,
  useLazyGetApiV1CacheListQuery,
  useGetApiV1CacheClearQuery,
  useLazyGetApiV1CacheClearQuery,
  useGetApiV1CampaignCodeByCampaignCodeQuery,
  useLazyGetApiV1CampaignCodeByCampaignCodeQuery,
  usePostApiV1CampaignCodeMutation,
  useGetApiV1CampaignEndreportByMediumAndPackageChoiceOrderIdFileTypeQuery,
  useLazyGetApiV1CampaignEndreportByMediumAndPackageChoiceOrderIdFileTypeQuery,
  useGetApiV1CampaignGetordersQuery,
  useLazyGetApiV1CampaignGetordersQuery,
  useGetApiV1CampaignGetorderQuery,
  useLazyGetApiV1CampaignGetorderQuery,
  useGetApiV1CampaignGetorderwithspotsQuery,
  useLazyGetApiV1CampaignGetorderwithspotsQuery,
  useGetApiV1CampaignGeteditablecampaignQuery,
  useLazyGetApiV1CampaignGeteditablecampaignQuery,
  useGetApiV1CampaignGetspotsbyorderQuery,
  useLazyGetApiV1CampaignGetspotsbyorderQuery,
  useGetApiV1CampaignGetspotsQuery,
  useLazyGetApiV1CampaignGetspotsQuery,
  usePostApiV1CampaignExportMutation,
  usePostApiV1CampaignUpdateMutation,
  useGetApiV1CampaignAnalysisRelatedByMediumAndOrderIdQuery,
  useLazyGetApiV1CampaignAnalysisRelatedByMediumAndOrderIdQuery,
  usePostApiV1CampaignAnalysisAnalysisGenericMutation,
  usePostApiV1CampaignAnalysisAnalysisMutation,
  useGetApiV1CampaignAnalysisAnalysistargetgroupsQuery,
  useLazyGetApiV1CampaignAnalysisAnalysistargetgroupsQuery,
  usePostApiV1CampaignAnalysisUploadFileMutation,
  useGetApiV1CampaignAnalysisHasTvCampaignOnNmoSwitchQuery,
  useLazyGetApiV1CampaignAnalysisHasTvCampaignOnNmoSwitchQuery,
  usePostApiV1CampaignForwardByInitialRequestIdMutation,
  useGetApiV1CampaignForwardContactsQuery,
  useLazyGetApiV1CampaignForwardContactsQuery,
  useGetApiV1CampaignForwardProductsByMediumQuery,
  useLazyGetApiV1CampaignForwardProductsByMediumQuery,
  useGetApiV1CampaignForwardAvailableQuery,
  useLazyGetApiV1CampaignForwardAvailableQuery,
  usePostApiV1CampaignForwardAcceptMutation,
  usePostApiV1CampaignForwardRejectMutation,
  useGetApiV1CampaignForwardDetailsByIdQuery,
  useLazyGetApiV1CampaignForwardDetailsByIdQuery,
  useGetApiV1CommercialInstructionsQuery,
  useLazyGetApiV1CommercialInstructionsQuery,
  usePostApiV1CommercialInstructionsMutation,
  useGetApiV1CommercialInstructionsOnlineByOrderIdQuery,
  useLazyGetApiV1CommercialInstructionsOnlineByOrderIdQuery,
  usePostApiV1CommercialInstructionsSaveOnlineByOrderIdMutation,
  usePostApiV1CommercialInstructionsMultiMutation,
  useDeleteApiV1CommercialInstructionsDeleteByInstructionIdMutation,
  useGetApiV1CommercialsByProductIdQuery,
  useLazyGetApiV1CommercialsByProductIdQuery,
  useGetApiV1CommercialsQuery,
  useLazyGetApiV1CommercialsQuery,
  useGetApiV1CommercialsByMediaIdInitQuery,
  useLazyGetApiV1CommercialsByMediaIdInitQuery,
  useGetApiV1CommercialsThumbnailsByCommercialCodeAndThumbnailIndexQuery,
  useLazyGetApiV1CommercialsThumbnailsByCommercialCodeAndThumbnailIndexQuery,
  useGetApiV1CommercialsByTypeAndCommercialCodeQuery,
  useLazyGetApiV1CommercialsByTypeAndCommercialCodeQuery,
  usePostApiV1CommercialsUploadMutation,
  useGetApiV1CommercialsAuditQuery,
  useLazyGetApiV1CommercialsAuditQuery,
  usePostApiV1CommercialsAuditMutation,
  usePostApiV1CommercialsReactivateMutation,
  useGetApiV1CustomerAccountsQuery,
  useLazyGetApiV1CustomerAccountsQuery,
  useGetApiV1CustomerContactsByAccountIdQuery,
  useLazyGetApiV1CustomerContactsByAccountIdQuery,
  useGetApiV1CustomerContactsByAdvertiserIdQuery,
  useLazyGetApiV1CustomerContactsByAdvertiserIdQuery,
  useGetApiV1CustomerOpportunitiesByAccountIdQuery,
  useLazyGetApiV1CustomerOpportunitiesByAccountIdQuery,
  useGetApiV1CustomerOpportunitiesByAdvertiserIdQuery,
  useLazyGetApiV1CustomerOpportunitiesByAdvertiserIdQuery,
  useGetApiV1DocumentQuery,
  useLazyGetApiV1DocumentQuery,
  usePostApiV1DocumentMutation,
  useGetApiV1DocumentByGuidQuery,
  useLazyGetApiV1DocumentByGuidQuery,
  usePostApiV1DocumentDeleteByGuidMutation,
  useGetByTypeAndIdExtensionQuery,
  useLazyGetByTypeAndIdExtensionQuery,
  useGetByTypeAndIdSubIdExtensionQuery,
  useLazyGetByTypeAndIdSubIdExtensionQuery,
  usePostApiV1ForecastRadioV2Mutation,
  usePostApiV1ForecastRadioMutation,
  usePostApiV1ForecastTvV2Mutation,
  usePostApiV1ForecastTvMutation,
  useGetApiV1ForecastConversiongroupsQuery,
  useLazyGetApiV1ForecastConversiongroupsQuery,
  useGetApiV1GenericSecondaryTargetGroupsQuery,
  useLazyGetApiV1GenericSecondaryTargetGroupsQuery,
  useGetApiV1GenericGetsalesperiodQuery,
  useLazyGetApiV1GenericGetsalesperiodQuery,
  useGetApiV1GenericGetproductsQuery,
  useLazyGetApiV1GenericGetproductsQuery,
  useGetApiV1GenericDigitalContextQuery,
  useLazyGetApiV1GenericDigitalContextQuery,
  useGetApiV1GenericDigitalTargetgroupsQuery,
  useLazyGetApiV1GenericDigitalTargetgroupsQuery,
  useGetApiV1InvoiceQuery,
  useLazyGetApiV1InvoiceQuery,
  useGetApiV1PlanningByInitialRequestIdQuery,
  useLazyGetApiV1PlanningByInitialRequestIdQuery,
  usePostApiV1PlanningSaveMutation,
  useGetApiV1PlanningBookingdateQuery,
  useLazyGetApiV1PlanningBookingdateQuery,
  usePostApiV1PlanningFinalizeMutation,
  usePostApiV1PlanningDeleteByInitialRequestIdMutation,
  usePostApiV1PlanningValidateMutation,
  usePostApiV1PlanningCopyMutation,
  useGetApiV1ProductTourQuery,
  useLazyGetApiV1ProductTourQuery,
  usePostApiV1ProductTourMutation,
  usePostApiV1ProposalMutation,
  usePostApiV1ProposalGenerateProposalMutation,
  usePostApiV1ProposalGetProposalDataMutation,
  usePostApiV1ProposalUploadForecastMutation,
  useGetApiV1ProposalTestByYearQuery,
  useLazyGetApiV1ProposalTestByYearQuery,
  useGetApiV1RatecardSpotindexesQuery,
  useLazyGetApiV1RatecardSpotindexesQuery,
  useGetApiV1RequestGetpackagesQuery,
  useLazyGetApiV1RequestGetpackagesQuery,
  useGetApiV1RequestGetpackagesGroupedRadioByYearQuery,
  useLazyGetApiV1RequestGetpackagesGroupedRadioByYearQuery,
  useGetApiV1RequestGetpackagesGroupedTvByYearQuery,
  useLazyGetApiV1RequestGetpackagesGroupedTvByYearQuery,
  usePostApiV1RequestGetavailablespotsMutation,
  usePostApiV1RequestCalculateMutation,
  useGetApiV1RequestDigitalProgramsQuery,
  useLazyGetApiV1RequestDigitalProgramsQuery,
  useGetApiV1RequestDigitalSitesQuery,
  useLazyGetApiV1RequestDigitalSitesQuery,
  useGetApiV1SettingsQuery,
  useLazyGetApiV1SettingsQuery,
  usePutApiV1SettingsMutation,
  useGetApiV1SettingsEditableQuery,
  useLazyGetApiV1SettingsEditableQuery,
  usePostApiV1SpotTrackerMutation,
  usePostApiV1SpotTrackerExportMutation,
  useGetApiV1TermsQuery,
  useLazyGetApiV1TermsQuery,
  usePostApiV1TermsMutation,
  useGetApiV1TipsQuery,
  useLazyGetApiV1TipsQuery,
} = injectedRtkApi;
