import { Action } from "redux";
import { Reducer, createReducer } from "typesafe-actions";

import { TermsAndConditionsResult } from "../../api/models";
import { ReduxStoreState } from "../base";
import { LoadingTermsAndConditionsResult } from "../models";
import { postTermsAction, receiveTermsAction } from "./actions";

// eslint-disable-next-line import/prefer-default-export
export const termsReducer: Reducer<LoadingTermsAndConditionsResult, Action> =
  createReducer({
    state: ReduxStoreState.Initial,
  })
    .handleAction(
      receiveTermsAction.request,
      (state: TermsAndConditionsResult) => ({
        ...state,
        loading: true,
        state: ReduxStoreState.Loading,
      })
    )
    .handleAction(
      postTermsAction.request,
      (state: TermsAndConditionsResult) => ({
        ...state,
        loading: true,
        state: ReduxStoreState.Loading,
      })
    )
    .handleAction(
      receiveTermsAction.failure,
      (state: TermsAndConditionsResult) => ({
        ...state,
        loading: false,
        state: ReduxStoreState.Failure,
      })
    )
    .handleAction(
      postTermsAction.failure,
      (state: TermsAndConditionsResult) => ({
        ...state,
        loading: false,
        state: ReduxStoreState.Failure,
      })
    )
    .handleAction(
      receiveTermsAction.success,
      (
        state: TermsAndConditionsResult,
        action: ReturnType<typeof receiveTermsAction.success>
      ) => ({
        ...action.payload,
        loading: false,
        state: ReduxStoreState.Success,
      })
    )
    .handleAction(
      postTermsAction.success,
      (
        state: TermsAndConditionsResult,
        action: ReturnType<typeof receiveTermsAction.success>
      ) => ({
        ...action.payload,
        loading: false,
        state: ReduxStoreState.Success,
      })
    );
