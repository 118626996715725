import { configureStore } from "@reduxjs/toolkit";
import { UnknownAction } from "redux";
import createSagaMiddleware from "redux-saga";

import { logoutMeAction } from "./account/actions";
import { Loading, ReduxStoreState } from "./base";
import { coreApi } from "./coreApi";
import errorMiddleware from "./errorMiddleware";
import { InvalidatePayload, StoreModel } from "./models";
import appReducer from "./reducers";
import rootSagas from "./sagas";

const rootReducer = (
  state: StoreModel | undefined,
  action: UnknownAction
): ReturnType<typeof appReducer> => {
  if (action.type === logoutMeAction.request().type) {
    // reset the store to its initial state
    return appReducer(undefined, action);
  }

  const store = appReducer(state, action);
  if (
    action.type === "INVALIDATE" &&
    (action.payload as InvalidatePayload).propertyName
  ) {
    /* Als er een `INVALIDATE` actie voorkomt met een `propertyName`,
     * zal de betreffende property op `Initial` gezet worden als deze niet op `Succes` staat. */
    const key = (action.payload as InvalidatePayload)
      .propertyName as keyof StoreModel;
    if ((store[key] as Loading).state !== ReduxStoreState.Success) {
      return {
        ...store,
        [key]: {
          loading: false,
          state: ReduxStoreState.Initial,
        } as Loading,
      };
    }
  }

  return store;
};

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  // Add the RTK Query API middleware
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(coreApi.middleware)
      .concat(sagaMiddleware)
      .concat(errorMiddleware),
});

sagaMiddleware.run(rootSagas);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type
export type AppDispatch = typeof store.dispatch;

export default store;
