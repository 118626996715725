/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ExternalMaterialMetadata
 */
export interface ExternalMaterialMetadata {
  /**
   *
   * @type {number}
   * @memberof ExternalMaterialMetadata
   */
  commercialLength: number;
  /**
   *
   * @type {number}
   * @memberof ExternalMaterialMetadata
   */
  technicalLength: number;
}

export function ExternalMaterialMetadataFromJSON(
  json: any
): ExternalMaterialMetadata {
  return ExternalMaterialMetadataFromJSONTyped(json, false);
}

export function ExternalMaterialMetadataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ExternalMaterialMetadata {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    commercialLength: json["commercialLength"],
    technicalLength: json["technicalLength"],
  };
}

export function ExternalMaterialMetadataToJSON(
  value?: ExternalMaterialMetadata | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    commercialLength: value.commercialLength,
    technicalLength: value.technicalLength,
  };
}
