import "./SpotAnalysis.less";

import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  Container,
  ContentContainer,
  Form,
  Icons,
  Pageheader,
  Row,
  Spinner,
  Typography,
} from "@ster/ster-toolkit";
import { Alert, Form as AntForm, Col, Space } from "antd";
import {
  endOfMonth,
  isAfter,
  isBefore,
  startOfDay,
  startOfMonth,
  subDays,
  subMonths,
} from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";

import {
  AnalysisTargetGroupRadio,
  AnalysisTargetGroupTV,
  DateSpan,
  MediumEnum,
} from "../../api";
import { ReduxStoreState } from "../../store/base";
import {
  clearCampaignAnalysisAction,
  receiveCampaignAnalysisTargetGroupsAction,
  requestCampaignAnalysisGenericAction,
} from "../../store/campaignDetail/actions";
import { StoreModel } from "../../store/models";
import CampaignAnalysisTargetGroups from "../partials/Cards/CampaignDetail/CampaignAnalysisTargetGroups";
import { formItemLayout } from "../partials/Form";
import ProductsPartialForm, {
  ProductsPartialFormData,
} from "../partials/Products/ProductsPartialForm";

const campaignAnalysisMediumTypes = [MediumEnum.Tv, MediumEnum.Radio];

const initialTargetGroups = [
  {
    age: { min: 25, max: 67 },
    gender: "X",
  },
];

type CampaignAnalysisFormData = ProductsPartialFormData & {
  targetGroups: Array<AnalysisTargetGroupTV | AnalysisTargetGroupRadio>;
};

const CampaignAnalysis = () => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();

  /**
   * Initially select the previous month, taking into account that
   * the most recent day for an analysis is 7 days ago.
   */
  const previousMonth = useMemo<DateSpan>(() => {
    const mostRecent = startOfDay(subDays(new Date(), 7));
    return {
      from: startOfMonth(subMonths(mostRecent, 1)),
      to: endOfMonth(subMonths(mostRecent, 1)),
    };
  }, []);

  const [form] = AntForm.useForm<CampaignAnalysisFormData>();
  const medium = AntForm.useWatch("medium", form);

  const isDateDisabled = useCallback(
    (currentDate: Date): boolean =>
      // After 1 January 2020
      isBefore(currentDate, new Date(2020, 0, 1)) ||
      // The most recent day, should be 7 days ago
      isAfter(currentDate, startOfDay(subDays(new Date(), 7))),
    []
  );

  const campaignAnalysisTargetGroups = useSelector(
    (store: StoreModel) => store.campaignAnalysisTargetGroups
  );

  useEffect(() => {
    if (campaignAnalysisTargetGroups.state !== ReduxStoreState.Initial) {
      return;
    }

    dispatch(receiveCampaignAnalysisTargetGroupsAction.request());
  }, [campaignAnalysisTargetGroups.state, dispatch]);

  const onFinish = useCallback(
    (values: CampaignAnalysisFormData) => {
      if (!values.medium) {
        return;
      }

      dispatch(
        requestCampaignAnalysisGenericAction.request({
          medium: values.medium,
          analysisInput: {
            period: {
              start: values.period[0],
              end: values.period[1],
            },
            productId: values.productId,
            secondaryTargetGroups: {
              tv: values.medium === MediumEnum.Tv ? values.targetGroups : [],
              radio:
                values.medium === MediumEnum.Radio ? values.targetGroups : [],
            },
          },
        })
      );
    },
    [dispatch]
  );

  const { loading = false, state: loadingState } = useSelector(
    (store: StoreModel) => store.campaignAnalysisGeneric
  );

  const [formState, setFormState] = useState<"success" | "failure" | "initial">(
    "initial"
  );

  const resetForm = useCallback(() => {
    form.resetFields();
    setFormState("initial");
    dispatch(clearCampaignAnalysisAction());
  }, [dispatch, form]);

  useEffect(() => {
    switch (loadingState) {
      case ReduxStoreState.Success:
        setFormState("success");
        break;
      case ReduxStoreState.Failure:
        setFormState("failure");
        break;
      default:
        break;
    }
  }, [dispatch, i18n, loadingState]);

  return (
    <>
      <Helmet>
        <title>{i18n._(t`Campagneanalyse`)}</title>
      </Helmet>

      <Pageheader
        title={<Trans>Campagneanalyse</Trans>}
        icon={
          <Icons.CampaignIcon
            width="100%"
            height="100%"
            fill="rgba(129, 176, 210, 0.2)"
          />
        }
      />

      <Spinner spinning={loading}>
        <ContentContainer className="content">
          <Container>
            {!loading && formState === "success" ? (
              <>
                <Typography.Paragraph>
                  <Trans>
                    Je aanvraag voor een analyse is ontvangen. Je ontvangt een
                    e-mail zodra deze analyse klaar staat in de Klantportal.
                  </Trans>
                </Typography.Paragraph>
                <Typography.Paragraph>
                  <Button mode="primary" onClick={resetForm}>
                    <Trans>Nieuwe analyse aanvragen?</Trans>
                  </Button>
                </Typography.Paragraph>
              </>
            ) : (
              <>
                <Typography.Paragraph>
                  <Trans>
                    Met onze campagneanalyse krijg je inzicht in de
                    bereikcijfers van jouw campagne. Selecteer je gewenste
                    doelgroep en periode, en de analyse wordt uitgedraaid. Kom
                    je er niet uit of heb je vragen over de analyse? Wij staan
                    voor je klaar! Neem contact op met je accountmanager of met
                    onze planners via de chatfunctie in de Ster Klantportal.
                  </Trans>
                </Typography.Paragraph>

                <Row>
                  <Col xl={14} lg={18} md={24}>
                    <Space
                      direction="vertical"
                      style={{ width: "100%" }}
                      size="large"
                    >
                      {!loading && formState === "failure" && (
                        <Alert
                          showIcon
                          type="error"
                          message=""
                          description={i18n._(
                            t`Er ging iets mis bij het aanvragen van de analyse.`
                          )}
                        />
                      )}

                      <Form<CampaignAnalysisFormData>
                        name="camp"
                        form={form}
                        initialValues={{
                          medium: MediumEnum.Tv,
                          period: [previousMonth.from, previousMonth.to],
                          targetGroups: initialTargetGroups,
                        }}
                        {...formItemLayout}
                        onFinish={onFinish}
                      >
                        <ProductsPartialForm
                          form={form}
                          mediumTypes={campaignAnalysisMediumTypes}
                          disabledDate={isDateDisabled}
                        />
                        <Form.Item<CampaignAnalysisFormData>
                          label={i18n._(t`Doelgroepen`)}
                          name="targetGroups"
                          rules={[
                            {
                              required: true,
                              message: i18n._(t`Selecteer een doelgroep`),
                            },
                          ]}
                          helpText={
                            <Trans>
                              Hier kun je aangeven voor welke doelgroep(en) je,
                              naast de standaard inkoopdoelgroep 25-67 jaar, een
                              campagneanalyse zou willen ontvangen.
                            </Trans>
                          }
                        >
                          <CampaignAnalysisTargetGroups
                            targetGroups={
                              medium === MediumEnum.Tv
                                ? campaignAnalysisTargetGroups?.tv
                                : campaignAnalysisTargetGroups?.radio
                            }
                            asFormField
                          />
                        </Form.Item>
                        <Row justify="end">
                          <Col>
                            <Form.Item shouldUpdate>
                              {({ isFieldsTouched }) => (
                                <Button
                                  mode="primary"
                                  htmlType="submit"
                                  disabled={!isFieldsTouched(false) || loading}
                                  loading={loading}
                                >
                                  {i18n._(t`Genereer campagneanalyse`)}
                                </Button>
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </Space>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </ContentContainer>
      </Spinner>
    </>
  );
};

export default CampaignAnalysis;
