import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { ContentContainer, Icons, Pageheader, Row } from "@ster/ster-toolkit";
import { Col, Space } from "antd";
import { memo } from "react";
import { Helmet } from "react-helmet-async";

import {
  useGetApiV1AccountMeQuery,
  useGetApiV1CampaignGetspotsQuery,
  useGetApiV1GenericGetsalesperiodQuery,
  useGetApiV1SettingsQuery,
  useGetApiV1TipsQuery,
} from "../../store/coreApi";
import Notification from "../Notification";
import CurrentSpots from "./CurrentSpots";
import styles from "./DashboardContainer.module.less";
import Rates from "./Rates";
import SalesPeriods from "./SalesPeriods";
import TipSlider from "./TipSlider";

const DashboardContainer = memo(() => {
  const { i18n } = useLingui();

  const { data: salesPeriods = [], isLoading: isSalesPeriodsLoading } =
    useGetApiV1GenericGetsalesperiodQuery();
  const { data: settings } = useGetApiV1SettingsQuery();
  const { data: spotsByMedium, isLoading: isCurrentSpotsLoading } =
    useGetApiV1CampaignGetspotsQuery();
  const { data: tips } = useGetApiV1TipsQuery();
  const { data: account } = useGetApiV1AccountMeQuery();

  return (
    <>
      <Helmet>
        <title>{i18n._(t`Welkom`)}</title>
      </Helmet>

      <Pageheader
        title={<Trans>Welkom</Trans>}
        subTitle={account?.sterInlog?.firstName}
        icon={
          <Icons.StatsIcon
            width="100%"
            height="100%"
            fill="rgba(129, 176, 210, 0.2)"
          />
        }
      />

      <ContentContainer className="content">
        <Notification />
        <Row gutter={[40, 20]}>
          <Col md={24} lg={17}>
            <Space direction="vertical" className={styles.maxWidth}>
              {spotsByMedium &&
                account?.claims.some((value) =>
                  ["campagnes_radio", "campagnes_tv"].includes(value)
                ) && (
                  <CurrentSpots
                    spotsByMedium={spotsByMedium}
                    loading={isCurrentSpotsLoading}
                  />
                )}
              <SalesPeriods
                salesPeriods={salesPeriods}
                loading={isSalesPeriodsLoading}
                maximum={settings?.settings?.maxSalesperiodsOnDashboard ?? 5}
              />
            </Space>
          </Col>
          <Col md={24} lg={7}>
            <TipSlider tips={tips} />
            <Space direction="vertical" className={styles.maxWidth}>
              <Rates />
            </Space>
          </Col>
        </Row>
      </ContentContainer>
    </>
  );
});

export default DashboardContainer;
