import { saveAs } from "file-saver";
import moment from "moment";
import { Action } from "redux";
import { Reducer, createReducer } from "typesafe-actions";

import { PortalUser } from "../../api/models";
import { ReduxStoreState } from "../base";
import {
  LoadingPortalUser,
  LoadingPortalUsers,
  LoadingUsersExport,
} from "../models";
import {
  clearUserAction,
  receiveUserAction,
  receiveUsersAction,
  receiveUsersExportAction,
} from "./actions";

const emptyUsers: PortalUser[] = [];

export const usersReducer: Reducer<LoadingPortalUsers, Action> = createReducer({
  state: ReduxStoreState.Initial,
})
  .handleAction(receiveUsersAction.request, (state: LoadingPortalUsers) => ({
    ...state,
    loading: true,
    state: ReduxStoreState.Loading,
  }))
  .handleAction(receiveUsersAction.failure, (state: LoadingPortalUsers) => ({
    ...state,
    loading: false,
    state: ReduxStoreState.Failure,
  }))
  .handleAction(
    receiveUsersAction.success,
    (
      state: LoadingPortalUsers,
      action: ReturnType<typeof receiveUsersAction.success>
    ) => ({
      users: action.payload || emptyUsers,
      loading: false,
      state: ReduxStoreState.Success,
    })
  );

export const userReducer: Reducer<LoadingPortalUser, Action> = createReducer({
  state: ReduxStoreState.Initial,
})
  .handleAction(receiveUserAction.request, (state: LoadingPortalUser) => ({
    ...state,
    loading: true,
    state: ReduxStoreState.Loading,
  }))
  .handleAction(receiveUserAction.failure, (state: LoadingPortalUser) => ({
    ...state,
    loading: false,
    state: ReduxStoreState.Failure,
  }))
  .handleAction(
    receiveUserAction.success,
    (
      state: LoadingPortalUser,
      action: ReturnType<typeof receiveUserAction.success>
    ) => ({
      ...action.payload,
      loading: false,
      state: ReduxStoreState.Success,
    })
  )
  .handleAction(clearUserAction.type, () => ({
    loading: false,
    state: ReduxStoreState.Initial,
  }));

export const usersExportReducer: Reducer<LoadingUsersExport, Action> =
  createReducer({
    state: ReduxStoreState.Initial,
  })
    .handleAction(receiveUsersExportAction.request, () => ({
      loading: true,
      state: ReduxStoreState.Loading,
    }))
    .handleAction(receiveUsersExportAction.failure, () => ({
      loading: false,
      state: ReduxStoreState.Failure,
    }))
    .handleAction(
      receiveUsersExportAction.success,
      (
        state: LoadingUsersExport,
        action: ReturnType<typeof receiveUsersExportAction.success>
      ) => {
        const fileContents = action.payload as Blob;
        const filename = `ster-klantportal-users-${moment().format(
          "YYYYMMDDTHHmmss"
        )}.xlsx`;
        saveAs(fileContents, filename);

        return {
          loading: false,
          state: ReduxStoreState.Success,
        };
      }
    );
