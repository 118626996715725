import { Trans } from "@lingui/macro";
import { Button, Row, Typography } from "@ster/ster-toolkit";
import { Col } from "antd";
import { memo, useCallback, useMemo, useState } from "react";

import { Banner, Commercial, Material, MediumEnum } from "../../api/models";
import { CommercialType } from "../../shared/models";
import MaterialCard from "../partials/Cards/Material/MaterialCard";
import MaterialCardBanner from "../partials/Cards/Material/MaterialCardBanner";
import AudioPlayer from "./AudioPlayer";
import { CommercialsProps } from "./models";
import VideoPlayer from "./VideoPlayer";

export const getMaterialPlayer = (material: Material) => {
  switch (material.identifier.medium) {
    case MediumEnum.Radio:
      return <AudioPlayer material={material} />;
    default:
      return <VideoPlayer material={material} />;
  }
};

export const getCommercialPlayer = (commercial: Commercial) =>
  getMaterialPlayer(commercial.material);

const Commercials = memo(
  ({
    byAdvertiser: { advertiserId, advertiserName, commercials, banners },
    product,
  }: CommercialsProps) => {
    const [showAll, updateShowAll] = useState(false);
    const toggleShowAll = useCallback(() => {
      updateShowAll(!showAll);
    }, [showAll]);

    const filtered = useMemo(
      () =>
        [
          ...commercials
            .filter((c) => product === 0 || c.productId === product)
            .map((s) => ({ ...s, type: CommercialType.Video })),
          ...banners.map((s) => ({ ...s, type: CommercialType.Banner })),
        ].sort(
          (a, b) =>
            (b.creationDate?.getTime() ?? 0) - (a.creationDate?.getTime() ?? 0)
        ),
      [banners, commercials, product]
    );

    return (
      <Row gutter={[40, 40]} key={advertiserId}>
        <Col span={24}>
          <Typography.Title level={2}>{advertiserName}</Typography.Title>
          <Row gutter={[40, 40]}>
            {filtered
              .slice(0, showAll ? filtered.length : 3)
              .map((commercial) => (
                <Col
                  key={`${commercial.medium}-${commercial.id}`}
                  lg={8}
                  sm={12}
                  xs={24}
                >
                  {commercial.type === CommercialType.Video ? (
                    <MaterialCard
                      advertiserId={advertiserId}
                      commercial={commercial as Commercial}
                    />
                  ) : (
                    <MaterialCardBanner cardData={commercial as Banner} />
                  )}
                </Col>
              ))}
          </Row>
          {filtered.length > 3 && (
            <Button mode="tertiary" onClick={toggleShowAll}>
              {showAll ? (
                <Trans>Minder ({filtered.length})</Trans>
              ) : (
                <Trans>Alles ({filtered.length})</Trans>
              )}
            </Button>
          )}
        </Col>
      </Row>
    );
  }
);

export default Commercials;
