import { useEffect, useMemo, useRef } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { MediumEnum } from "../api/models";
import { AppDispatch, RootState } from "../store";
import { StoreModel } from "../store/models";

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

const allMediumTypes: MediumEnum[] = [
  MediumEnum.Tv,
  MediumEnum.Radio,
  MediumEnum.Inter,
];
const emptyArray: MediumEnum[] = [];
export type ClaimPrefix =
  | "aanvragen_formulier"
  | "campagnes"
  | "facturen"
  | "instructions"
  | "material"
  | "materialinzien";

/**
 * React hook to get all available Medium Types based on a given claim-prefix.
 *
 * Example: A user has 2 claims with the `campagnes` prefix: `campagnes_tv` and
 * `campagnes_inter`. This hook will return a tuple with this value:
 *
 * `[[MediumEnum.tv, MediumEnum.inter], MediumEnum.tv]`
 *
 * This hook can only be used from inside a React component and the `account`
 * property in the Redux-store has to be loaded from the API.
 *
 * @returns A tuple with an array of available `MediumEnum` objects and the first `MediumEnum`
 */
export const useAvailableMediumTypes = (
  claimPrefix: ClaimPrefix
): [MediumEnum[], MediumEnum | undefined] => {
  const account = useSelector((state: StoreModel) => state.account);
  return useMemo(() => {
    const prefix = `${claimPrefix}_`;
    const available = (account.claims ?? [])
      .filter((c) => c.startsWith(prefix))
      .map((c) => c.replace(prefix, "") as MediumEnum);
    if (!available || available.length === 0) {
      return [emptyArray, undefined];
    }

    const sorted = allMediumTypes.filter((t) => available.includes(t));
    return [sorted, sorted[0]];
  }, [account.claims, claimPrefix]);
};

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
