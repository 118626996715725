import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type ErrorInputState = {
  endpointName: string;
};

export type ErrorState = {
  id: number;
} & ErrorInputState;

const initialState: ErrorState[] = [];

const errorSlice = createSlice({
  name: "errors",
  initialState,
  reducers: {
    addError: (state, action: PayloadAction<ErrorInputState>) => {
      const newId = Math.max(...state.map((error) => error.id), 0) + 1;
      return [...state, { id: newId, ...action.payload }];
    },

    removeError: (state, action: PayloadAction<number>) =>
      state.filter((error) => error.id !== action.payload),
  },
});

// Action creators are generated for each case reducer function
export const { addError, removeError } = errorSlice.actions;

export default errorSlice.reducer;
