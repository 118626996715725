import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import { MaterialApi } from "../../api/apis";
import apiConfig from "../../apiConfig";
import { toError } from "../utils";
import {
  linkExternalMaterialWithProductIdAction,
  receiveExternalMaterialMetadataAction,
  receiveExternalMaterialsAction,
} from "./actions";

export function* receiveExternalMaterials(
  _action: ReturnType<typeof receiveExternalMaterialsAction.request>
): SagaIterator {
  try {
    const materialApi = new MaterialApi(apiConfig());
    const response = yield call(() =>
      materialApi.apiV1MaterialGetExternalMaterialsGet()
    );
    yield put(receiveExternalMaterialsAction.success(response));
  } catch (err) {
    yield put(receiveExternalMaterialsAction.failure(toError(err)));
  }
}

export function* receiveExternalMaterialMetadata(
  action: ReturnType<typeof receiveExternalMaterialMetadataAction.request>
): SagaIterator {
  try {
    const materialApi = new MaterialApi(apiConfig());
    const response = yield call(() =>
      materialApi.apiV1MaterialGetExternalMaterialMetadataPost({
        materialIdentifier: action.payload,
      })
    );
    yield put(receiveExternalMaterialMetadataAction.success(response));
  } catch (err) {
    yield put(receiveExternalMaterialMetadataAction.failure(toError(err)));
  }
}

export function* linkExternalMaterialWithProductId(
  action: ReturnType<typeof linkExternalMaterialWithProductIdAction.request>
): SagaIterator {
  try {
    const materialApi = new MaterialApi(apiConfig());
    const response = yield call(() =>
      materialApi.apiV1MaterialLinkExternalMaterialWithProductIdPost({
        linkExternalMaterialWithProductIdRequest: action.payload,
      })
    );
    yield put(linkExternalMaterialWithProductIdAction.success(response));
  } catch (err) {
    yield put(linkExternalMaterialWithProductIdAction.failure(toError(err)));
  }
}
