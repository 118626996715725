import { Action } from "redux";
import { Reducer, createReducer } from "typesafe-actions";

import { genericReducer } from "../../utils";
import { Loading, ReduxStoreState } from "../base";
import {
  LoadingEditableCampaign,
  LoadingInstructionsOnlineResult,
} from "../models";
import {
  receiveCampaignInstructionsOnlineAction,
  receiveEditableCampaignAction,
  updateCampaignAction,
} from "./actions";

export const updateCampaignReducer: Reducer<Loading, Action> = genericReducer(
  updateCampaignAction.request,
  updateCampaignAction.success,
  updateCampaignAction.failure
);

export const receiveCampaignInstructionsOnlineReducer: Reducer<
  LoadingInstructionsOnlineResult,
  Action
> = genericReducer(
  receiveCampaignInstructionsOnlineAction.request,
  receiveCampaignInstructionsOnlineAction.success,
  receiveCampaignInstructionsOnlineAction.failure,
  "instructions"
);

export const receiveEditableCampaignReducer: Reducer<
  Record<number, LoadingEditableCampaign>,
  Action
> = createReducer({
  state: ReduxStoreState.Initial,
})
  .handleAction(
    receiveEditableCampaignAction.request,
    (
      state: Record<number, LoadingEditableCampaign>,
      action: ReturnType<typeof receiveEditableCampaignAction.request>
    ) => ({
      ...state,
      [action.payload.orderId]: {
        ...state[action.payload.orderId],
        loading: true,
        state: ReduxStoreState.Loading,
      },
    })
  )
  .handleAction(
    receiveEditableCampaignAction.failure,
    (
      state: Record<number, LoadingEditableCampaign>,
      action: ReturnType<typeof receiveEditableCampaignAction.failure>
    ) => ({
      ...state,
      [action.payload.orderId]: {
        ...state[action.payload.orderId],
        loading: false,
        state: ReduxStoreState.Failure,
      },
    })
  )
  .handleAction(
    receiveEditableCampaignAction.success,
    (
      state: Record<number, LoadingEditableCampaign>,
      action: ReturnType<typeof receiveEditableCampaignAction.success>
    ) => ({
      ...state,
      [action.payload.id!]: {
        campaign: action.payload,
        loading: false,
        state: ReduxStoreState.Success,
      },
    })
  );
