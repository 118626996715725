import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  Container,
  ContentContainer,
  Icons,
  Spinner,
  Table,
  Typography,
} from "@ster/ster-toolkit";
import { App as AntApp, Space } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";

import { ExternalMaterial } from "../../../api";
import { createStructuredAppSelector } from "../../../shared/selectors";
import { ReduxStoreState } from "../../../store/base";
import { receiveExternalMaterialsAction } from "../../../store/materials/actions";
import { StoreModel } from "../../../store/models";
import LinkExternalMaterialModal from "./LinkExternalMaterialModal";

export const linkExternalMaterialContainerRootSelector =
  createStructuredAppSelector({
    externalMaterials: (state: StoreModel) =>
      state.material.externalMaterials.externalMaterials,
    externalMaterialsState: (state: StoreModel) =>
      state.material.externalMaterials.state,
    loading: (state: StoreModel) => state.material.externalMaterials.loading,
  });

const LinkExternalMaterialContainer = () => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const { message } = AntApp.useApp();

  // Initialize data
  useEffect(() => {
    dispatch(receiveExternalMaterialsAction.request());
  }, [dispatch]);

  // external material for modal window
  const [externalMaterialToLink, setExternalMaterialToLink] = useState<
    ExternalMaterial | undefined
  >(undefined);

  // material table variables
  const { externalMaterials, externalMaterialsState, loading } = useSelector(
    linkExternalMaterialContainerRootSelector
  );
  const tableActionButtonIconSize = { width: 20, height: 20 };

  useEffect(() => {
    if (externalMaterialsState === ReduxStoreState.Failure) {
      message.error(
        i18n._(
          t`Er is een fout opgetreden bij het ophalen van de externe materialen.`
        )
      );
    }
  }, [externalMaterialsState, message, i18n]);

  // linked external material list
  const [linkedExternalMaterials, setLinkedExternalMaterials] = useState<
    string[]
  >([]);

  // Actual displayed table data
  const externalMaterialsDisplayList = useMemo(
    () =>
      externalMaterials?.map((item) => ({
        ...item,
        isLinked: linkedExternalMaterials.includes(
          item.material.identifier.code
        ),
      })),
    [externalMaterials, linkedExternalMaterials]
  );

  // After linking
  const handleOnLink = useCallback(
    (externalMaterial: ExternalMaterial) => {
      setLinkedExternalMaterials((prevLinkedExternalMaterials) => [
        ...prevLinkedExternalMaterials,
        externalMaterial.material.identifier.code,
      ]);
      setExternalMaterialToLink(undefined);
    },
    [setLinkedExternalMaterials, setExternalMaterialToLink]
  );

  return (
    <ContentContainer>
      <Helmet>
        <title>{i18n._(t`Extern materiaal koppelen`)}</title>
      </Helmet>

      <Container>
        <Spinner spinning={loading}>
          <Typography.Title>
            <Trans>Extern materiaal koppelen</Trans>
          </Typography.Title>

          <Typography.Paragraph>
            <Trans>
              Met behulp van dit scherm is het mogelijk om het externe materiaal
              te koppelen aan sternummers.
            </Trans>
          </Typography.Paragraph>

          {externalMaterialToLink && (
            <LinkExternalMaterialModal
              externalMaterial={externalMaterialToLink}
              onLinked={() => handleOnLink(externalMaterialToLink)}
              onCancel={() => setExternalMaterialToLink(undefined)}
            />
          )}

          {externalMaterialsDisplayList && (
            <Table
              dataSource={externalMaterialsDisplayList}
              rowKey={({ material }) => material.identifier.code}
              pagination={false}
              columns={[
                {
                  title: i18n._(t`Naam`),
                  key: "name",
                  fixed: "left",
                  ellipsis: true,
                  render: (_, { name }) => name,
                  defaultSortOrder: "descend",
                },
                {
                  title: i18n._(t`Acties`),
                  key: "actions",
                  width: 100,
                  fixed: "right",
                  render: (_, item) => (
                    <Space direction="horizontal">
                      {item.isLinked ? (
                        <Trans>Koppeling gemaakt</Trans>
                      ) : (
                        <Button
                          icon={
                            <Icons.EditIcon {...tableActionButtonIconSize} />
                          }
                          mode="tertiary"
                          type="link"
                          size="small"
                          onClick={() => setExternalMaterialToLink(item)}
                        >
                          {i18n._(t`Koppelen`)}
                        </Button>
                      )}
                    </Space>
                  ),
                },
              ]}
              scroll={{ x: "max-content", y: window.innerHeight }}
            />
          )}
        </Spinner>
      </Container>
    </ContentContainer>
  );
};

export default LinkExternalMaterialContainer;
