import { AnyAction } from "redux-saga";
import { ActionType, Reducer, createReducer } from "typesafe-actions";

import { genericReducer } from "../../utils";
import { Loading, ReduxStoreState } from "../base";
import {
  LoadingExternalMaterialMetadata,
  LoadingExternalMaterials,
} from "../models";
import {
  clearLinkExternalMaterialWithProductIdAction,
  linkExternalMaterialWithProductIdAction,
  receiveExternalMaterialMetadataAction,
  receiveExternalMaterialsAction,
} from "./actions";

const initialStateExternalMaterials: LoadingExternalMaterials = {
  externalMaterials: undefined,
  loading: false,
  state: ReduxStoreState.Initial,
};

export const externalMaterialsReducer = createReducer<
  LoadingExternalMaterials,
  ActionType<typeof receiveExternalMaterialsAction>
>(initialStateExternalMaterials)
  .handleAction(receiveExternalMaterialsAction.request, (state) => ({
    ...state,
    loading: true,
    state: ReduxStoreState.Loading,
  }))
  .handleAction(receiveExternalMaterialsAction.failure, (state) => ({
    ...state,
    loading: false,
    state: ReduxStoreState.Failure,
  }))
  .handleAction(receiveExternalMaterialsAction.success, (state, action) => ({
    externalMaterials: action.payload,
    loading: false,
    state: ReduxStoreState.Initial,
  }));

const initialStateExternalMaterialMetadata: LoadingExternalMaterialMetadata = {
  externalMaterialMetadata: undefined,
  loading: false,
  state: ReduxStoreState.Initial,
};

export const receiveExternalMaterialMetadataReducer = createReducer<
  LoadingExternalMaterialMetadata,
  ActionType<typeof receiveExternalMaterialMetadataAction>
>(initialStateExternalMaterialMetadata)
  .handleAction(receiveExternalMaterialMetadataAction.request, (state) => ({
    ...state,
    loading: true,
    state: ReduxStoreState.Loading,
  }))
  .handleAction(receiveExternalMaterialMetadataAction.failure, (state) => ({
    ...state,
    loading: false,
    state: ReduxStoreState.Failure,
  }))
  .handleAction(
    receiveExternalMaterialMetadataAction.success,
    (state, action) => ({
      externalMaterialMetadata: action.payload,
      loading: false,
      state: ReduxStoreState.Initial,
    })
  );

export const linkExternalMaterialWithProductIdReducer: Reducer<
  Loading,
  AnyAction
> = genericReducer(
  linkExternalMaterialWithProductIdAction.request,
  linkExternalMaterialWithProductIdAction.success,
  linkExternalMaterialWithProductIdAction.failure,
  undefined,
  clearLinkExternalMaterialWithProductIdAction
);
