import "./app.less";

import { MessageDescriptor } from "@lingui/core";
import { msg, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  ContextMenuLink,
  Page,
  Spinner,
  VisibleChangeFunction,
} from "@ster/ster-toolkit";
import { App as AntApp } from "antd";
import { isBefore } from "date-fns";
import Cookies from "js-cookie";
import { ReactNode, memo, useCallback, useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";

import { ApplicationFromSterInlog } from "../api";
import { ClaimType, RoleType } from "../shared/models";
import {
  accountOperationalContextStateSelector,
  appSelector,
  operationalContextStateSelector,
} from "../shared/selectors";
import {
  loadOperationalContextStateAction,
  receiveMeAction,
} from "../store/account/actions";
import { receivePublishedAnnouncementsAction } from "../store/announcements/actions";
import { receiveAvailableCampaignsAction } from "../store/campaignForward/actions";
import {
  invalidate,
  receiveSecondaryTargetGroupsAction,
} from "../store/generic/actions";
import { StoreModel } from "../store/models";
import { receiveProductTourAction } from "../store/productTour/actions";
import { receiveSettingsAction } from "../store/settings/actions";
import { receiveTermsAction } from "../store/terms/actions";
import { getLastRead, isAccessTokenValid, setLastRead } from "../utils";
import {
  emptyArray,
  impersonateCookieName,
  organisationCookieName,
} from "../utils/constants";
import {
  isAccountReady,
  isAdmin,
  isImpersonating,
  isInternalUser,
  isLoggedIn,
} from "../utils/userHelper";
import Admin from "./admin/Admin";
import Settings from "./admin/Settings";
import Analysis from "./analysis/Analysis";
import CampaignAnalysis from "./analysis/CampaignAnalysis";
import SpotAnalysis from "./analysis/SpotAnalysis";
import AnnouncementsContainer from "./announcements/AnnouncementsContainer";
import { router } from "./AppContainer";
import { loginUrl } from "./authentication/constants";
import Forbidden from "./authentication/Forbidden";
import Logout from "./authentication/Logout";
import NoAccount from "./authentication/NoAccount";
import NoTenant from "./authentication/NoTenant";
import NotLoggedIn from "./authentication/NotLoggedIn";
import PrivateRoute, { privateRoutePaths } from "./authentication/PrivateRoute";
import CampaignCreateFromCode from "./campaignCreate/CampaignCreateFromCode";
import CampaignDetail from "./campaignDetail/CampaignDetail";
import CampaignContainer from "./campaigns/CampaignContainer";
import CampaignCreateEdit from "./campaigns/CampaignCreateEdit";
import ChatwootWidget from "./ChatwootWidget";
import CommercialAudit from "./commercials/audit/CommercialAudit";
import CommercialContainer from "./commercials/CommercialContainer";
import CommercialUpload from "./commercials/CommercialUpload";
import LinkExternalMaterialContainer from "./commercials/create/LinkExternalMaterialContainer";
import DashboardContainer from "./dashboard/DashboardContainer";
import DocumentContainer from "./documents/DocumentContainer";
import ErrorBoundary from "./ErrorBoundary";
import errorMessages from "./errorMessages";
import Impersonate, { roleLabel } from "./Impersonate";
import InvoiceContainer from "./invoices/InvoiceContainer";
import NotFound from "./NotFound";
import OrganisationContainer from "./organisation/OrganisationContainer";
import AvailableCampaignNotification from "./partials/AvailableCampaignNotification";
import ProductTourModal from "./productTour/ProductTourModal";
import ProposalWithForecast from "./proposal/ProposalWithForecast";
import Ribbon from "./Ribbon";
import TermsModal from "./terms/TermsModal";
import UsersContainer from "./users/UsersContainer";

interface NavLink {
  title: MessageDescriptor;
  to: string;
  accessWithClaims?: ClaimType[];
  accessWithRoles?: RoleType[];
}

const emptyApplications: ApplicationFromSterInlog[] = [];
const emptyNavlinks: NavLink[] = [];

const navlinks: NavLink[] = [
  {
    title: msg`Overzicht`,
    to: "/",
    accessWithRoles: ["User"],
  },
  {
    title: msg`Campagnes`,
    to: "/campaigns",
    accessWithClaims: ["campagnes_tv", "campagnes_radio", "campagnes_inter"],
  },
  {
    title: msg`Analyses`,
    to: "/analysis",
    accessWithClaims: ["analyse"],
  },
  {
    title: msg`Facturen`,
    to: "/invoices",
    accessWithClaims: ["facturen_tv", "facturen_radio", "facturen_inter"],
  },
  {
    title: msg`Materiaal`,
    to: "/material",
    accessWithClaims: [
      "materialinzien_tv",
      "materialinzien_radio",
      "materialinzien_inter",
    ],
  },
  {
    title: msg`Documenten`,
    to: "/documents",
    accessWithClaims: ["documenten_download"],
  },
  {
    title: msg`Beheer`,
    accessWithRoles: ["Admin"],
    to: "/admin",
  },
];

const DocumentRedirect = memo(() => {
  const { documentId } = useParams();
  window.location.replace(`/api/v1/document/${documentId}`);
  return null;
});

const App = memo(() => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const {
    account,
    settings,
    showTerms,
    dateOfTerms,
    productTourLastStep,
    loading,
    failures,
  } = useSelector(appSelector);

  const [showTour, setShowTour] = useState(false);
  const location = useLocation();
  const { message, notification } = AntApp.useApp();
  const [gtmInitialized, setGtmInitialized] = useState(false);
  const operationalContextState = useSelector(operationalContextStateSelector);
  const accountOperationalContext = useSelector(
    accountOperationalContextStateSelector
  );
  const loggedIn = isLoggedIn(account);

  useEffect((): void => {
    // Push de pagina-wissels naar GTM
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        url: location.pathname,
      },
    });
  }, [location]);

  useEffect((): void => {
    dispatch(receiveMeAction.request());
    dispatch(receiveSettingsAction.request());
  }, [dispatch]);

  // Reactivity to ensure there is a valid tenant, re-issue when operational context state changes
  useEffect(() => {
    dispatch(loadOperationalContextStateAction());
  }, [dispatch, accountOperationalContext]);

  const impersonating = isImpersonating(account);
  const internalUser = isInternalUser(account);
  const admin = isAdmin(account);

  const handleShowTour = useCallback(
    (onVisibleChange: VisibleChangeFunction) => {
      onVisibleChange(false);
      setShowTour(true);
    },
    []
  );

  const renderContextMenu = useCallback(
    (onVisibleChange: VisibleChangeFunction): ReactNode => (
      <>
        {internalUser && (
          <>
            <span>
              {i18n._(t`Bekijk als`)}:{" "}
              <strong>
                {Cookies.get(organisationCookieName) ??
                  account.userInfo?.organisationCode}
              </strong>
            </span>
            <ContextMenuLink
              className="context-menu__on-behalf-link"
              to={`/organisation?next=${encodeURIComponent(`/campaigns`)}`}
              onVisibleChange={onVisibleChange}
            >
              {i18n._(t`Wijzigen`)}
            </ContextMenuLink>
          </>
        )}
        <Impersonate account={account} onVisibleChange={onVisibleChange} />
        <button
          type="button"
          className="link context-menu__link"
          onClick={(_) => handleShowTour(onVisibleChange)}
        >
          {i18n._(t`Uitleg Klantportal`)}
        </button>
      </>
    ),
    [account, handleShowTour, i18n, internalUser]
  );

  useEffect(() => {
    if (loggedIn) {
      dispatch(receiveSecondaryTargetGroupsAction.request());
      dispatch(receiveTermsAction.request());
      dispatch(receivePublishedAnnouncementsAction.request());
      dispatch(receiveProductTourAction.request());
      dispatch(receiveAvailableCampaignsAction.request());
    }
  }, [dispatch, account, loggedIn, operationalContextState]);

  useEffect(() => {
    let available: number | undefined;
    if (loggedIn && operationalContextState) {
      available = setInterval(() => {
        if (isAccessTokenValid()) {
          /* refresh every 5 minutes when token is valid */
          dispatch(receiveAvailableCampaignsAction.request());
        } else {
          clearInterval(available);
        }
      }, 300_000) as unknown as number;
    }

    return () => clearInterval(available);
  }, [dispatch, account, loggedIn, operationalContextState]);

  const googleTagManagerId = settings.settings?.google?.tagManagerId;

  useEffect((): void => {
    if (googleTagManagerId && loggedIn && !gtmInitialized) {
      setGtmInitialized((value) => {
        if (!value) {
          TagManager.initialize({
            gtmId: `${googleTagManagerId}`,
            dataLayer: {
              remote_ip: account.ipAddress,
              userId: internalUser ? account.sterInlog.id : account.id,
              userType: account.userType,
            },
          });
        }
        return true;
      });
    }
  }, [account, googleTagManagerId, gtmInitialized, internalUser, loggedIn]);

  const availableCampaigns = useSelector(
    (store: StoreModel) =>
      store.campaignForward.availableCampaigns?.availableCampaigns ?? emptyArray
  );

  const [lastRead, setLastReadInState] = useState(getLastRead());
  const handleReadAll = useCallback(() => {
    setLastRead();
    setLastReadInState(new Date());
  }, []);

  if (failures && failures.length > 0) {
    failures.forEach(({ propertyName }) => {
      const description = errorMessages[propertyName] ? (
        <>
          {i18n._(errorMessages[propertyName])}{" "}
          {i18n._(errorMessages.defaultMessage)}{" "}
          <a href="mailto:klantportal@ster.nl">klantportal@ster.nl</a>
        </>
      ) : (
        <>
          {i18n._(errorMessages.defaultMessage)}{" "}
          <a href="mailto:klantportal@ster.nl">klantportal@ster.nl</a>
        </>
      );
      notification.error({
        key: `${propertyName}`,
        message: i18n._(t`Fout`),
        description,
        duration: 0,
        onClose: () => {
          dispatch(invalidate(propertyName));
        },
      });
    });
  }

  const cloudRoleNameUi = settings.settings?.appInsights?.cloudRoleNameUi;
  const instrumentationKey = settings.settings?.appInsights.instrumentationKey;

  return (
    cloudRoleNameUi && (
      <ErrorBoundary
        cloudRoleNameUi={cloudRoleNameUi}
        instrumentationKey={instrumentationKey}
        router={router}
        message={message}
      >
        <Page
          loggedIn={loggedIn}
          navLinks={(loggedIn
            ? navlinks.filter(
                (l) =>
                  (!l.accessWithRoles ||
                    account.roles.some((value: string) =>
                      l.accessWithRoles?.includes(value as RoleType)
                    )) &&
                  (!l.accessWithClaims ||
                    account.claims.some((value: string) =>
                      l.accessWithClaims?.includes(value as ClaimType)
                    )) &&
                  (settings.settings?.enableAnalysis
                    ? true
                    : l.to !== "/analysis")
              )
            : emptyNavlinks
          ).map((n) => ({ ...n, title: i18n._(n.title) }))}
          applications={account.sterInlog?.applications ?? emptyApplications}
          notifications={availableCampaigns.map((campaign) => ({
            message: (
              <AvailableCampaignNotification
                key={campaign.id}
                campaign={campaign}
              />
            ),
            isRead: isBefore(campaign.forwardedOn, lastRead),
            clickToClose: true,
          }))}
          onReadAll={handleReadAll}
          extraContextMenuItems={renderContextMenu}
          loginUrl={loginUrl.replace(/\/$/, "")}
          isInternalUser={internalUser}
          isAdmin={admin}
          backLabelText={i18n._(t`Terug naar overzicht`)}
          userContextMenuLabels={{
            websiteLabel: i18n._(t`Ster Website`),
            adminLabel: i18n._(t`Beheer`),
            profileLabel: i18n._(t`Mijn profiel`),
            languageLabel: i18n._(t`Taal / Language`),
            notificationsLabel: i18n._(t`Mijn notificaties`),
            changePasswordLabel: i18n._(t`Wachtwoord wijzigen`),
            logoutLabel: i18n._(t`Uitloggen`),
          }}
          footerLabels={{
            terms: i18n._(t`Voorwaarden`),
            cookieStatement: i18n._(t`Cookiestatement`),
            changeCookieSettings: i18n._(t`Wijzig cookie instellingen`),
            privacyStatement: i18n._(t`Privacystatement`),
          }}
        >
          <Helmet
            defaultTitle="Klantportal - Ster reclame"
            titleTemplate="%s - Klantportal - Ster reclame"
          >
            <html lang="nl" />
          </Helmet>
          {settings.settings && (
            <ChatwootWidget
              sterInlog={account.sterInlog}
              settings={settings.settings}
            />
          )}

          {impersonating && (
            <Ribbon
              text={`${i18n._(t`Huidige rol`)}: ${i18n._(
                roleLabel[Cookies.get(impersonateCookieName) as string]
              )}`}
            />
          )}

          <Spinner spinning={loading}>
            {
              // Lege inhoud om spinner in embed mode te zetten
            }
            {settings.settings && isAccountReady(account) && (
              <Routes>
                <Route element={<PrivateRoute user={account} />}>
                  <Route path="/" element={<DashboardContainer />} />
                  <Route path="/index.html" element={<DashboardContainer />} />
                  <Route
                    path="/campaigns/proposal/:medium/:ids/:from/:to"
                    element={<ProposalWithForecast />}
                  />
                  {/* Directe link naar documenten via de portal zodat je een melding krijgt als je niet ingelogd bent */}
                  <Route
                    path="/documents/:documentId"
                    element={<DocumentRedirect />}
                  />
                </Route>
                <Route
                  element={
                    <PrivateRoute
                      user={account}
                      requiredClaims={[
                        "campagnes_tv",
                        "campagnes_radio",
                        "campagnes_inter",
                      ]}
                    />
                  }
                >
                  <Route path="/campaigns" element={<CampaignContainer />} />
                  <Route path="/campaigns/*" element={<CampaignDetail />}>
                    <Route
                      path="/campaigns/*/:medium/:orderId"
                      element={<Outlet />}
                    />
                    <Route
                      path="/campaigns/*/schedule/:medium/:orderId"
                      element={<Outlet />}
                    />
                    <Route
                      path="/campaigns/*/schedule/:medium/:orderId/:subOrderId"
                      element={<Outlet />}
                    />
                    <Route
                      path="/campaigns/*/instructions/:medium/:orderId"
                      element={<Outlet />}
                    />
                  </Route>
                </Route>
                {settings.settings.enableAnalysis && (
                  <Route
                    element={
                      <PrivateRoute
                        user={account}
                        requiredClaims={["analyse"]}
                      />
                    }
                  >
                    <Route path="/analysis" element={<Analysis />} />
                    <Route
                      path="/analysis/campaign"
                      element={<CampaignAnalysis />}
                    />
                    <Route path="/analysis/spot" element={<SpotAnalysis />} />
                  </Route>
                )}
                <Route
                  element={
                    <PrivateRoute
                      user={account}
                      requiredClaims={[
                        "aanvragen_formulier_tv",
                        "aanvragen_formulier_radio",
                        "aanvragen_formulier_inter",
                      ]}
                    />
                  }
                >
                  <Route
                    path="/campaigns/new"
                    element={<CampaignCreateEdit />}
                  />
                  <Route
                    path="/campaigns/new/:medium/:initialRequestId/:from/:to"
                    element={<CampaignCreateEdit />}
                  />
                  <Route
                    path="/campaigns/edit/:medium/:orderId/:subOrderId?"
                    element={<CampaignCreateEdit isEdit />}
                  />
                  <Route
                    path="/campaigns/code"
                    element={<CampaignCreateFromCode />}
                  />
                  <Route
                    path="/campaigns/code/:campaignCode"
                    element={<CampaignCreateFromCode />}
                  />
                </Route>
                <Route
                  element={
                    <PrivateRoute
                      user={account}
                      requiredClaims={[
                        "facturen_tv",
                        "facturen_radio",
                        "facturen_inter",
                      ]}
                    />
                  }
                >
                  <Route path="/invoices" element={<InvoiceContainer />} />
                </Route>
                <Route
                  element={
                    <PrivateRoute
                      user={account}
                      requiredClaims={[
                        "materialinzien_tv",
                        "materialinzien_radio",
                        "materialinzien_inter",
                      ]}
                    />
                  }
                >
                  <Route path="/material">
                    <Route index element={<CommercialContainer />} />
                    <Route
                      path="upload/:medium"
                      element={<CommercialUpload />}
                    />
                    <Route path="audit/:medium" element={<CommercialAudit />} />
                    <Route
                      path="link"
                      element={<LinkExternalMaterialContainer />}
                    />
                  </Route>
                </Route>
                <Route
                  element={
                    <PrivateRoute
                      user={account}
                      requiredRoles={["Medewerker"]}
                    />
                  }
                >
                  <Route
                    path="/organisation"
                    element={<OrganisationContainer />}
                  />
                </Route>
                <Route
                  element={
                    <PrivateRoute
                      user={account}
                      requiredClaims={[
                        "documenten_download",
                        "documenten_upload",
                      ]}
                    />
                  }
                >
                  <Route path="/documents/*" element={<DocumentContainer />}>
                    <Route
                      path="/documents/*/upload"
                      element={<DocumentContainer />}
                    />
                  </Route>
                </Route>
                <Route
                  element={
                    <PrivateRoute user={account} requiredRoles={["Admin"]} />
                  }
                >
                  <Route path="/admin" element={<Admin />}>
                    <Route
                      index
                      element={<Navigate to="/admin/users" replace />}
                    />
                    <Route path="users" element={<UsersContainer />}>
                      <Route path="new" element={<Outlet />} />
                      <Route path=":userId" element={<Outlet />} />
                    </Route>
                    <Route
                      path="announcements"
                      element={<AnnouncementsContainer />}
                    />
                    <Route path="settings" element={<Settings />} />
                  </Route>
                </Route>
                <Route path="/logout" element={<Logout />} />
                <Route path="*" element={<NotFound />} />
                <Route
                  path={privateRoutePaths.forbidden}
                  element={<Forbidden />}
                />
                <Route
                  path={privateRoutePaths.noPortalAccount}
                  element={<NoAccount />}
                />
                <Route
                  path={privateRoutePaths.notLoggedIn}
                  element={<NotLoggedIn user={account} />}
                />
                <Route
                  path={privateRoutePaths.noTenant}
                  element={<NoTenant />}
                />
              </Routes>
            )}

            {loggedIn &&
              !loading &&
              showTerms &&
              settings.settings &&
              dateOfTerms && <TermsModal dateOfTerms={dateOfTerms} />}
            {loggedIn && !loading && productTourLastStep !== undefined && (
              <ProductTourModal
                lastStep={productTourLastStep}
                lastLogin={account.lastLogin}
                visible={showTour}
                setVisible={setShowTour}
              />
            )}
          </Spinner>
        </Page>
      </ErrorBoundary>
    )
  );
});

export default App;
