import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Form, Spinner } from "@ster/ster-toolkit";
import { App as AntApp, Form as AntForm } from "antd";
import { subYears } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";

import { ExternalMaterial, Material } from "../../../api";
import { initialDatesSelector } from "../../../shared/selectors";
import { ReduxStoreState } from "../../../store/base";
import {
  clearLinkExternalMaterialWithProductIdAction,
  linkExternalMaterialWithProductIdAction,
  receiveExternalMaterialMetadataAction,
} from "../../../store/materials/actions";
import { StoreModel } from "../../../store/models";
import { formatDuration } from "../../../utils";
import DefinitionList from "../../common/DefinitionList";
import MaterialPlayButton from "../../partials/Cards/Material/MaterialPlayButton";
import ProductsPartialForm, {
  ProductsPartialFormData,
} from "../../partials/Products/ProductsPartialForm";
import styles from "./LinkExternalMaterialForm.module.less";
import MaterialPreviewModal from "./MaterialPreviewModal";

const linkExternalMaterialFormRootSelector = createStructuredSelector({
  initialPeriod: initialDatesSelector,
  externalMaterialMetadata: (state: StoreModel) =>
    state.material.externalMaterialMetadata.externalMaterialMetadata,
  externalMaterialMetadataState: (state: StoreModel) =>
    state.material.externalMaterialMetadata.state,
  linkExternalMaterialWithProductId: (state: StoreModel) =>
    state.material.linkExternalMaterialWithProductId,
  loading: (state: StoreModel) =>
    state.material.externalMaterialMetadata.loading,
});

const LinkExternalMaterialForm = ({
  externalMaterial,
  onLinked,
}: {
  externalMaterial: ExternalMaterial;
  onLinked: () => void;
}) => {
  const { message } = AntApp.useApp();
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const [form] = AntForm.useForm<ProductsPartialFormData>();
  const {
    externalMaterialMetadata,
    externalMaterialMetadataState,
    linkExternalMaterialWithProductId,
    loading,
  } = useSelector((state: StoreModel) =>
    linkExternalMaterialFormRootSelector(state)
  );

  const dateNow = new Date();
  const dateOneYearAgo = subYears(new Date(), 1);

  const initialValues: ProductsPartialFormData = {
    period: [dateOneYearAgo, dateNow],
    medium: externalMaterial.material.identifier.medium,
  };

  const formProductId = AntForm.useWatch("productId", form);

  // preview variables
  const [previewMaterial, setPreviewMaterial] = useState<Material | undefined>(
    undefined
  );

  // Request data on initialize
  useEffect(() => {
    dispatch(
      receiveExternalMaterialMetadataAction.request(
        externalMaterial.material.identifier
      )
    );

    // and clear when previous form was dismissed
    dispatch(clearLinkExternalMaterialWithProductIdAction());
  }, [externalMaterial.material.identifier, dispatch]);

  useEffect(() => {
    if (externalMaterialMetadataState === ReduxStoreState.Failure) {
      message.error(
        i18n._(
          t`Er is een fout opgetreden bij het ophalen van de meta data van het externe materiaal.`
        )
      );
    }
  }, [externalMaterialMetadataState, i18n, message]);

  // When form is submitted
  const handleSubmit = useCallback(() => {
    if (!formProductId) {
      return;
    }

    dispatch(
      linkExternalMaterialWithProductIdAction.request({
        materialIdentifier: externalMaterial.material.identifier,
        productId: formProductId,
      })
    );
  }, [formProductId, dispatch, externalMaterial.material.identifier]);

  // When linked
  useEffect(() => {
    if (linkExternalMaterialWithProductId.state === ReduxStoreState.Success) {
      // display success
      message.success(i18n._(t`Het materiaal is gekoppeld.`));

      // clear link action
      dispatch(clearLinkExternalMaterialWithProductIdAction());

      // report linked
      onLinked();
    }
  }, [
    linkExternalMaterialWithProductId.state,
    dispatch,
    onLinked,
    i18n,
    message,
  ]);

  const errorMessage =
    linkExternalMaterialWithProductId.state === ReduxStoreState.Failure
      ? "Er is iets fout gegaan. Controleer je selectie en probeer het opnieuw."
      : "";

  return (
    <div>
      <Spinner spinning={loading}>
        <div className={styles.container}>
          <div className={styles.leftColumn}>
            <MaterialPlayButton
              material={externalMaterial.material}
              title={`${externalMaterial.material.identifier.code}`}
              onClick={() => {
                setPreviewMaterial(externalMaterial.material);
              }}
            />
            <DefinitionList>
              {externalMaterialMetadata && (
                <>
                  <DefinitionList.Term>
                    <Trans>Commerciële lengte</Trans>
                  </DefinitionList.Term>
                  <DefinitionList.Description>
                    {formatDuration(externalMaterialMetadata.commercialLength)}
                  </DefinitionList.Description>
                  <DefinitionList.Term>
                    <Trans>Technische lengte</Trans>
                  </DefinitionList.Term>
                  <DefinitionList.Description>
                    {formatDuration(externalMaterialMetadata.technicalLength)}
                  </DefinitionList.Description>
                </>
              )}
            </DefinitionList>
          </div>
          <div className={styles.rightColumn}>
            <Form<ProductsPartialFormData>
              layout="vertical"
              initialValues={initialValues}
              form={form}
            >
              <ProductsPartialForm
                form={form}
                mediumTypes={[externalMaterial.material.identifier.medium]}
                disabledDate={undefined}
                hideRangePicker
              />
              <Form.Item validateStatus="error" help={errorMessage}>
                <Button
                  mode="primary"
                  disabled={!formProductId}
                  onClick={handleSubmit}
                  loading={linkExternalMaterialWithProductId.loading}
                >
                  <Trans>Koppelen</Trans>
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Spinner>

      {previewMaterial && (
        <MaterialPreviewModal
          material={previewMaterial}
          onCancel={() => setPreviewMaterial(undefined)}
        />
      )}
    </div>
  );
};

export default LinkExternalMaterialForm;
