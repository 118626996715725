import React from "react";

import styles from "./DefinitionList.module.less";

interface DefinitionListProps {
  children: React.ReactNode;
}

interface TermProps {
  children: React.ReactNode;
}

interface DescriptionProps {
  children: React.ReactNode;
}

const DefinitionList = ({ children }: DefinitionListProps) => (
  <dl className={styles.dl}>{children}</dl>
);

const Term = ({ children }: TermProps) => <dt>{children}</dt>;

const Description = ({ children }: DescriptionProps) => <dd>{children}</dd>;

// Attach Term and Description as static properties to DefinitionList
DefinitionList.Term = Term;
DefinitionList.Description = Description;

export default DefinitionList;
