import { saveAs } from "file-saver";
import { Action } from "redux";
import { Reducer, createReducer } from "typesafe-actions";

import { MediumEnum } from "../../api";
import { genericReducer } from "../../utils";
import { Loading, ReduxStoreState } from "../base";
import {
  LoadingBookingDate,
  LoadingCalculations,
  LoadingCampaigns,
  LoadingContextTargets,
  LoadingForecast,
  LoadingForecastConversionGroups,
  LoadingGroupedPackagesRadio,
  LoadingGroupedPackagesTv,
  LoadingInitialRequest,
  LoadingPackages,
  LoadingProducts,
  LoadingPrograms,
  LoadingProposalGenerate,
  LoadingSalesPeriods,
  LoadingSites,
  LoadingSpotIndexes,
  LoadingTargetGroups,
} from "../models";
import {
  analysisUploadFileAction,
  campaignConfigurationClearAction,
  campaignConfigurationFetchAction,
  clearForecastAction,
  clearGroupedPackagesRadioAction,
  clearGroupedPackagesTvAction,
  clearPackagesAction,
  clearProductsAction,
  clearProposalGenerateAction,
  finalizeRequestAction,
  importConfigurationAction,
  importConfigurationClearAction,
  proposalGenerateAction,
  proposalUploadForecastAction,
  receiveBookingDateAction,
  receiveCalculationAction,
  receiveContextTargetsAction,
  receiveCustomerAccountsAction,
  receiveCustomerContactClearAction,
  receiveCustomerContactsAction,
  receiveCustomerOpportunitiesAction,
  receiveCustomerOpportunitiesClearAction,
  receiveForecastAction,
  receiveForecastConversionGroupsAction,
  receiveGroupedPackagesRadioAction,
  receiveGroupedPackagesTvAction,
  receiveInitialRequestAction,
  receivePackagesAction,
  receiveProductsAction,
  receiveProgramsAction,
  receiveSalesPeriodsAction,
  receiveSitesAction,
  receiveSpotIndexesAction,
  receiveTargetGroupsAction,
  saveRequestAction,
} from "./actions";
import {
  LoadingCampaignConfiguration,
  LoadingCampaignImportOutput,
  LoadingCustomerAccounts,
  LoadingCustomerContacts,
  LoadingCustomerOpportunities,
} from "./models";

export const salesPeriodsReducer: Reducer<LoadingSalesPeriods, Action> =
  createReducer({
    state: ReduxStoreState.Initial,
  })
    .handleAction(
      receiveSalesPeriodsAction.request,
      (state: LoadingCampaigns) => ({
        ...state,
        loading: true,
        state: ReduxStoreState.Loading,
      })
    )
    .handleAction(
      receiveSalesPeriodsAction.failure,
      (state: LoadingCampaigns) => ({
        ...state,
        loading: false,
        state: ReduxStoreState.Failure,
      })
    )
    .handleAction(
      receiveSalesPeriodsAction.success,
      (
        state: LoadingSalesPeriods,
        action: ReturnType<typeof receiveSalesPeriodsAction.success>
      ) => ({
        salesPeriods: action.payload,
        loading: false,
        state: ReduxStoreState.Success,
      })
    );

export const productsReducer: Reducer<LoadingProducts, Action> = createReducer({
  state: ReduxStoreState.Initial,
})
  .handleAction(receiveProductsAction.request, (state: LoadingProducts) => ({
    ...state,
    loading: true,
    state: ReduxStoreState.Loading,
  }))
  .handleAction(receiveProductsAction.failure, (state: LoadingProducts) => ({
    ...state,
    loading: false,
    state: ReduxStoreState.Failure,
  }))
  .handleAction(
    receiveProductsAction.success,
    (
      state: LoadingProducts,
      action: ReturnType<typeof receiveProductsAction.success>
    ) => ({
      products: action.payload,
      loading: false,
      state: ReduxStoreState.Success,
    })
  )
  .handleAction(clearProductsAction, () => ({
    products: undefined,
    loading: false,
    state: ReduxStoreState.Initial,
  }));

// Deze reducer is zowel voor het ophalen van een aanvraag als het finalizen
export const initialRequestReducer: Reducer<
  Record<string, LoadingInitialRequest>,
  Action
> = createReducer({
  state: ReduxStoreState.Initial,
})
  .handleAction(
    receiveInitialRequestAction.request,
    (
      state: Record<string, LoadingInitialRequest>,
      action: ReturnType<typeof receiveInitialRequestAction.request>
    ) => ({
      ...state,
      [`${action.payload.initialRequestId}_${action.payload.from.getMonth()}`]:
        {
          ...state[
            `${
              action.payload.initialRequestId
            }_${action.payload.from.getMonth()}`
          ],
          loading: true,
          state: ReduxStoreState.Loading,
        },
    })
  )
  .handleAction(
    receiveInitialRequestAction.failure,
    (
      state: Record<string, LoadingInitialRequest>,
      action: ReturnType<typeof receiveInitialRequestAction.failure>
    ) => ({
      ...state,
      [`${action.payload.initialRequestId}_${action.payload.from.getMonth()}`]:
        {
          ...state[
            `${
              action.payload.initialRequestId
            }_${action.payload.from.getMonth()}`
          ],
          loading: false,
          state: ReduxStoreState.Failure,
        },
    })
  )
  .handleAction(
    receiveInitialRequestAction.success,
    (
      state: Record<string, LoadingInitialRequest>,
      action: ReturnType<typeof receiveInitialRequestAction.success>
    ) => ({
      ...state,
      [`${action.payload.id ?? 0}_${action.payload.period.from.getMonth()}`]: {
        initialRequest: action.payload,
        loading: false,
        state: ReduxStoreState.Success,
      },
    })
  )
  .handleAction(
    saveRequestAction.request,
    (
      state: Record<string, LoadingInitialRequest>,
      action: ReturnType<typeof saveRequestAction.request>
    ) => ({
      ...state,
      [`${
        action.payload.orderRequest.id ?? 0
      }_${action.payload.orderRequest.period.from.getMonth()}`]: {
        ...state[
          `${
            action.payload.orderRequest.id ?? 0
          }_${action.payload.orderRequest.period.from.getMonth()}`
        ],
        loading: true,
        state: ReduxStoreState.Loading,
      },
    })
  )
  .handleAction(
    saveRequestAction.failure,
    (
      state: Record<string, LoadingInitialRequest>,
      action: ReturnType<typeof saveRequestAction.failure>
    ) => ({
      ...state,
      [`0_${action.payload.from.getMonth()}`]: {
        loading: false,
        state: ReduxStoreState.Initial,
      },
      [`${
        action.payload.initialRequestId ?? 0
      }_${action.payload.from.getMonth()}`]: {
        ...state[
          `${action.payload.initialRequestId}_${action.payload.from.getMonth()}`
        ],
        loading: false,
        state: ReduxStoreState.Failure,
      },
    })
  )
  .handleAction(
    saveRequestAction.success,
    (
      state: Record<string, LoadingInitialRequest>,
      action: ReturnType<typeof saveRequestAction.success>
    ) => ({
      ...state,
      [`0_${action.payload.order.period.from.getMonth()}`]: {
        loading: false,
        state: ReduxStoreState.Initial,
      },
      [`${
        action.payload.initialRequestId
      }_${action.payload.order.period.from.getMonth()}`]: {
        initialRequest: action.payload.order,
        loading: false,
        state: ReduxStoreState.Success,
      },
    })
  )
  .handleAction(
    finalizeRequestAction.request,
    (
      state: Record<string, LoadingInitialRequest>,
      action: ReturnType<typeof finalizeRequestAction.request>
    ) => ({
      ...state,
      [`${
        action.payload.orderRequest.id ?? 0
      }_${action.payload.orderRequest.period.from.getMonth()}`]: {
        ...state[
          `${
            action.payload.orderRequest.id ?? 0
          }_${action.payload.orderRequest.period.from.getMonth()}`
        ],
        loading: true,
        state: ReduxStoreState.Loading,
      },
    })
  )
  .handleAction(
    finalizeRequestAction.failure,
    (
      state: Record<string, LoadingInitialRequest>,
      action: ReturnType<typeof finalizeRequestAction.failure>
    ) => ({
      ...state,
      [`0_${action.payload.from.getMonth()}`]: {
        loading: false,
        state: ReduxStoreState.Initial,
      },
      [`${
        action.payload.initialRequestId ?? 0
      }_${action.payload.from.getMonth()}`]: {
        ...state[
          `${
            action.payload.initialRequestId ?? 0
          }_${action.payload.from.getMonth()}`
        ],
        loading: false,
        state: ReduxStoreState.Failure,
      },
    })
  )
  .handleAction(
    finalizeRequestAction.success,
    (
      state: Record<string, LoadingInitialRequest>,
      action: ReturnType<typeof finalizeRequestAction.success>
    ) => ({
      ...state,
      [`0_${action.payload.order.period.from.getMonth()}`]: {
        loading: false,
        state: ReduxStoreState.Initial,
      },
      [`${
        action.payload.initialRequestId ?? 0
      }_${action.payload.order.period.from.getMonth()}`]: {
        initialRequest: action.payload.order,
        loading: false,
        state: ReduxStoreState.Success,
      },
    })
  );

export const spotIndexesReducer: Reducer<
  Record<MediumEnum, LoadingSpotIndexes>,
  Action
> = createReducer({
  state: ReduxStoreState.Initial,
})
  .handleAction(
    receiveSpotIndexesAction.request,
    (
      state: LoadingProducts,
      action: ReturnType<typeof receiveSpotIndexesAction.request>
    ) => ({
      ...state,
      [action.payload.medium]: {
        loading: true,
        state: ReduxStoreState.Loading,
      },
    })
  )
  .handleAction(
    receiveSpotIndexesAction.failure,
    (
      state: LoadingProducts,
      action: ReturnType<typeof receiveSpotIndexesAction.failure>
    ) => ({
      ...state,
      [action.payload.medium]: {
        loading: false,
        state: ReduxStoreState.Failure,
      },
    })
  )
  .handleAction(
    receiveSpotIndexesAction.success,
    (
      state: LoadingSpotIndexes,
      action: ReturnType<typeof receiveSpotIndexesAction.success>
    ) => ({
      ...state,
      [action.payload.medium]: {
        spotIndexes: action.payload.data,
        loading: false,
        state: ReduxStoreState.Success,
      },
    })
  );

export const packagesReducer: Reducer<LoadingPackages, Action> = createReducer({
  state: ReduxStoreState.Initial,
})
  .handleAction(receivePackagesAction.request, (state: LoadingPackages) => ({
    ...state,
    loading: true,
    state: ReduxStoreState.Loading,
  }))
  .handleAction(receivePackagesAction.failure, (state: LoadingPackages) => ({
    ...state,
    loading: false,
    state: ReduxStoreState.Failure,
  }))
  .handleAction(
    receivePackagesAction.success,
    (
      state: LoadingPackages,
      action: ReturnType<typeof receivePackagesAction.success>
    ) => ({
      packages: action.payload,
      loading: false,
      state: ReduxStoreState.Success,
    })
  )
  .handleAction(clearPackagesAction, () => ({
    packages: undefined,
    loading: false,
    state: ReduxStoreState.Initial,
  }));

export const groupedPackagesRadioReducer: Reducer<
  LoadingGroupedPackagesRadio,
  Action
> = genericReducer(
  receiveGroupedPackagesRadioAction.request,
  receiveGroupedPackagesRadioAction.success,
  receiveGroupedPackagesRadioAction.failure,
  "groupedPackagesRadio",
  clearGroupedPackagesRadioAction
);

export const groupedPackagesTvReducer: Reducer<
  LoadingGroupedPackagesTv,
  Action
> = genericReducer(
  receiveGroupedPackagesTvAction.request,
  receiveGroupedPackagesTvAction.success,
  receiveGroupedPackagesTvAction.failure,
  "groupedPackagesTv",
  clearGroupedPackagesTvAction
);

export const bookingDateReducer: Reducer<LoadingBookingDate, Action> =
  createReducer({
    state: ReduxStoreState.Initial,
  })
    .handleAction(
      receiveBookingDateAction.request,
      (state: LoadingBookingDate) => ({
        ...state,
        loading: true,
        state: ReduxStoreState.Loading,
      })
    )
    .handleAction(
      receiveBookingDateAction.failure,
      (state: LoadingBookingDate) => ({
        ...state,
        loading: false,
        state: ReduxStoreState.Failure,
      })
    )
    .handleAction(
      receiveBookingDateAction.success,
      (
        state: LoadingBookingDate,
        action: ReturnType<typeof receiveBookingDateAction.success>
      ) => ({
        bookingDate: action.payload,
        loading: false,
        state: ReduxStoreState.Success,
      })
    );

export const receiveForecastReducer: Reducer<LoadingForecast, Action> =
  createReducer({
    state: ReduxStoreState.Initial,
  })
    .handleAction(receiveForecastAction.request, (state: LoadingForecast) => ({
      ...state,
      loading: true,
      state: ReduxStoreState.Loading,
    }))
    .handleAction(receiveForecastAction.failure, () => ({
      forecast: undefined,
      loading: false,
      state: ReduxStoreState.IgnoredFailure,
    }))
    .handleAction(
      receiveForecastAction.success,
      (
        state: LoadingForecast,
        action: ReturnType<typeof receiveForecastAction.success>
      ) => ({
        forecast: action.payload,
        loading: false,
        state: ReduxStoreState.Success,
      })
    )
    .handleAction(clearForecastAction, () => ({
      forecast: undefined,
      loading: false,
      state: ReduxStoreState.Initial,
    }));

export const forecastConversionGroupsReducer: Reducer<
  LoadingForecastConversionGroups,
  Action
> = createReducer({
  state: ReduxStoreState.Initial,
})
  .handleAction(
    receiveForecastConversionGroupsAction.request,
    (state: LoadingBookingDate) => ({
      ...state,
      loading: true,
      state: ReduxStoreState.Loading,
    })
  )
  .handleAction(
    receiveForecastConversionGroupsAction.failure,
    (state: LoadingBookingDate) => ({
      ...state,
      loading: false,
      state: ReduxStoreState.Failure,
    })
  )
  .handleAction(
    receiveForecastConversionGroupsAction.success,
    (
      state: LoadingForecastConversionGroups,
      action: ReturnType<typeof receiveForecastConversionGroupsAction.success>
    ) => ({
      ...action.payload,
      loading: false,
      state: ReduxStoreState.Success,
    })
  );

export const contextTargetsReducer: Reducer<LoadingContextTargets, Action> =
  genericReducer(
    receiveContextTargetsAction.request,
    receiveContextTargetsAction.success,
    receiveContextTargetsAction.failure,
    "contextTargets"
  );

export const sitesReducer: Reducer<LoadingSites, Action> = genericReducer(
  receiveSitesAction.request,
  receiveSitesAction.success,
  receiveSitesAction.failure
);

export const programsReducer: Reducer<LoadingPrograms, Action> = genericReducer(
  receiveProgramsAction.request,
  receiveProgramsAction.success,
  receiveProgramsAction.failure
);

export const targetGroupsReducer: Reducer<LoadingTargetGroups, Action> =
  genericReducer(
    receiveTargetGroupsAction.request,
    receiveTargetGroupsAction.success,
    receiveTargetGroupsAction.failure,
    "data"
  );

export const proposalGenerateReducer: Reducer<LoadingProposalGenerate, Action> =
  createReducer({
    state: ReduxStoreState.Initial,
  })
    .handleAction(proposalGenerateAction.request, () => ({
      loading: true,
      state: ReduxStoreState.Loading,
    }))
    .handleAction(proposalGenerateAction.failure, () => ({
      loading: false,
      state: ReduxStoreState.Failure,
    }))
    .handleAction(proposalGenerateAction.success, () => ({
      loading: false,
      state: ReduxStoreState.Success,
    }))
    .handleAction(clearProposalGenerateAction, () => ({
      loading: false,
      state: ReduxStoreState.Initial,
    }));

export const proposalUploadForecastReducer: Reducer<Loading, Action> =
  createReducer({
    state: ReduxStoreState.Initial,
  })
    .handleAction(proposalUploadForecastAction.request, () => ({
      loading: true,
      state: ReduxStoreState.Loading,
    }))
    .handleAction(proposalUploadForecastAction.failure, () => ({
      loading: false,
      state: ReduxStoreState.Failure,
    }))
    .handleAction(
      proposalUploadForecastAction.success,
      (
        state: Loading,
        action: ReturnType<typeof proposalUploadForecastAction.success>
      ) => {
        const filename = `Ster-voorstel.pdf`;
        const fileContents = action.payload;
        saveAs(fileContents, filename);

        return {
          loading: false,
          state: ReduxStoreState.Success,
        };
      }
    );

export const analysisUploadFileReducer: Reducer<Loading, Action> =
  createReducer({
    state: ReduxStoreState.Initial,
  })
    .handleAction(analysisUploadFileAction.request, () => ({
      loading: true,
      state: ReduxStoreState.Loading,
    }))
    .handleAction(analysisUploadFileAction.failure, () => ({
      loading: false,
      state: ReduxStoreState.Failure,
    }))
    .handleAction(
      analysisUploadFileAction.success,
      (
        state: Loading,
        action: ReturnType<typeof analysisUploadFileAction.success>
      ) => {
        const filename = `Ster-analyse.pdf`;
        const fileContents = action.payload;
        saveAs(fileContents, filename);

        return {
          loading: false,
          state: ReduxStoreState.Success,
        };
      }
    );

export const campaignConfigurationReducer: Reducer<
  LoadingCampaignConfiguration,
  Action
> = genericReducer(
  campaignConfigurationFetchAction.request,
  campaignConfigurationFetchAction.success,
  campaignConfigurationFetchAction.failure,
  undefined,
  campaignConfigurationClearAction
);

export const importConfigurationReducer: Reducer<
  LoadingCampaignImportOutput,
  Action
> = genericReducer(
  importConfigurationAction.request,
  importConfigurationAction.success,
  importConfigurationAction.failure,
  undefined,
  importConfigurationClearAction
);

export const receiveCustomerAccountsReducer: Reducer<
  LoadingCustomerAccounts,
  Action
> = genericReducer(
  receiveCustomerAccountsAction.request,
  receiveCustomerAccountsAction.success,
  receiveCustomerAccountsAction.failure,
  "data"
);

export const receiveCustomerOpportunitiesReducer: Reducer<
  LoadingCustomerOpportunities,
  Action
> = genericReducer(
  receiveCustomerOpportunitiesAction.request,
  receiveCustomerOpportunitiesAction.success,
  receiveCustomerOpportunitiesAction.failure,
  "data",
  receiveCustomerOpportunitiesClearAction
);

export const receiveCustomerContactsReducer: Reducer<
  LoadingCustomerContacts,
  Action
> = genericReducer(
  receiveCustomerContactsAction.request,
  receiveCustomerContactsAction.success,
  receiveCustomerContactsAction.failure,
  "data",
  receiveCustomerContactClearAction
);

export const receiveCalculationReducer: Reducer<LoadingCalculations, Action> =
  createReducer({
    state: ReduxStoreState.Initial,
  })
    .handleAction(
      receiveCalculationAction.request,
      (
        state: LoadingCalculations,
        action: ReturnType<typeof receiveCalculationAction.request>
      ) => ({
        ...state,
        [action.payload.subOrderId]: {
          calculation: { initiator: action.payload.initiator },
          loading: true,
          state: ReduxStoreState.Loading,
        },
      })
    )
    .handleAction(
      receiveCalculationAction.failure,
      (
        state: LoadingCalculations,
        action: ReturnType<typeof receiveCalculationAction.failure>
      ) => ({
        ...state,
        [action.payload.subOrderId]: {
          ...state[action.payload.subOrderId],
          loading: false,
          state: ReduxStoreState.Failure,
        },
      })
    )
    .handleAction(
      receiveCalculationAction.success,
      (
        state: LoadingCalculations,
        action: ReturnType<typeof receiveCalculationAction.success>
      ) => ({
        ...state,
        timestamp: +new Date(),
        [action.payload.subOrderId]: {
          calculation: action.payload,
          loading: false,
          state: ReduxStoreState.Success,
        },
      })
    );
