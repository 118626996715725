import { Container } from "@ster/ster-toolkit";
import classNames from "classnames";
import { PropsWithChildren } from "react";

import styles from "./TipSlide.module.less";

interface TipSlideProps {
  image?: string;
  className?: string;
}

const TipSlide = ({
  image,
  className,
  children,
}: PropsWithChildren<TipSlideProps>) => (
  <Container className={classNames(styles.tip, className)}>
    {image && (
      <div className={styles.image}>
        <img src={image} alt="Logo" />
      </div>
    )}

    <div className={styles.text}>{children}</div>
  </Container>
);

export default TipSlide;
