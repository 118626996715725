import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import { MediumEnum, ProblemDetails } from "../../api";
import { CommercialsApi } from "../../api/apis";
import apiConfig from "../../apiConfig";
import { toError } from "../utils";
import {
  reactivateCommercialAction,
  receiveCommercialsAction,
  receiveCommercialsForAuditAction,
  receiveCommercialsForProductIdAction,
  updateCommercialRegistrationStatusAction,
  uploadCommercialAction,
} from "./actions";

export function* receiveCommercials(
  action: ReturnType<typeof receiveCommercialsAction.request>
): SagaIterator {
  try {
    const commercialsApi = new CommercialsApi(apiConfig());
    const response = yield call(() =>
      commercialsApi.apiV1CommercialsGet(action.payload)
    );
    yield put(receiveCommercialsAction.success(response));
  } catch (err) {
    yield put(receiveCommercialsAction.failure(toError(err)));
  }
}

export function* receiveCommercialsForProductId(
  action: ReturnType<typeof receiveCommercialsForProductIdAction.request>
): SagaIterator {
  try {
    const commercialsApi = new CommercialsApi(apiConfig());
    const response = yield call(() =>
      commercialsApi.apiV1CommercialsProductIdGet(action.payload)
    );
    yield put(receiveCommercialsForProductIdAction.success(response));
  } catch (err) {
    yield put(receiveCommercialsForProductIdAction.failure(toError(err)));
  }
}

export function* receiveCommercialsForAudit(
  action: ReturnType<typeof receiveCommercialsForAuditAction.request>
): SagaIterator {
  try {
    const commercialsApi = new CommercialsApi(apiConfig());
    const response = yield call(() =>
      commercialsApi.apiV1CommercialsAuditGet(action.payload)
    );
    yield put(receiveCommercialsForAuditAction.success(response));
  } catch (err) {
    yield put(receiveCommercialsForAuditAction.failure(toError(err)));
  }
}

export function* updateCommercialRegistrationStatus(
  action: ReturnType<typeof updateCommercialRegistrationStatusAction.request>
): SagaIterator {
  try {
    const commercialsApi = new CommercialsApi(apiConfig());
    yield call(() =>
      commercialsApi.apiV1CommercialsAuditPost({
        medium: MediumEnum.Inter,
        commercialUploadRegistrationReview: action.payload.data,
      })
    );
    yield put(updateCommercialRegistrationStatusAction.success());
    yield put(
      receiveCommercialsForAuditAction.request({
        medium: MediumEnum.Inter,
        showAll: action.payload.showAll,
      })
    );
  } catch (err) {
    yield put(updateCommercialRegistrationStatusAction.failure(toError(err)));
  }
}

export function* reactivateCommercial(
  action: ReturnType<typeof reactivateCommercialAction.request>
): SagaIterator {
  try {
    const commercialsApi = new CommercialsApi(apiConfig());
    yield call(() =>
      commercialsApi.apiV1CommercialsReactivatePost({
        medium: MediumEnum.Inter,
        commercialUploadRegistrationReactivate: action.payload,
      })
    );
    yield put(reactivateCommercialAction.success());
  } catch (err) {
    yield put(reactivateCommercialAction.failure(toError(err)));
  }
}

export function* uploadCommercial(
  action: ReturnType<typeof uploadCommercialAction.request>
): SagaIterator {
  try {
    const commercialsApi = new CommercialsApi(apiConfig());
    yield call(() => commercialsApi.apiV1CommercialsUploadPost(action.payload));
    yield put(uploadCommercialAction.success());
  } catch (err) {
    if ((err as Response).status === 400) {
      const { errors }: ProblemDetails = yield call(() =>
        (err as Response).json()
      );
      yield put(
        uploadCommercialAction.failure({
          ...toError(err),
          validationResult: errors,
        })
      );
    } else {
      yield put(uploadCommercialAction.failure(toError(err)));
    }
  }
}
