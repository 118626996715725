import { Action } from "redux";
import { Reducer, createReducer } from "typesafe-actions";

import { ReduxStoreState } from "../base";
import { LoadingInvoices } from "../models";
import { receiveInvoicesAction } from "./actions";

// eslint-disable-next-line import/prefer-default-export
export const invoicesReducer: Reducer<LoadingInvoices, Action> = createReducer({
  state: ReduxStoreState.Initial,
})
  .handleAction(receiveInvoicesAction.request, (state: LoadingInvoices) => ({
    ...state,
    loading: true,
    state: ReduxStoreState.Loading,
  }))
  .handleAction(receiveInvoicesAction.failure, (state: LoadingInvoices) => ({
    ...state,
    loading: false,
    state: ReduxStoreState.Failure,
  }))
  .handleAction(
    receiveInvoicesAction.success,
    (
      state: LoadingInvoices,
      action: ReturnType<typeof receiveInvoicesAction.success>
    ) => ({
      invoices: action.payload || [],
      loading: false,
      state: ReduxStoreState.Success,
    })
  );
