import { Action } from "redux";
import { Reducer, createReducer } from "typesafe-actions";

import { AnnouncementDto } from "../../api/models";
import { ReduxStoreState } from "../base";
import { LoadingAnnouncements } from "../models";
import {
  deleteAnnouncementAction,
  receiveAnnouncementsAction,
  receivePublishedAnnouncementsAction,
  upsertAnnouncementAction,
} from "./actions";

export const emptyAnnouncements: AnnouncementDto[] = [];

export const announcementsReducer: Reducer<LoadingAnnouncements, Action> =
  createReducer({
    state: ReduxStoreState.Initial,
  })
    .handleAction(
      receiveAnnouncementsAction.request,
      (state: LoadingAnnouncements) => ({
        ...state,
        loading: true,
        state: ReduxStoreState.Loading,
      })
    )
    .handleAction(
      receiveAnnouncementsAction.failure,
      (state: LoadingAnnouncements) => ({
        ...state,
        loading: false,
        state: ReduxStoreState.Failure,
      })
    )
    .handleAction(
      receiveAnnouncementsAction.success,
      (
        state: LoadingAnnouncements,
        action: ReturnType<typeof receiveAnnouncementsAction.success>
      ) => ({
        announcements: action.payload || emptyAnnouncements,
        loading: false,
        state: ReduxStoreState.Success,
      })
    )
    .handleAction(
      upsertAnnouncementAction.success,
      (
        state: LoadingAnnouncements,
        action: ReturnType<typeof upsertAnnouncementAction.success>
      ) => {
        const announcements = [
          ...state.announcements.filter((a) => a.id !== action.payload.id),
          action.payload,
        ].sort((a, b) => b.id! - a.id!);

        return {
          announcements,
          loading: false,
          state: ReduxStoreState.Success,
        };
      }
    )
    .handleAction(
      deleteAnnouncementAction.request,
      (
        state: LoadingAnnouncements,
        action: ReturnType<typeof deleteAnnouncementAction.request>
      ) => ({
        announcements: state.announcements.filter(
          (a) => a.id !== action.payload
        ),
        loading: false,
        state: ReduxStoreState.Success,
      })
    );

export const publishedAnnouncementsReducer: Reducer<
  LoadingAnnouncements,
  Action
> = createReducer({
  state: ReduxStoreState.Initial,
})
  .handleAction(
    receivePublishedAnnouncementsAction.request,
    (state: LoadingAnnouncements) => ({
      ...state,
      loading: true,
      state: ReduxStoreState.Loading,
    })
  )
  .handleAction(
    receivePublishedAnnouncementsAction.failure,
    (state: LoadingAnnouncements) => ({
      ...state,
      loading: false,
      state: ReduxStoreState.Failure,
    })
  )
  .handleAction(
    receivePublishedAnnouncementsAction.success,
    (
      state: LoadingAnnouncements,
      action: ReturnType<typeof receivePublishedAnnouncementsAction.success>
    ) => ({
      announcements: action.payload || emptyAnnouncements,
      loading: false,
      state: ReduxStoreState.Success,
    })
  );
