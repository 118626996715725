import { Action } from "redux";
import { Reducer } from "typesafe-actions";

import { genericReducer } from "../../utils";
import { LoadingAnalysisNmoNotification } from "../models";
import { getAnalysisNmoNotificationAction } from "./actions";

// eslint-disable-next-line import/prefer-default-export
export const getAnalysisNmoNotificationReducer: Reducer<
  LoadingAnalysisNmoNotification,
  Action
> = genericReducer(
  getAnalysisNmoNotificationAction.request,
  getAnalysisNmoNotificationAction.success,
  getAnalysisNmoNotificationAction.failure
);
