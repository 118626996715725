import "./materialmodal.less";

import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Modal, Tag, Typography } from "@ster/ster-toolkit";
import { Space } from "antd";
import { ModalProps } from "antd/lib/modal";
import { PropsWithChildren, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Commercial,
  CommercialStatus,
  MediumEnum,
} from "../../../../api/models";
import { ReduxStoreState } from "../../../../store/base";
import {
  clearReactivateCommercialAction,
  reactivateCommercialAction,
} from "../../../../store/commercials/actions";
import { StoreModel } from "../../../../store/models";
import { getCommercialStatusText, tagStatusMapper } from "../../../../utils";
import { getCommercialPlayer } from "../../../commercials/Commercials";

interface MaterialModalProps extends ModalProps {
  commercial: Commercial;
  creationDate?: string;
  advertiserId?: number;
}

const MaterialModal = ({
  commercial,
  creationDate,
  open,
  title,
  onCancel,
  advertiserId,
}: PropsWithChildren<MaterialModalProps>) => {
  const {
    status,
    length,
    currentYear,
    currentYearBroadcasts,
    previousYearBroadcasts,
    medium,
    adScienceId,
  } = commercial;

  const { i18n } = useLingui();

  const dispatch = useDispatch();
  const { state, loading } = useSelector(
    ({ reactivateCommercial }: StoreModel) => reactivateCommercial
  );
  const handleReactivate = useCallback(() => {
    if (medium !== MediumEnum.Inter || !advertiserId || !adScienceId) {
      return;
    }

    dispatch(
      reactivateCommercialAction.request({
        advertiserId,
        adScienceId,
      })
    );
  }, [adScienceId, advertiserId, dispatch, medium]);

  useEffect(() => {
    if (open) {
      dispatch(clearReactivateCommercialAction());
    }
  }, [dispatch, open]);

  const { Caption, Footnote } = Typography;

  return (
    <Modal
      destroyOnClose
      footer={null}
      title={title}
      open={open}
      onCancel={onCancel}
      cancelText={<Trans>Annuleren</Trans>}
      wrapClassName="material-modal"
      width={600}
    >
      {getCommercialPlayer(commercial)}
      <div className="material-metadata">
        <div className="material-metadata--main">
          <Tag
            status={tagStatusMapper(status)}
            text={i18n._(getCommercialStatusText(status))}
          />
          <Caption>{length}&ldquo;</Caption>
          <Footnote>
            <Trans>
              toegevoegd op <strong>{creationDate}</strong>
            </Trans>
          </Footnote>
        </div>
        <div className="material-metadata--extra">
          {!!currentYearBroadcasts && (
            <Footnote>
              <Trans>
                Uitzendingen in {currentYear}:{" "}
                <strong>{currentYearBroadcasts}</strong>
              </Trans>
            </Footnote>
          )}
          {!!previousYearBroadcasts && previousYearBroadcasts > 0 && (
            <Footnote>
              <Trans>
                Uitzendingen vorig jaar:{" "}
                <strong>{previousYearBroadcasts}</strong>
              </Trans>
            </Footnote>
          )}
        </div>
      </div>
      {medium === MediumEnum.Inter &&
        advertiserId &&
        status === CommercialStatus.Inactive &&
        state !== ReduxStoreState.Success && (
          <Space align="center" className="material-action-wrapper">
            <Button
              mode="tertiary"
              onClick={handleReactivate}
              loading={loading}
            >
              {i18n._(t`Opnieuw activeren`)}
            </Button>
          </Space>
        )}
      {state === ReduxStoreState.Success && (
        <Typography.Paragraph>
          <Trans>
            De aanvraag om deze commercial te activeren, is verzonden.
          </Trans>
        </Typography.Paragraph>
      )}
    </Modal>
  );
};

export default MaterialModal;
