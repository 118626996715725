import { createSelector, createStructuredSelector } from "reselect";

import {
  Advertiser,
  ForecastResult,
  OrderListItem,
  OrderRequest,
  OrderStatus,
  PortalUser,
  Product,
} from "../../api/models";
import {
  accountSelector,
  bookingDateFromStoreSelector,
  bookingDateSelector,
  initialDatesSelector,
  initialRequestDetailSelector,
  packagesFromStoreSelector,
  packagesSelector,
  settingsSelector,
} from "../../shared/selectors";
import { Loading, ReduxStoreState } from "../../store/base";
import {
  LoadingBookingDate,
  LoadingCampaigns,
  LoadingEditableCampaign,
  LoadingForecast,
  LoadingForecastConversionGroups,
  LoadingInitialRequest,
  LoadingPackages,
  StoreModel,
} from "../../store/models";
import {
  TagStatus,
  UniqueStrings,
  getOrderStatusOrder,
  getOrderStatusTranslation,
  groupBy,
  tagStatusMapper,
} from "../../utils";
import { emptyArray } from "../../utils/constants";
import { CampaignDataProps, CrmAdvertiser, StatusFilter } from "./models";

const deleteCampaignFromStoreSelector = ({
  deleteCampaign: deleteCampaignFromStore,
}: StoreModel): Loading => deleteCampaignFromStore;

const campaignFromStoreSelector = ({
  campaigns: campaignsFromStore,
}: StoreModel): LoadingCampaigns => campaignsFromStore;

export const editableCampaignSelector = (
  { editableCampaigns }: StoreModel,
  _: Date,
  orderId: number
): LoadingEditableCampaign | undefined => editableCampaigns[orderId];

export const forecastStateSelector = ({
  forecast: { state },
}: StoreModel): ReduxStoreState => state;

const forecastFromStoreSelector = ({ forecast }: StoreModel): LoadingForecast =>
  forecast;

export const forecastConversionGroupsFromStoreSelector = ({
  forecastConversionGroups,
}: StoreModel): LoadingForecastConversionGroups => forecastConversionGroups;

export const forecastConversionGroupsStateSelector = createSelector(
  [forecastConversionGroupsFromStoreSelector],
  (
    forecastConversionGroups: LoadingForecastConversionGroups
  ): ReduxStoreState => forecastConversionGroups.state
);

export const packagesStateSelector = createSelector(
  [packagesFromStoreSelector],
  (packages: LoadingPackages): ReduxStoreState => packages.state
);

const campaignsSelector = createSelector(
  [campaignFromStoreSelector],
  (campaigns: LoadingCampaigns): OrderListItem[] =>
    campaigns.campaigns ?? emptyArray
);

const deleteCampaignState = createSelector(
  [deleteCampaignFromStoreSelector],
  (deleteCampaign: Loading): ReduxStoreState => deleteCampaign.state
);

const campaignsLoadingSelector = createSelector(
  [campaignFromStoreSelector, deleteCampaignFromStoreSelector],
  (campaigns: LoadingCampaigns, deleteCampaign: Loading): boolean =>
    (campaigns.loading ?? false) || (deleteCampaign.loading ?? false)
);

const loadingSubOrdersSelector = createSelector(
  [bookingDateFromStoreSelector, packagesFromStoreSelector],
  (bookingDate: LoadingBookingDate, packages: LoadingPackages): boolean =>
    (bookingDate.loading ?? false) || (packages.loading ?? false)
);

export const loadingForecastSelector = createSelector(
  [forecastFromStoreSelector],
  (forecast: LoadingForecast): boolean => forecast.loading ?? false
);

export const forecastSelector = createSelector(
  [forecastFromStoreSelector],
  (forecast: LoadingForecast): ForecastResult => forecast.forecast ?? []
);

export const forecastConversionGroupsSelector = createSelector(
  [forecastConversionGroupsFromStoreSelector],
  (forecastConversionGroups: LoadingForecastConversionGroups) =>
    forecastConversionGroups.conversionGroups ?? []
);

export const emailFiterValue = (_: StoreModel, emailFilter: boolean): boolean =>
  emailFilter;

export const campaignListSelector = createSelector(
  [campaignsSelector, emailFiterValue, accountSelector],
  (campaigns: OrderListItem[], emailFiter: boolean, account: PortalUser) => {
    const prospectIds = UniqueStrings(
      campaigns
        .filter(
          (c) =>
            c.isProspect &&
            (emailFiter
              ? c.contactPersonAgencyEmail === account.userName
              : true)
        )
        .map((c) => c.prospectId as string)
    );

    return {
      // Sorteer campagnes op maandoverschrijdend en daarna op naam van de status
      campaigns: campaigns.sort((a, b) => {
        if (a.spreadedSecondPart || b.spreadedSecondPart) {
          return 100; // Zet het tweede deel van een overschrijdende campagne naar achteren
        }
        return getOrderStatusOrder(a.status) - getOrderStatusOrder(b.status);
      }),
      advertisers: campaigns
        .filter(
          (campaign) =>
            !campaign.isProspect &&
            (emailFiter
              ? campaign.contactPersonAgencyEmail === account.userName
              : true)
        )
        .map(
          (campaign) =>
            ({
              name: campaign.advertiserName,
              id: campaign.advertiserId,
            }) as Advertiser
        )
        .sort(
          (a, b) =>
            // First sort on #, then on name
            Number((a.name ?? "").startsWith("#")) -
              Number((b.name ?? "").startsWith("#")) ||
            a.name?.localeCompare(b.name || "") ||
            0
        ) // Dubbele adverteerders filteren
        .reverse()
        .filter(
          (item, i, arr) =>
            arr.indexOf(arr.find((t) => t.id === item.id)!) === i
        )
        .reverse(),
      prospectAdvertisers: prospectIds
        .map(
          (prospectId) =>
            ({
              id: prospectId,
              name: campaigns.find((c) => c.prospectId === prospectId)
                ?.advertiserName,
            }) as CrmAdvertiser
        )
        .filter((c) => c.name)
        .sort(
          (a, b) =>
            // First sort on #, then on name
            Number((a.name ?? "").startsWith("#")) -
              Number((b.name ?? "").startsWith("#")) ||
            (a.name ?? "").localeCompare(b.name ?? "")
        ),
    };
  }
);

const getCampaignsFromProps = (
  _: StoreModel,
  { campaigns }: CampaignDataProps
): OrderListItem[] => campaigns;

const getStatusses = createSelector(
  [campaignFromStoreSelector],
  (loadingCampaigns: LoadingCampaigns): StatusFilter[] => {
    const { campaigns } = loadingCampaigns;
    if (!campaigns) {
      return [];
    }

    const statusWithCountAndBudget: StatusFilter[] = [];
    // Group campaigns by status
    const groupedByStatus = groupBy(campaigns, (c) => c.status.toString());
    Object.entries(groupedByStatus).forEach(([key, value]) => {
      // Get the number of campaigns per status and the sum of their budget
      statusWithCountAndBudget.push({
        name: getOrderStatusTranslation(key as OrderStatus),
        value: key,
        tagStatus: tagStatusMapper(value[0].status) ?? TagStatus.Default,
        count: value.length,
        budget: value.reduce((sum, campaign) => sum + campaign.budget, 0),
      });
    });
    return statusWithCountAndBudget.sort(
      (a, b) =>
        getOrderStatusOrder(a.value as OrderStatus) -
        getOrderStatusOrder(b.value as OrderStatus)
    );
  }
);

export const filterSelector = createSelector(
  [getStatusses, getCampaignsFromProps],
  (statusses: StatusFilter[], campaigns) => ({
    products: campaigns
      // Dubbele producten filteren
      .filter(
        (item, i, arr) =>
          arr.indexOf(arr.find((t) => t.productId === item.productId)!) === i
      )
      .map(
        (campaign) =>
          ({
            description: campaign.productDescr,
            id: campaign.productId,
          }) as Product
      )
      .sort((a, b) => a.description?.localeCompare(b.description || "") || 0),
    statusses,
  })
);

export const initialRequestSelector = createSelector(
  [initialRequestDetailSelector],
  (
    initialRequest: LoadingInitialRequest | undefined
  ): OrderRequest | undefined => initialRequest?.initialRequest
);

export const campaignStateSelector = createSelector(
  [editableCampaignSelector],
  (editableCampaign: LoadingEditableCampaign | undefined): ReduxStoreState =>
    editableCampaign?.state ?? ReduxStoreState.Initial
);

export const campaignSelector = createSelector(
  [editableCampaignSelector],
  (
    editableCampaign: LoadingEditableCampaign | undefined
  ): OrderRequest | undefined => editableCampaign?.campaign
);

export const campaignsRootSelector = createStructuredSelector({
  period: initialDatesSelector,
  campaignsData: campaignListSelector,
  deleteCampaignState,
  loading: campaignsLoadingSelector,
  settings: settingsSelector,
});

export const campaignsAccountSelector = createStructuredSelector({
  account: accountSelector,
});

export const packageCardSelector = createStructuredSelector({
  packages: packagesSelector,
  packagesState: packagesStateSelector,
  forecastConversionGroupsState: forecastConversionGroupsStateSelector,
});

export const subOrdersSelector = createStructuredSelector({
  packages: packagesSelector,
  bookingDate: bookingDateSelector,
  loading: loadingSubOrdersSelector,
});

export const campaignCreateEditSelector = createStructuredSelector({
  initialRequest: initialRequestSelector,
  campaign: campaignSelector,
  campaignState: campaignStateSelector,
});
