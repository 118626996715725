import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Icons,
  Table,
  Tooltip,
  Typography,
  formatDate,
} from "@ster/ster-toolkit";
import { ColumnsType } from "antd/lib/table";
import { useMemo, useState } from "react";

import {
  Banner,
  CommercialInstructionSpots,
  CommercialReference,
  CommercialsPerAdvertiser,
  MediumEnum,
  OnlineInstructionSpots,
} from "../../../api";
import MaterialBannerModal from "../Cards/Material/MaterialBannerModal";
import MaterialModal from "../Cards/Material/MaterialModal";
import styles from "./CommercialTable.module.less";

interface CommercialTableProps {
  rows: CommercialInstructionSpots[] | OnlineInstructionSpots[];
  productId: number;
  medium: MediumEnum;
  commercialsPerAdvertiser?: CommercialsPerAdvertiser;
  hasBanners?: boolean;
}

interface CommercialElementProps {
  commercialReference: CommercialReference;
  productId: number;
  commercialsPerAdvertiser?: CommercialsPerAdvertiser;
}

interface BannerElementProps {
  banner: Banner;
}

const BannerElement = ({ banner }: BannerElementProps) => {
  const { i18n } = useLingui();
  const { description, id } = banner || {};

  const [visible, setVisible] = useState(false);
  const openModal = (): void => {
    setVisible(true);
  };

  const closeModal = (): void => {
    setVisible(false);
  };

  return (
    <span key={id} className="commercial-tile">
      <button
        className="icon-wrapper link"
        onClick={openModal}
        type="button"
        title={i18n._(t`Tonen`)}
        disabled={!banner}
      >
        <Icons.EyeIcon width={20} height={20} fill="white" />
      </button>
      <Tooltip title={description} placement="bottom">
        <span className="label">
          {id}
          {`: ${description}`}
        </span>
      </Tooltip>
      <MaterialBannerModal
        title={
          <Typography.Title level={3} className="material-modal__title">
            <span className="material-modal__id">{id}: </span>
            {description}
          </Typography.Title>
        }
        open={visible}
        onCancel={closeModal}
        materialBannerData={banner}
      />
    </span>
  );
};

const CommercialElement = ({
  commercialReference,
  productId,
  commercialsPerAdvertiser,
}: CommercialElementProps) => {
  const { i18n } = useLingui();
  const { description, id, length, code } = commercialReference;

  const [visible, setVisible] = useState(false);
  const openModal = (): void => {
    setVisible(true);
  };

  const closeModal = (): void => {
    setVisible(false);
  };

  const commercial = useMemo(
    () => commercialsPerAdvertiser?.commercials?.find((c) => c.id === id),
    [commercialsPerAdvertiser?.commercials, id]
  );

  return (
    <span key={5} className="commercial-tile">
      <button
        className="icon-wrapper link"
        onClick={openModal}
        type="button"
        title={i18n._(t`Afspelen`)}
        disabled={!commercial}
      >
        <Icons.PlayIcon width={20} height={20} />
      </button>
      <Tooltip title={description} placement="bottom">
        <span className="label">
          {length ? `${length}" ` : ""}
          {id}
          {code === "Nieuw" ? (
            <em>: {i18n._(t`Nog aan te leveren`)}</em>
          ) : (
            `: ${description}`
          )}
        </span>
      </Tooltip>
      {commercial && (
        <MaterialModal
          title={
            <Typography.Title level={3} className="material-modal__title">
              <span className="material-modal__id">{`${productId}`}</span>
              {`${code}`}: {description}
            </Typography.Title>
          }
          open={visible}
          onCancel={closeModal}
          commercial={commercial}
          creationDate={
            commercial.creationDate
              ? formatDate(commercial.creationDate)
              : undefined
          }
        />
      )}
    </span>
  );
};

const CommercialTable = ({
  rows,
  productId,
  medium,
  commercialsPerAdvertiser,
  hasBanners,
}: CommercialTableProps) => {
  const { i18n } = useLingui();

  const columns: ColumnsType<CommercialInstructionSpots> = [
    {
      key: "commercials",
      title: hasBanners
        ? i18n._(t`De volgende banner(s)`)
        : i18n._(t`De volgende commercial(s)`),
      width: medium === MediumEnum.Inter ? "60%" : "80%",
      render: (_, { commercial, banner, tagOns }) => (
        <span className="commercials-container">
          {commercial && (
            <CommercialElement
              key={commercial.id}
              productId={productId}
              commercialReference={commercial}
              commercialsPerAdvertiser={commercialsPerAdvertiser}
            />
          )}
          {banner && <BannerElement key={banner.id} banner={banner} />}
          {tagOns?.map((tagOn) => (
            <CommercialElement
              key={tagOn.id}
              productId={productId}
              commercialReference={tagOn}
              commercialsPerAdvertiser={commercialsPerAdvertiser}
            />
          ))}
        </span>
      ),
    },
    {
      key: "frequency",
      title: i18n._(t`Frequentie`),
      width: "20%",
      render: (_, { rotationFrequency }) => (
        <span>{rotationFrequency ? `${rotationFrequency}%` : "-"}</span>
      ),
    },
    {
      key: "homepage",
      title: i18n._(t`Landingspagina`),
      width: "40%",
      render: (_, { homepage }) => (
        <div className={styles.homepage}>
          {homepage ? (
            <a
              href={homepage}
              title={homepage}
              target="_blank"
              rel="noreferrer noopener"
            >
              {homepage}
            </a>
          ) : (
            "-"
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="rotation__commercial-table">
      <Table
        columns={columns
          .filter((s) =>
            s.key === "frequency" ? medium !== MediumEnum.Inter : true
          )
          .filter((s) =>
            s.key === "homepage" ? medium === MediumEnum.Inter : true
          )}
        dataSource={rows}
        pagination={false}
        rowKey="id"
      />
    </div>
  );
};

export default CommercialTable;
