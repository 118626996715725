import "./forecast.less";

import { Table, formatNumber } from "@ster/ster-toolkit";
import { ColumnsType } from "antd/lib/table";
import { memo } from "react";

import { ForecastCoverageEnum, ForecastResultTargetGroup } from "../../../api";
import { forecastCoverageEnumTranslations } from "./shared";

const ForecastContactDistributionTable = memo(
  ({ forecastTargets }: { forecastTargets: ForecastResultTargetGroup[] }) => {
    const availableForecastCoverageEnums = Object.keys(ForecastCoverageEnum)
      .map((key) => key as ForecastCoverageEnum)
      // Look only at the first forecast to see which coverage enums are available - all forecasts should have the same enums
      .filter((key) => forecastTargets[0].forecasts[key]);

    const coverageColumns: ColumnsType<ForecastResultTargetGroup> =
      availableForecastCoverageEnums.map((key, index) => ({
        title: forecastCoverageEnumTranslations[key],
        key: `${index}`,
        align: "right",
        render: (targetGroup: ForecastResultTargetGroup) =>
          `${formatNumber(targetGroup.forecasts[key]?.bereikPercentage ?? 0, 1)}%`,
      }));

    const columns: ColumnsType<ForecastResultTargetGroup> = [
      {
        title: "",
        key: "targetGroupDisplayName",
        dataIndex: "targetGroupDisplayName",
      },
      ...coverageColumns,
    ];

    return (
      <Table
        columns={columns}
        dataSource={forecastTargets}
        rowKey={(record) => record.targetGroup}
        pagination={false}
      />
    );
  }
);

export default ForecastContactDistributionTable;
