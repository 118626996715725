import { AnyAction } from "redux-saga";
import { Reducer, createReducer } from "typesafe-actions";

import { genericReducer } from "../../utils";
import { Loading, ReduxStoreState } from "../base";
import {
  LoadingCommercials,
  LoadingCommercialsForAudit,
  LoadingCommercialsForProductId,
} from "../models";
import {
  clearReactivateCommercialAction,
  reactivateCommercialAction,
  receiveCommercialsAction,
  receiveCommercialsForAuditAction,
  receiveCommercialsForProductIdAction,
  updateCommercialRegistrationStatusAction,
  uploadCommercialAction,
  uploadCommercialClearAction,
} from "./actions";
import { LoadingUploadCommercial } from "./models";

export const commercialsReducer: Reducer<LoadingCommercials, AnyAction> =
  createReducer({
    state: ReduxStoreState.Initial,
  })
    .handleAction(
      receiveCommercialsAction.request,
      (state: LoadingCommercials) => ({
        ...state,
        loading: true,
        state: ReduxStoreState.Loading,
      })
    )
    .handleAction(
      receiveCommercialsAction.failure,
      (state: LoadingCommercials) => ({
        ...state,
        loading: false,
        state: ReduxStoreState.Failure,
      })
    )
    .handleAction(
      receiveCommercialsAction.success,
      (
        state: LoadingCommercials,
        action: ReturnType<typeof receiveCommercialsAction.success>
      ) => ({
        commercials: action.payload || [],
        loading: false,
        state: ReduxStoreState.Initial,
      })
    );

export const commercialsForProductIdReducer: Reducer<
  LoadingCommercialsForProductId,
  AnyAction
> = createReducer({
  state: ReduxStoreState.Initial,
})
  .handleAction(
    receiveCommercialsForProductIdAction.request,
    (state: LoadingCommercialsForProductId) => ({
      ...state,
      loading: true,
      state: ReduxStoreState.Loading,
    })
  )
  .handleAction(
    receiveCommercialsForProductIdAction.failure,
    (state: LoadingCommercialsForProductId) => ({
      ...state,
      loading: false,
      state: ReduxStoreState.Failure,
    })
  )
  .handleAction(
    receiveCommercialsForProductIdAction.success,
    (
      state: LoadingCommercialsForProductId,
      action: ReturnType<typeof receiveCommercialsForProductIdAction.success>
    ) => ({
      commercialPerAdvertiser: action.payload,
      loading: false,
      state: ReduxStoreState.Success,
    })
  );

export const commercialsForAuditReducer: Reducer<
  LoadingCommercialsForAudit,
  AnyAction
> = createReducer({
  state: ReduxStoreState.Initial,
})
  .handleAction(
    receiveCommercialsForAuditAction.request,
    (state: LoadingCommercialsForAudit) => ({
      ...state,
      loading: true,
      state: ReduxStoreState.Loading,
    })
  )
  .handleAction(
    receiveCommercialsForAuditAction.failure,
    (state: LoadingCommercialsForAudit) => ({
      ...state,
      loading: false,
      state: ReduxStoreState.Failure,
    })
  )
  .handleAction(
    receiveCommercialsForAuditAction.success,
    (
      state: LoadingCommercialsForAudit,
      action: ReturnType<typeof receiveCommercialsForAuditAction.success>
    ) => ({
      commercialRegistrations: action.payload,
      loading: false,
      state: ReduxStoreState.Success,
    })
  );

export const commercialRegistrationStatusReducer: Reducer<Loading, AnyAction> =
  createReducer({
    state: ReduxStoreState.Initial,
  })
    .handleAction(updateCommercialRegistrationStatusAction.request, () => ({
      loading: true,
      state: ReduxStoreState.Loading,
    }))
    .handleAction(updateCommercialRegistrationStatusAction.failure, () => ({
      loading: false,
      state: ReduxStoreState.Failure,
    }))
    .handleAction(updateCommercialRegistrationStatusAction.success, () => ({
      loading: false,
      state: ReduxStoreState.Success,
    }));

export const reactivateCommercialReducer: Reducer<Loading, AnyAction> =
  createReducer({
    state: ReduxStoreState.Initial,
  })
    .handleAction(reactivateCommercialAction.request, () => ({
      loading: true,
      state: ReduxStoreState.Loading,
    }))
    .handleAction(reactivateCommercialAction.failure, () => ({
      loading: false,
      state: ReduxStoreState.Failure,
    }))
    .handleAction(reactivateCommercialAction.success, () => ({
      loading: false,
      state: ReduxStoreState.Success,
    }))
    .handleAction(clearReactivateCommercialAction, () => ({
      loading: false,
      state: ReduxStoreState.Initial,
    }));

export const uploadCommercialReducer: Reducer<
  LoadingUploadCommercial,
  AnyAction
> = genericReducer(
  uploadCommercialAction.request,
  uploadCommercialAction.success,
  uploadCommercialAction.failure,
  undefined,
  uploadCommercialClearAction
);
