/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  MaterialIdentifier,
  MaterialIdentifierFromJSON,
  MaterialIdentifierFromJSONTyped,
  MaterialIdentifierToJSON,
} from "./";

/**
 *
 * @export
 * @interface Material
 */
export interface Material {
  /**
   *
   * @type {MaterialIdentifier}
   * @memberof Material
   */
  identifier: MaterialIdentifier;
  /**
   *
   * @type {Array<string>}
   * @memberof Material
   */
  thumbnails: Array<string>;
  /**
   *
   * @type {string}
   * @memberof Material
   */
  uriPath: string;
}

export function MaterialFromJSON(json: any): Material {
  return MaterialFromJSONTyped(json, false);
}

export function MaterialFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Material {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    identifier: MaterialIdentifierFromJSON(json["identifier"]),
    thumbnails: json["thumbnails"],
    uriPath: json["uriPath"],
  };
}

export function MaterialToJSON(value?: Material | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    identifier: MaterialIdentifierToJSON(value.identifier),
    thumbnails: value.thumbnails,
    uriPath: value.uriPath,
  };
}
