import { Action } from "redux";
import { Reducer } from "typesafe-actions";

import { genericReducer } from "../../utils";
import { LoadingProposalData } from "../models";
import { receiveProposalDataAction } from "./actions";

// eslint-disable-next-line import/prefer-default-export
export const proposalDataReducer: Reducer<LoadingProposalData, Action> =
  genericReducer(
    receiveProposalDataAction.request,
    receiveProposalDataAction.success,
    receiveProposalDataAction.failure,
    "proposalData"
  );
